import {
  ADD_NOTIFICATION,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_ERROR,
  CLEAR_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS_SUCCESS,
  CLEAR_NOTIFICATIONS_ERROR,
  REMOVE_NOTIFICATION,
  REMOVE_NOTIFICATION_SUCCESS,
  REMOVE_NOTIFICATION_ERROR,
  READ_NOTIFICATION,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_ERROR,
} from "../Types/notifications.constants";

const INITIAL_STATE = {
  loading: false,
  notifications: [],
  error: null,
};

export const notificationsReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        loading: true,
        notifications: [...state.notifications],
      };
    case ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: [action.payload, ...state.notifications],
      };
    case ADD_NOTIFICATION_ERROR:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: [],
      };
    case CLEAR_NOTIFICATIONS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        loading: true,
        notifications: [...state.notifications],
      };
    case REMOVE_NOTIFICATION_SUCCESS:
      const updatedNotifications = state.notifications.filter((ele: any) => {
        return ele.timestamp !== action.payload.timestamp;
      });

      return {
        ...state,
        loading: false,
        notifications: updatedNotifications,
      };
    case REMOVE_NOTIFICATION_ERROR:
      return { ...state, loading: false, error: action.payload };
    case READ_NOTIFICATION:
      return {
        ...state,
        loading: true,
        notifications: [...state.notifications],
      };
    case READ_NOTIFICATION_SUCCESS:
      const index = state.notifications
        .map((ele: any) => {
          return ele.timestamp;
        })
        .indexOf(action.payload.timestamp);
      const readNotification: { read: boolean } = state.notifications[index];
      readNotification.read = true;
      const updatedArray: any = state.notifications;
      updatedArray.splice(index, 1, readNotification);

      return {
        ...state,
        loading: false,
        notifications: updatedArray,
      };
    case READ_NOTIFICATION_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
