export const LOAD_TICKETS = "LOAD_TICKETS";
export const LOAD_TICKETS_SUCCESS = "LOAD_TICKETS_SUCCESS";
export const LOAD_TICKETS_ERROR = "LOAD_TICKETS_ERROR";
export const LOAD_TICKETS_BY_ALERTS = "LOAD_TICKETS_BY_ALERTS";
export const LOAD_TICKETS_BY_ALERTS_SUCCESS = "LOAD_TICKETS_BY_ALERTS_SUCCESS";
export const LOAD_TICKETS_BY_ALERTS_ERROR = "LOAD_TICKETS_BY_ALERTS_ERROR";
export const UPDATE_PAGINATION = "UPDATE_PAGINATION";
export const UPDATE_PAGINATION_SUCCESS = "UPDATE_PAGINATION_SUCCESS";
export const UPDATE_PAGINATION_ERROR = "UPDATE_PAGINATION_ERROR";
export const LOAD_ALL_TICKETS = "LOAD_ALL_TICKETS";
export const LOAD_ALL_TICKETS_SUCCESS = "LOAD_ALL_TICKETS_SUCCESS";
export const LOAD_ALL_TICKETS_ERROR = "LOAD_ALL_TICKETS_ERROR";
export const EXPORT_TICKETS = "EXPORT_TICKETS";
export const EXPORT_TICKETS_SUCCESS = "EXPORT_TICKETS_SUCCESS";
export const EXPORT_TICKETS_ERROR = "EXPORT_TICKETS_ERROR";
export const ADD_TICKET_ID = "ADD_TICKET_ID";
export const ADD_TICKET_ID_SUCCESS = "ADD_TICKET_ID_SUCCESS";
export const ADD_TICKET_ID_ERROR = "ADD_TICKET_ID_ERROR";
export const FILTER_STATUS = "FILTER_STATUS";
export const FILTER_STATUS_SUCCESS = "FILTER_STATUS_SUCCESS";
export const FILTER_STATUS_ERROR = "FILTER_STATUS_ERROR";
export const UPDATE_FILTER_PARAMS = "UPDATE_FILTER_PARAMS";
export const UPDATE_FILTER_PARAMS_SUCCESS = "UPDATE_FILTER_PARAMS_SUCCESS";
export const UPDATE_FILTER_PARAMS_ERROR = "UPDATE_FILTER_PARAMS_ERROR";
export const LOAD_ALL_TICKETS_USECASE = "LOAD_ALL_TICKETS_USECASE";
export const LOAD_ALL_TICKETS_USECASE_ERROR = "LOAD_ALL_TICKETS_USECASE_ERROR";
export const LOAD_ALL_TICKETS_USECASE_SUCCESS =
  "LOAD_ALL_TICKETS_USECASE_SUCCESS";
export const SET_CURRENT_TICKET = "SET_CURRENT_TICKET";
export const SET_CURRENT_TICKET_SUCCESS = "SET_CURRENT_TICKET_SUCCESS";
export const SET_CURRENT_TICKET_ERROR = "SET_CURRENT_TICKET_ERROR";
export const UPDATE_CURRENT_TICKET = "UPDATE_CURRENT_TICKET";
