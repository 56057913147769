import { NAV_QUALITY_HISTORICAL } from '../Constants'

const cubeQuery = (
	type: string,
	cubeName: string,
	startTime: Date | string,
	endTime: Date | string
) => {
	switch (type) {
		case NAV_QUALITY_HISTORICAL:
			return {
				measures: [`${cubeName}.Value`],
				timeDimensions: [
					{
						dimension: `${cubeName}.timestamp`,
						granularity: 'second',
						dateRange: [startTime, endTime]
					}
				],
				order: {
					'TACLGVOutOfSpec.timestamp': 'asc'
				},
				dimensions: [`${cubeName}.description`, `${cubeName}.deviceName`],
				filters: [
					{
						member: `${cubeName}.description`,
						operator: 'equals',
						values: [
							'NavigationalQuality',
							'NavigationSeenReflectors',
							'LGVX',
							'LGVY',
							'NavigationUsedReflectors',
							'LGVHeadingAngle',
							'CurrentSegmentID'
						]
					}
				]
			}
	}
}

export default cubeQuery
