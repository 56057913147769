import {
  LOAD_MACHINES,
  LOAD_MACHINES_SUCCESS,
  LOAD_MACHINES_ERROR,
} from "../Types/machines.constants";

import axios from "../../Utils/AxiosConfig/axiosConfig";
import { put, call, takeEvery } from "redux-saga/effects";

//function to get all machines from the api
const getMachines = async () => {
  let machines: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`machines?pageSize=100&page=${page++}`);
    machines = machines.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);
  return machines;
};

export function* listMachines(): any {
  try {
    const data = yield call(() =>
    getMachines().then((res) => {
        return res;
      })
    );

    yield put({
      type: LOAD_MACHINES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: LOAD_MACHINES_ERROR,
      payload: null,
    });
  }
}

export function* listMachinesSaga() {
  yield takeEvery(LOAD_MACHINES, listMachines);
}
