import { makeStyles } from "@mui/styles";
import { getGradientColor } from "../Helpers/utils";

const useStyles = makeStyles(() => ({
  lgvIconStyle: {
    display: "flex",
    border: "0.5px solid #ffffff",
    borderRadius: "2px",
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Poppins !important",
  },
  lgvPathStyle: {
    display: "flex",
    borderRadius: "50px",
    justifyContent: "center",
    alignItems: "center",
    margin: "8px 0 !important", //need to change to 0 after path data set
    border: "none",
  },
}));

const LgvIcon = ({
  data,
  navQuality,
  type,
  hoverLgvId,
  LgvConfig,
  navQualityHeatMapConfig,
  component,
  navQualityWdhHgt,
}: any) => {
  const classes = useStyles();

  const renderPathColor = (
    data: string,
    hoverLgvId: string,
    component: string
  ): { backgroundColor: string; opacity?: number } => {
    if (data === hoverLgvId) {
      return { backgroundColor: "black" };
    }
    const backgroundColor = getGradientColor(
      navQuality,
      navQualityHeatMapConfig
    );
    return { backgroundColor, opacity: component === "heatMap" ? 1 : 0.3 };
  };

  return type === "icon" ? (
    <span
      style={{
        backgroundColor: `${getGradientColor(navQuality, LgvConfig)}`,
        ...navQualityWdhHgt,
      }}
      className={classes.lgvIconStyle}
    >
      {data}
    </span>
  ) : (
    <hr
      style={{
        ...renderPathColor(data, hoverLgvId, component),
        ...navQualityWdhHgt,
      }}
      className={classes.lgvPathStyle}
    ></hr>
  );
};

export default LgvIcon;
