import React, { useState } from 'react';
// @ts-ignore
import { useSelector } from "react-redux";
import { cloneDeep, find, get, isEmpty, isString } from 'lodash';
import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { SelectChangeEvent } from '@material-ui/core';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

const RHGradientConfig = (props: any) => {
  const { items, onSeqChange, onError } = props;

  const [error, setError] = useState<string>('');

  const seedsList = useSelector((state: any) => state.seedsList);
  
  const RHGradientValues = find(seedsList.seeds, function (obj) {
    if (obj.seed_key_name === 'RH - Gradient Values') {
      return true;
    }
  });

  if (isEmpty(items) || isEmpty(RHGradientValues)) return null;

  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    let clonedUpdatedItems = cloneDeep(items);
    clonedUpdatedItems.value = typeof value === 'string' ? value.split(',') : value;

    if (isEmpty(value)) {
      onError(true);
      setError('Please select at least 1 config.');
    } else {
      onError(false);
      setError('');
    }

    onSeqChange(clonedUpdatedItems, false);
  };

  const currentValue = isString(items.value) ? [items.value] : items.value;

  const menuItems = get(RHGradientValues, 'seed_data_value', []).map((item: any) => item.key);  

  return (
    <div className="selfConfigComponent">
      <FormControl fullWidth sx={{ margin: '16px 0' }} error={!!error}>
        <InputLabel id="demo-simple-select-label">Gradient Config</InputLabel>
        <Select
          multiple
          value={currentValue}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {currentValue.map((value: any) => (
                <Chip key={value} label={value} style={{ backgroundColor: '#8476EF' }}/>
              ))}
            </Box>
          )}
        >
          {menuItems.map((item: any) => (
            <MenuItem
              key={item}
              value={item}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </div>
  );
}


export default RHGradientConfig;
