import { useState, useEffect, useRef, ChangeEvent } from "react";
import { FC } from "react";
import { Theme } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import JsFileDownloader from "js-file-downloader";
import Axios from "axios";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert";

import { uploadFileToBlobSAT } from "../../../../Services/azure-storage-blob";
import { env } from "../../../../env";
import findTemplate from "./Templates";
import CustomScrollbars from "../../../../componets/CustomScrollbars/CustomScrollbars";
import SubmitFilePopup from "./SubmitFilePopup";
import DownloadIcon from "./DownloadIcon.svg";
import UploadIcon from "./UploadIcon.svg";
import SATCompletedAnimation from "./animationSATDone.json";
import Lottie from "lottie-web";

const useStyles = makeStyles({
  root: {
    marginTop: "10px",
    backgroundColor: "pink",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "#293368",
    borderRadius: "5px",
  },

  flexBox: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  flexItems: {
    margin: "5px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },

  gridPadding: {
    "&.MuiGrid-root.MuiGrid-item": {
      padding: "15px 24px",
    },
  },
  gridContainer: {
    backgroundColor: "#293368",
    borderRadius: "5px",
    padding: "10px",
  },
  cardBg: {
    "&.MuiCard-root": {
      background: "#293368",
      padding: "15px 20px 25px 20px",
    },
  },
});

const OEMSignOffPage: FC<any> = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isSignOffModal, setIsSignOffModal] = useState(false);
  const [isRejectModal, setIsRejectModal] = useState(false);
  // const [isFileSubmissionModal, setIsFileSubmissionModal] = useState(false);

  const seedsList = useSelector((state: any) => state.seedsList);
  const handleSignOffModalOpen = () => setIsSignOffModal(true);
  const handleSignOffModalClose = () => setIsSignOffModal(false);
  const handleFileSubmissionModalClose = () => setShowSubmitButton(false);
  const handleRejectModalOpen = () => setIsRejectModal(true);
  const handleRejectModalClose = () => setIsRejectModal(false);
  const [loading, setLoading] = useState(true);
  const [uploadedFile, setUploadedFile] = useState<any>([]);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [showSubmitButton, setShowSubmitButton] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState<any>([]); // Define the state with proper type
  // const [filePreviews, setFilePreviews] = useState<string[]>([]);
  const [clickedFileIndex, setClickedFileIndex] = useState<number | null>(null);

  const [satConfig, setSATConfig] = useState<any>(null); //STATE TO STORE SAT CONFIG FOR CURRENTLY OPEN SAT SIGN OFF
  const [satAdmins, setSatAdmins] = useState<any>([]);

  const [satDetails, setSatDetails] = useState<any>(null);
  const [accessToken, setAccessToken] = useState<any>(null);

  const [submitFileButton, setSubmitFileButton] = useState<any>(false);
  const handleSubmitFileModalOpen = () => setSubmitFileButton(true);
  const handleSubmitFileModalClose = () => setSubmitFileButton(false);

  const [isSubmitted, setIsSubmitted] = useState(satDetails?.oemDocsSubmitted);

  //Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState<AlertColor>("success");

  const [dragActive, setDragActive] = useState(false);
  const animationContainer = useRef<HTMLDivElement>(null);

  function renameFile(originalFile: any, newName: any) {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }

  //function to fetch list of users
  const getUsers = async (authorization: any) => {
    let users: any = [];
    let res = null,
      page = 1;
    //loop to fetch all pages from api
    do {
      res = await Axios.get(`${env.baseURL}users?pageSize=100&page=${page++}`, {
        headers,
      });
      users = users.concat(res.data.rows);
    } while (res.data.page < res.data.totalPages);
    return users;
  };

  //function to fetch list of user permissions
  const getUserPermissions = async (authorization: any) => {
    let users: any = [];
    let res = null,
      page = 1;
    //loop to fetch all pages from api
    do {
      res = await Axios.get(
        `${env.baseURL}userpermissions?pageSize=100&page=${page++}`,
        { headers }
      );
      users = users.concat(res.data.rows);
    } while (res.data.page < res.data.totalPages);
    return users;
  };

  const fetchSatDetails = async () => {
    var usersList: any = [];
    const headers = { Authorization: accessToken };
    await getUsers(headers).then((res) => {
      usersList = res;
      return res;
    });
    await getUserPermissions(headers).then((res) => {
      const satAdminRole = res.find((role: any) => {
        return role.role_name === "ARCH_SAT_LEAD";
      });
      //filtering SAT Admin users
      setSatAdmins(
        usersList.filter((user: any) => {
          return user.user_arch_group.includes(satAdminRole._id);
        })
      );
      return res;
    });
    var tempSatDetailsData: any;
    try {
      await Axios.get(
        `${env.baseURL}satdetails/${window.location.href.substring(
          window.location.href.lastIndexOf("/") + 1,
          window.location.href.length
        )}`,
        { headers }
      ).then((response: any) => {
        setSatDetails(response.data);
        tempSatDetailsData = response.data;
        setLoading(false);
      });
      Axios.get(`${env.baseURL_monarch}seedconfigs?pageSize=100`, {
        headers: {
          Authorization: accessToken,
          isServer: true,
        },
      }).then((res: any) => {
        const tempConfig = res.data.find((seed: any) => {
          return seed.seed_key_name === "SATConfigData";
        });
        //fetching SAT config from seed for the current plant
        setSATConfig(
          JSON.parse(
            tempConfig.seed_data_value.find((item: any) => {
              return item.key === tempSatDetailsData.plant_name;
            })?.value
          )
        );
      });
    } catch (error) {
      console.error("Error fetching satDetails: ", error);
    }
  };

  const fetchAccessToken = async () => {
    await Axios.get(`${env.baseURL_sat}/getAccessToken`).then((res: any) => {
      setAccessToken("Bearer " + res.data.token);
    });
  };

  useEffect(() => {
    fetchAccessToken();
  }, []);

  useEffect(() => {
    if (accessToken !== null) {
      fetchSatDetails();
    }
  }, [accessToken]);

  useEffect(() => {
    setUploadedFile(satDetails?.oemDocs);
    setIsSubmitted(satDetails?.oemDocsSubmitted);
  }, [satDetails]);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: animationContainer.current!,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: SATCompletedAnimation,
    });
    return () => {
      animation.destroy();
    };
  }, [loading]);

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Handle the selected files here
    if (event.target.files) {
      const files = Array.from(event.target.files).map((file) => file.name);
      setSelectedFiles(files);
      setClickedFileIndex(null);
    }
  };

  const handleFileClick = (index: number) => {
    setClickedFileIndex(index);
  };
  const handleFileSelect = async (files: any) => {
    setSelectedFiles(files);
    setShowSubmitButton(true);
    console.log("files", files);
    if (
      Array.from(files).filter((file: any) => {
        return file.type.includes("text");
      }).length > 0
    ) {
      setSnackbarOpen(true);
      setSnackbarType("error");
      setSnackbarMessage("Text/CSV files not supported.");
    }
    if (
      Array.from(files).filter((file: any) => {
        return !file.type.includes("text");
      }).length > 0
    ) {
      try {
        const uploadedFiles = await uploadFiles(
          Array.from(files).filter((file: any) => {
            return !file.type.includes("text");
          })
        );

        // Filter out null values (failed uploads) and update state
        const validFiles = uploadedFiles.filter((file) => file !== null);
        setUploadedFile([...uploadedFile, ...validFiles]);
        handleSaveFilesAgainstSAT([...uploadedFile, ...validFiles]);

        setSubmissionStatus("Successfully submitted!");
      } catch (error) {
        // Handle errors if the upload fails
        console.error("Upload failed:", error);
        setSubmissionStatus("Submission failed. Please try again.");
      }
    }
  };

  const commentFileInput = useRef<HTMLInputElement>(null);
  const handleAttachClick = (event: any) => {
    commentFileInput?.current?.click();
  };
  const headers = { Authorization: accessToken };

  const handleDeleteFile = (index: any) => {
    // Create a copy of the uploaded files array
    const updatedFiles = [...uploadedFile];
    // Remove the file at the specified index
    updatedFiles.splice(index, 1);
    setLoading(true);
    setSnackbarOpen(true);
    setSnackbarMessage("Removing files. Please wait.");
    setSnackbarType("info");
    Axios.put(
      `${env.baseURL}satdetails/${window.location.href.substring(
        window.location.href.lastIndexOf("/") + 1,
        window.location.href.length
      )}`,
      {
        oemDocs: updatedFiles,
      },
      { headers }
    ).then(() => {
      setLoading(false);

      dispatch({ type: "LOAD_SAT_DETAILS" });
      setSnackbarOpen(true);
      setSnackbarMessage("File successfully removed.");
      setSnackbarType("success");
    });

    // Update the state with the modified files array
    setUploadedFile(updatedFiles);
    setSatDetails({ ...satDetails, oemDocs: updatedFiles });
  };

  const handleSaveFilesAgainstSAT = async (files: any) => {
    setLoading(true);

    Axios.put(
      `${env.baseURL}satdetails/${window.location.href.substring(
        window.location.href.lastIndexOf("/") + 1,
        window.location.href.length
      )}`,
      {
        oemDocs: files,
      },
      { headers }
    ).then((response: any) => {
      setSatDetails(response.data);

      setLoading(false);

      setSnackbarOpen(true);
      setSnackbarMessage("Uploaded documents successfully");
      setSnackbarType("success");

      // dispatch({ type: "LOAD_SAT_DETAILS" });
      // fetchSatDetails();
    });
  };

  const uploadFiles = async (files: any) => {
    const uploadTimestamp = new Date().toISOString();
    setSnackbarOpen(true);
    setSnackbarMessage("Uploading files. Please wait.");
    setSnackbarType("info");

    const uploadedFiles = await Promise.all(
      Array.from(files)
        .filter((file: any) => {
          return !file.type.includes("text");
        })
        .map(async (file: any) => {
          const updatedFile = renameFile(
            file,
            `${file.name} ${uploadTimestamp}`
          );
          const url = await uploadFileToBlobSAT(updatedFile);
          if (!url) {
            return null; // Handle upload failure
          }
          const fileObj = {
            name: file.name,
            size: file.size,
            url: url,
            date: uploadTimestamp.split("T")[0],
            time: uploadTimestamp.split("T")[1].slice(0, 8),
          };

          return fileObj; // Return the uploaded file object
        })
    );

    return uploadedFiles;
  };
  const handleDownload = (url: any, fileName: any) => {
    new JsFileDownloader({
      url: url,
      filename: fileName,
    })
      .then(function () {
        // Called when download ended
        setSnackbarOpen(true);
        setSnackbarMessage("File downloaded successfully");
        setSnackbarType("success");
      })
      .catch(function (error: any) {
        // Called when an error occurred
        setSnackbarOpen(true);
        setSnackbarMessage("There is some problem in downloading a file");
        setSnackbarType("error");
        console.error("Error during download:", error);
      });
  };

  // const handleDragOver = (e: any) => {
  //   e.preventDefault();
  // };
  // const handleFiles = (files: any) => {
  //   const fileList = Array.from(files).map((file: any) => ({
  //     name: file.name,
  //     size: file.size,
  //     type: file.type,
  //   }));

  // Update your component state or dispatch an action to handle the dropped files
  // console.log("Dropped files:", fileList);
  // Perform necessary actions with the dropped files, e.g., upload, store in state, etc.

  // const handleDrop = (e: any) => {
  //   e.preventDefault();
  //   const files = e.dataTransfer.files;
  //   handleFiles(files);
  // };

  const handleSubmitOEMDocs = () => {
    Axios.put(
      `${env.baseURL}satdetails/${window.location.href.substring(
        window.location.href.lastIndexOf("/") + 1,
        window.location.href.length
      )}`,
      {
        oemDocsSubmitted: true,
      },
      { headers }
    ).then((response: any) => {
      setSatDetails(response.data);

      Axios.post(
        env.LogicApp_SATEmailTrigger,
        {
          recipients: satConfig?.PlantDirectorDetails.map((user: any) => {
            return user.mail;
          }),
          cc_recipients: satAdmins.map((user: any) => {
            return user.mail;
          }),
          type: "PD to sign docs",
          attachments: satDetails?.oemDocs.map((ele: any) => {
            return {
              name: ele.name,
              path: ele.url.substring(
                ele.url.indexOf("/sat-uploaded-files"),
                ele.url.length
              ),
            };
          }),
          subject: `SAT ${
            satDetails.plant_name
          } Line ${satDetails.line_name.substring(
            satDetails.plant_name.length,
            satDetails.line_name.length
          )}- Documents to be Signed`,
          body: findTemplate({
            niagara_lead: satConfig.LeadsInfo.find((ele: any) => {
              return ele.type === "Niagara Lead";
            }).users[0],
            type: "PDtoSignDocs",

            url: `${window.location.href.substring(
              0,
              window.location.href.indexOf("upload-docs/")
            )}sat-sign-off/${satDetails?._id}`,
          }),
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      // .then(() => {
      //   dispatch({
      //     type: "LOAD_SNACKBAR",
      //     payload: {
      //       message: "File successfully submitted and mail triggered to PD.",
      //       type: "success",
      //       open: true,
      //     },
      //   });
      // });
      setIsSubmitted(true);

      setSnackbarOpen(true);
      setSnackbarMessage("File successfully submitted and shared to PD.");
      setSnackbarType("success");
    });
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    console.log("654", e.dataTransfer.files);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileSelect(e.dataTransfer.files);
    }
    console.log("660", commentFileInput?.current);

    if (commentFileInput?.current) {
      commentFileInput.current.value = "";
    }
    // if (commentFileInput?.current?.value) {
    //   commentFileInput.current.value = "";
    // }
    // if (commentFileInput?.current.open) {
    //   commentFileInput?.current?.click();
    // }
    if (commentFileInput?.current) {
      commentFileInput.current.hidden = true;
    }
  };

  return (
    <>
      <Card
        data-testid="ticket-list-table"
        style={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          height: "auto",
          padding: "15px",
          color: "white",
          fontFamily: "Poppins",
          borderRadius: "12px",
          backgroundColor: "#293368",
          border: "#293368",
        }}
      >
        {loading === true ? (
          <CircularProgress style={{ margin: "20vh 20vw", color: "white" }} />
        ) : (
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            spacing={2}
            style={{
              fontFamily: "Poppins",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <div style={{ textAlign: "center", width: "100%" }}>
              <div
                style={{ width: "20%", margin: "auto" }}
                ref={animationContainer}
              ></div>
            </div>

            <div style={{ padding: "0 20px", width: "100%" }}>
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  fontSize: "1.8rem",
                  fontWeight: "bold",
                }}
              >
                {`${
                  satDetails?.plant_name
                } Line ${satDetails?.line_name.substring(
                  satDetails?.plant_name.length,
                  satDetails?.line_name.length
                )} has been accepted by the Plant Director`}
              </Typography>
              <Typography variant="h6">
                {isSubmitted === true
                  ? "Documents successfully submitted to Plant Director"
                  : "Please upload the documents to Sign Off"}
              </Typography>
            </div>

            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              spacing={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {isSubmitted === true ? (
                <></>
              ) : (
                <Grid item xs={6}>
                  <form
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  >
                    <Button
                      variant="outlined"
                      style={{
                        border: "1px dashed #8476EF",
                        backgroundColor: "#232A58",
                        display: "flex",
                        width: "100%",
                        height: "400px",
                        padding: "20px 40px",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        color: "#FFF",
                        cursor: "pointer",
                      }}
                      onClick={handleAttachClick}
                      disabled={isSubmitted === true}
                    >
                      <img
                        src={UploadIcon}
                        style={{
                          cursor: "pointer",
                          margin: "0em 0.5em",
                          width: "61px",
                          height: "60px",
                        }}
                      />
                      &nbsp;
                      <Typography
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          fontSize: "20px",
                        }}
                      >
                        {" "}
                        Drop items here
                      </Typography>
                      &nbsp;
                      <Grid
                        item
                        style={{
                          color: "#ABA7AD",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        }}
                      >
                        OR
                      </Grid>
                      &nbsp;
                      <Button
                        style={{
                          backgroundColor: "#293368",
                          color: "#FFF",
                          fontFamily: "Poppins",
                          borderRadius: "8px",
                          padding: "10px, 18px, 10px, 18px",
                          gap: "10px",
                        }}
                      >
                        Browse Files
                      </Button>
                    </Button>
                    <input
                      type="file"
                      ref={commentFileInput}
                      onChange={(e) => handleFileSelect(e.target.files)}
                      style={{ display: "none" }}
                      multiple
                      disabled={isSubmitted === true}
                      accept=".pdf,.ppt,.pptx,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.doc,.docx,application/msword)"
                    />
                  </form>
                </Grid>
              )}
              {
                <Grid
                  container
                  xs={isSubmitted === true ? 12 : 6}
                  style={{
                    display: "flex",
                    // height: "455px",
                    // flexDirection: "column",
                    gap: "10",
                  }}
                >
                  <CustomScrollbars style={{ height: "28.5em" }}>
                    {uploadedFile?.map((fileName: any, index: any) => (
                      // <Grid
                      //   item
                      //   style={{
                      //     width: "auto",
                      //     //   padding: "20px 16px 20px 20px",
                      //   }}
                      //   onClick={() => handleFileClick(index)}
                      // >
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: " 1em",
                        }}
                        key={index}
                        // onClick={() => handleFileClick(index)}
                      >
                        <Button
                          variant="contained"
                          style={{
                            background: "#232A58",
                            width: "100%",
                            margin: "0em 0.5em",
                            justifyContent: "space-between",
                            padding: "15px",
                          }}
                        >
                          <Tooltip
                            title={`${fileName.name} 
                        ${fileName.date} ${fileName.time}`}
                          >
                            <Typography noWrap style={{}}>
                              {fileName.name} &nbsp;&nbsp;
                              {fileName.date} {fileName.time}
                            </Typography>
                          </Tooltip>
                          <div style={{ display: "flex" }}>
                            <FileDownloadIcon
                              style={{ cursor: "pointer", margin: "0em 0.5em" }}
                              onClick={() =>
                                handleDownload(fileName.url, fileName.name)
                              }
                            />
                            {satDetails.oemDocsSubmitted === true ? (
                              <></>
                            ) : (
                              <ClearIcon
                                style={{ margin: "0em 0.5em" }}
                                onClick={() => {
                                  handleDeleteFile(index);
                                }}
                              />
                            )}
                          </div>
                        </Button>
                      </Grid>
                    ))}
                  </CustomScrollbars>
                </Grid>
              }
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "flex-end",
                marginBottom: "1em",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                style={{
                  color: isSubmitted === true ? "#b3b3b3" : "",
                  backgroundColor: isSubmitted === true ? "grey" : "",
                }}
                onClick={() => {
                  setSubmitFileButton(!submitFileButton);
                }}
                disabled={isSubmitted === true}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        )}
      </Card>
      {submitFileButton && (
        <SubmitFilePopup
          role="OEM"
          submitFileButton={submitFileButton}
          setSubmitFileButton={setSubmitFileButton}
          handleSubmitFileModalOpen={handleSubmitFileModalOpen}
          handleSubmitFileModalClose={handleSubmitFileModalClose}
          handleSubmitOEMDocs={handleSubmitOEMDocs}
        />
      )}
      {snackbarOpen && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackbarOpen}
          autoHideDuration={6000} // Adjust as needed
          onClose={() => setSnackbarOpen(false)}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarType}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      )}
    </>
  );
};

export default OEMSignOffPage;
