import {
  LOAD_DASHBOARDS,
  LOAD_DASHBOARDS_SUCCESS,
  LOAD_DASHBOARDS_ERROR,
} from "../Types/dashboards.constants";

// import axios from "axios";
import axios from "../../Utils/AxiosConfig/axiosConfigMonarch";
import { put, call, takeEvery } from "redux-saga/effects";

//function to get all plants from the api
const getDashboards = async () => {
  let dashboards: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  res = await axios.get(`dashboards`);
  dashboards = res.data;
  return dashboards;
};

export function* listDashboards(): any {
  try {
    const data = yield call(() =>
      getDashboards().then((res) => {
        return res;
      })
    );
    yield put({
      type: LOAD_DASHBOARDS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // console.error(error.response);
    yield put({
      type: LOAD_DASHBOARDS_ERROR,
      payload: null,
    });
  }
}

export function* listDashboardsSaga() {
  yield takeEvery(LOAD_DASHBOARDS, listDashboards);
}
