import React, { useState, useEffect } from "react";
import Alert from "../../componets/Modal/RefreshModal";
const RefreshModal = (props: any) => {
  React.useEffect(() => {
    props.setAlertClose();
  }, []);

  const handleContinue = () => {
    //window.location.reload();
    window.location.href = "";
    props.setAlertClose();
  };

  return (
    <Alert
      open={props.isAlertOpen}
      handleContinue={handleContinue}
      setOpen={props.setAlertClose}
    />
  );
};

export default RefreshModal;
