import {
  LOAD_KPIS,
  LOAD_KPIS_SUCCESS,
  LOAD_KPIS_ERROR,
} from "../Types/kpis.constants";

// import axios from "axios";
import axios from "../../Utils/AxiosConfig/axiosConfigMonarch";
import { put, call, takeEvery } from "redux-saga/effects";

//function to get all plants from the api
const getKpis = async () => {
  let kpis: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  res = await axios.get(`kpis`);
  kpis = res.data;
  return kpis;
};

export function* listKpis(): any {
  try {
    const data = yield call(() =>
      getKpis().then((res) => {
        return res;
      })
    );
    yield put({
      type: LOAD_KPIS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // console.error(error.response);
    yield put({
      type: LOAD_KPIS_ERROR,
      payload: null,
    });
  }
}

export function* listKpisSaga() {
  yield takeEvery(LOAD_KPIS, listKpis);
}
