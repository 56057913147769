import React from "react";
import { Helmet } from "react-helmet-async";
import DowntimeLadingPage from "./DowntimeLandingPage";

function DowntimeDashboard() {
  const [view, setView] = React.useState("Dashboard");
  const [selectedPlant, setSelectedPlant] = React.useState<any>(null);
  const [correspondingCubes, setCorrespondingCubes] = React.useState<any>([]);

  return (
    <>
      <Helmet>
        <title>Downtime Requests</title>
      </Helmet>
      <DowntimeLadingPage
        view={view}
        setView={setView}
        selectedPlant={selectedPlant}
        setSelectedPlant={setSelectedPlant}
        correspondingCubes={correspondingCubes}
        setCorrespondingCubes={setCorrespondingCubes}
      />
    </>
  );
}

export default DowntimeDashboard;
