export const LOAD_USER_PERMISSIONS = "LOAD_USER_PERMISSIONS";
export const LOAD_USER_PERMISSIONS_SUCCESS = "LOAD_USER_PERMISSIONS_SUCCESS";
export const LOAD_USER_PERMISSIONS_ERROR = "LOAD_USER_PERMISSIONS_ERROR";
export const UPDATE_USER_PERMISSION = "UPDATE_USER_PERMISSION";
export const UPDATE_USER_PERMISSIONS_SUCCESS =
  "UPDATE_USER_PERMISSIONS_SUCCESS";
export const UPDATE_USER_PERMISSIONS_ERROR = "UPDATE_USER_PERMISSIONS_ERROR";
export const DELETE_USER_PERMISSION = "DELETE_USER_PERMISSION";
export const DELETE_USER_PERMISSIONS_SUCCESS =
  "DELETE_USER_PERMISSIONS_SUCCESS";
export const DELETE_USER_PERMISSIONS_ERROR = "DELETE_USER_PERMISSIONS_ERROR";
