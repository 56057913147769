import {
  ADD_NOTIFICATION,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_ERROR,
  CLEAR_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS_SUCCESS,
  CLEAR_NOTIFICATIONS_ERROR,
  REMOVE_NOTIFICATION,
  REMOVE_NOTIFICATION_SUCCESS,
  REMOVE_NOTIFICATION_ERROR,
  READ_NOTIFICATION,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_ERROR,
} from "../Types/notifications.constants";

import { put, takeEvery } from "redux-saga/effects";

export function* addNotification(action: any): any {
  try {
    if (action?.payload?.alert_type !== "downtime") {
      yield put({
        type: ADD_NOTIFICATION_SUCCESS,
        payload: action.payload,
      });
    }
  } catch (error) {
    //  / console.error(error.response);
    yield put({
      type: ADD_NOTIFICATION_ERROR,
      payload: null,
    });
  }
}

export function* clearNotifications(action: any): any {
  try {
    yield put({
      type: CLEAR_NOTIFICATIONS_SUCCESS,
      payload: null,
    });
  } catch (error) {
    //  / console.error(error.response);
    yield put({
      type: CLEAR_NOTIFICATIONS_ERROR,
      payload: null,
    });
  }
}

export function* removeNotification(action: any): any {
  try {
    yield put({
      type: REMOVE_NOTIFICATION_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    //  / console.error(error.response);
    yield put({
      type: REMOVE_NOTIFICATION_ERROR,
      payload: null,
    });
  }
}

export function* markAsRead(action: any): any {
  try {
    yield put({
      type: READ_NOTIFICATION_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    //  / console.error(error.response);
    yield put({
      type: READ_NOTIFICATION_ERROR,
      payload: null,
    });
  }
}

export function* addNotificationsSaga() {
  yield takeEvery(ADD_NOTIFICATION, addNotification);
  yield takeEvery(REMOVE_NOTIFICATION, removeNotification);
  yield takeEvery(CLEAR_NOTIFICATIONS, clearNotifications);
  yield takeEvery(READ_NOTIFICATION, markAsRead);
}
