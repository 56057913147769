import { ReactElement, FC } from "react";
import { Box, Typography } from "@mui/material";

interface LegendItem {
  color?: string;
  icon?: ReactElement;
  name: string;
}

interface NavQualLegendProps {
  legendTitle?: string;
  legendItems: LegendItem[];
}

const NavQualLegend: FC<NavQualLegendProps> = ({
  legendTitle = "",
  legendItems,
}) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    bgcolor="#232A58"
    py={2}
  >
    <Typography variant="body1" mr={3}>
      {legendTitle}
    </Typography>
    {legendItems.map(({ name, color, icon }, index) => (
      <Box key={index} display="flex" alignItems="center" mr={3}>
        {color && (
          <Box
            bgcolor={color}
            width={20}
            height={20}
            mr={1}
            borderRadius="50%"
          />
        )}
        {icon}
        <Typography variant="body1">{name}</Typography>
      </Box>
    ))}
  </Box>
);

export default NavQualLegend;
