import {
    LOAD_SAT_DETAILS,
    LOAD_SAT_DETAILS_SUCCESS,
    LOAD_SAT_DETAILS_ERROR,
  } from "../Types/satDetails.constants";
  
  // import axios from "axios";
  import axios from "../../Utils/AxiosConfig/axiosConfig";
  import { put, call, takeEvery } from "redux-saga/effects";
  
  //function to get all devices from the api
  const getSatDetails = async () => {
    let satDetails: any = [];
    let res = null,
      page = 1;
    //loop to fetch all pages from api
    do {
      res = await axios.get(`satdetails?pageSize=100&page=${page++}`);
      satDetails = satDetails.concat(res.data.rows);
    } while (res.data.page < res.data.totalPages);
    return satDetails;
  };
  
  export function* listSATDetails(): any {
    try {
      const data = yield call(() =>
      getSatDetails().then((res) => {
          return res;
        })
      );
      yield put({
        type: LOAD_SAT_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      console.error(error.response);
      yield put({
        type: LOAD_SAT_DETAILS_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
  
  export function* listSATDetailsSaga() {
    yield takeEvery(LOAD_SAT_DETAILS, listSATDetails);
  }
  