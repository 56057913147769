import { FC } from "react";
import { Typography } from "@mui/material";

interface NoDataProps {
  styles?: any;
  message?: string;
}
const NoDataDisplay: FC<NoDataProps> = ({
  styles = {
    color: "white",
    margin: "15%",
    marginLeft: "45%",
    fontSize: "16px",
  },
  message = "",
}) => {
  return (
    <Typography sx={{ ...styles }}>
      No data to display {message && `for ${message}`}
    </Typography>
  );
};

export default NoDataDisplay;
