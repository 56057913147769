const LEGENDS = ["running", "pump running", "offline", "status"];
const DYNAMICQUERY = "Dynamic Query";
const DYNAMICPARAMETER = "Dynamic Parameter";
const DYNAMICREPORT = "Dynamic Report";
const DYNAMICLEGENDS = "Dynamic Legends";
const YELLOW = "#E3CD04";
const YELLOWBG1 = "#e3cd0433";
const YELLOWBG2 = "#e3cd0453";
const ORANGE = "#E38C25";
const ORANGEBG1 = "#e38c2533";
const ORANGEBG2 = "#e38c2553";
const GREEN = "#A6E154";
const GREENBG1 = "#A6E15433";
const GREENBG2 = "#A6E15453";
const RED = "#C75054";
const REDBG1 = "#C7505433";
const REDBG2 = "#C7505453";
const DEFAULT = "#656A8A";
const DEFAULT1 = "#656A8A33";
const DEFAULT2 = "#656A8A53";

export {
  LEGENDS,
  DYNAMICPARAMETER,
  DYNAMICREPORT,
  DYNAMICQUERY,
  DYNAMICLEGENDS,
  YELLOW,
  ORANGE,
  GREEN,
  RED,
  DEFAULT,
  YELLOWBG1,
  YELLOWBG2,
  ORANGEBG1,
  ORANGEBG2,
  GREENBG1,
  GREENBG2,
  REDBG1,
  REDBG2,
  DEFAULT1,
  DEFAULT2,
};
