import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Typography, Grid, Button, Stack } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    "&.MuiSvgIcon-root": {
      fontSize: "30px",
      color: "#8476EF",
      marginBottom: "20px",
    },
  },
  containedButton: {
    "&.MuiButton-root": {
      backgroundColor: "#8476EF",
      color: "white",
      marginTop: "30px",
      width: "150px",
      fontWeight: "500",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#8476EF",
        border: "1px solid #000",
      },
    },
  },
}));

function Alert(props: any) {
  const classes = useStyles();
  const appMode = localStorage.getItem("appMode");

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {xs:285, md:470},
    boxShadow: "8px 24px 91px -11px rgba(12, 18, 52, 0.3)",
    p: "16px 40px",
    color: "white",
    borderRadius: "12px",
    bgcolor: "#293368",
    border: "#293368",
    ":focus": { outline: "none" },
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={(e: any, reason: string) => {
        if (reason !== "backdropClick") {
          props.handleClose();
        } else {
          e.stopPropagation();
        }
      }}
      closeAfterTransition
      onClick={(e) => e.stopPropagation()}
    >
      <Fade in={props.open}>
        <Stack sx={style} alignItems="center">
          <RefreshIcon
            style={{
              fontSize: "30px",
              color: "#8476EF",
              marginBottom: "20px",
            }}
          />
          <Typography sx={{ fontSize: "1.2rem" }}>Role Changed</Typography>
          <Typography
            align="center"
            style={{
              fontWeight: "400",
              fontSize: "13px",
              color: appMode === "Dark" ? "text.secondary" : "black",
              marginTop: "25px",
            }}
          >
            Your role has changed
          </Typography>
          <Typography
            align="center"
            style={{
              fontWeight: "400",
              fontSize: "14px",
              marginTop: "25px",
              color: appMode === "Dark" ? "text.secondary" : "black",
            }}
          >
            Please click Refresh to view the changes
          </Typography>
          <Button
            className={classes.containedButton}
            onClick={() => {
              props.handleContinue();
            }}
          >
            Refresh
          </Button>
        </Stack>
      </Fade>
    </Modal>
  );
}

export default Alert;
