import { ReactElement } from "react";
import { dashboardStyles } from "../DowntimeLandingPageStyles";
import { FormControl, Input } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export interface tabOptions {
  label: string;
  key: string;
}

export interface TabsControlInterface {
  defaultValue: string | null;
  handleChange(event: any): void;
  className?: any;
  placeholder: string;
  fontSize?: string | null;
}

const SearchField = (props: TabsControlInterface): ReactElement => {
  const classes = dashboardStyles();

  return (
    <FormControl
      className={
        props.className || `${classes.multiDropdown} ${classes.searchBox}`
      }
    >
      <FormControl variant="standard">
        <Input
          id="input-with-icon-adornment"
          placeholder={props.placeholder}
          endAdornment={<SearchIcon 
            sx={{
            fontSize: {
              xs: '1rem',
              sm: '1.2rem',
              md: '1.4rem',
              lg: '1.6rem'
            }
          }} />}
          defaultValue={props.defaultValue}
          onChange={(event: any) => {
            if (typeof props.handleChange === "function") {
              props.handleChange(event);
            }
          }}
        />
      </FormControl>
    </FormControl>
  );
};

export default SearchField;
