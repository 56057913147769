import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";


export const exportTicketPDF = async () => {
  const ticketDetailsContainer = document.getElementById('ticket-details-container');
  
  if (!ticketDetailsContainer) {
    console.error('Ticket details container not found.');
    return false;
  }

  try {
    const canvas = await html2canvas(ticketDetailsContainer, {
      scale: 2, 
      useCORS: true,
      logging: true, 
      allowTaint: true, 
      backgroundColor: "#232A58",
    });
    const imgData = canvas.toDataURL('image/png');
    
    const pdf = new jsPDF("portrait", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save(`DowntimeTicketDetails_${moment().format("YYYYMMDD_HHmmss")}.pdf`);

    return true;
  } catch (error) {
    console.error('Error generating PDF:', error);
    return false;
  }
};

