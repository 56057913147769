import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import { isUndefined } from 'lodash'
import { FC, SyntheticEvent, useMemo } from 'react'
import { chipDisplay } from '../../Constants'
import { AssetSelector as useStyles } from '../helpers/styles'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

interface CustomChipProps {
	label: string
}

const CustomChip: FC<CustomChipProps> = ({ label }) => {
	const classes = useStyles()

	return <Chip label={label} className={classes.chipStyles} />
}

export interface AssetSelectProps {
	assetsAvailable: string[]
	selectedAssets: string[]
	onChange: (selectedAssets: string[]) => void
}

const AssetSelect: FC<AssetSelectProps> = ({
	assetsAvailable,
	selectedAssets,
	onChange
}) => {
	const isAllSelected = selectedAssets.length === assetsAvailable.length

	const handleChange = (_: SyntheticEvent<Element, Event>, value: string[]) => {
		const newValue: string = value[1]

		// it means all assets are already selected and user clicked on 'All Assets'
		if (isUndefined(newValue)) return onChange([])

		// it means the user clicked on 'All Assets'
		if (newValue === chipDisplay.allAssets)
			return onChange([...assetsAvailable])

		const newSelectedAssets = [...selectedAssets]

		const isSelected = selectedAssets.includes(newValue)

		if (isSelected) {
			const selectedIndex = selectedAssets.indexOf(newValue)
			newSelectedAssets.splice(selectedIndex, 1)
		} else {
			newSelectedAssets.push(newValue)
		}

		onChange(newSelectedAssets)
	}

	const selectedAssetsType = useMemo<string>(
		() =>
			isAllSelected
				? chipDisplay.allAssets
				: selectedAssets.length === 0
				? chipDisplay.noAssets
				: selectedAssets.length === 1
				? chipDisplay.singleAsset
				: chipDisplay.multiAssets,
		[isAllSelected, selectedAssets]
	)

	return (
		<Autocomplete
			sx={{
				width: 300,
				height: '66px',
				paddingTop: '1em',
				paddingBottom: '1em',
				marginTop: '1em',
				marginLeft: '2em',
				marginBottom: '2em',
				position: 'relative'
			}}
			size='medium'
			multiple
			id='checkboxes-tags-demo'
			options={[chipDisplay.allAssets, ...assetsAvailable]}
			disableCloseOnSelect
			freeSolo
			clearOnEscape
			disableClearable
			renderTags={tagValue =>
				tagValue.map((option, index) => (
					<CustomChip key={index} label={option} />
				))
			}
			value={[selectedAssetsType]}
			ListboxProps={
				{
					sx: {
						'& li:nth-of-type(even)': {
							backgroundColor: '#232a58'
						}
					}
				} as any
			}
			onChange={handleChange}
			renderOption={(renderOptionProps, option) => (
				<li {...renderOptionProps}>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{ marginRight: 8 }}
						checked={selectedAssets.includes(option) || isAllSelected}
					/>
					{option}
				</li>
			)}
			renderInput={params => (
				<TextField
					{...params}
					label='Select Asset'
					style={{ margin: 'none', padding: 'none' }}
					InputLabelProps={{
						style: {
							backgroundColor: 'rgb(48, 54, 103)',
							padding: '1px 15px',
							borderRadius: '10px'
						}
					}}
				/>
			)}
		/>
	)
}

export default AssetSelect
