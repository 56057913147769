import { LOAD_BANNER, BANNER_OPEN } from "../Types/banner.constants";
import { put, takeEvery } from "redux-saga/effects";

export function* loadBanner(action: any): any {
  yield put({
    type: BANNER_OPEN,
    payload: action.payload,
  });
}

export function* addBannerSaga() {
  yield takeEvery(LOAD_BANNER, loadBanner);
}
