import * as React from "react";
import Button from "@mui/material/Button";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import { Box, DialogTitle } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { AddCommentButtonProps } from "../Helpers/interfaces";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#232a58",
  boxShadow: "none",
  p: 4,
  borderRadius: "16px",
};

export default function AddCommentButton({
  parentContainerId,
}: AddCommentButtonProps) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Button
        onClick={handleOpen}
        variant="outlined"
        style={{ marginLeft: "1em" }}
        sx={{ fontWeight: "regular" }}
      >
        <MapsUgcIcon style={{ marginRight: "3px" }} /> Add Comment
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        container={() => document.getElementById(parentContainerId)}
        BackdropProps={{
          style: {
            backgroundColor: "transparent",
          },
        }}
        style={{ position: "absolute" }}
      >
        <Box sx={style}>
          <DialogTitle
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography
              style={{ marginLeft: "30%" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              To add a comment,
            </Typography>
            <div
              style={{
                position: "absolute",
                top: "2px",
                right: "0",
                borderRadius: "35px",
                padding: "3px 7px",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ClearOutlinedIcon
                onClick={handleClose}
                style={{
                  fontSize: "20px",
                }}
              />
            </div>
          </DialogTitle>

          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            please click on a datapoint on the graph
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
