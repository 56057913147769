import {
  LOAD_PLANTS,
  LOAD_PLANTS_SUCCESS,
  LOAD_PLANTS_ERROR,
  UPDATE_PLANT,
  UPDATE_PLANT_SUCCESS,
  DELETE_PLANT,
  DELETE_PLANT_SUCCESS,
} from "../Types/plants.constants";

const INITIAL_STATE = {
  loading: false,
  plants: [],
  error: null,
};

export const plantsListReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_PLANTS:
      return { ...state, loading: true, plants: [] };
    case LOAD_PLANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        plants: action.payload,
      };
    case UPDATE_PLANT:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PLANT_SUCCESS:
      return {
        ...state,
        loading: false,
        plants: Array.isArray(action.payload)
          ? [...state.plants, ...action.payload].sort((a: any, b: any) =>
              a.plant_name.toLowerCase() > b.plant_name.toLowerCase()
                ? 1
                : b.plant_name.toLowerCase() > a.plant_name.toLowerCase()
                ? -1
                : 0
            )
          : [
              ...state.plants.filter((ele: any) => {
                return ele._id !== action.payload._id;
              }),
              action.payload,
            ].sort((a: any, b: any) =>
              a.plant_name.toLowerCase() > b.plant_name.toLowerCase()
                ? 1
                : b.plant_name.toLowerCase() > a.plant_name.toLowerCase()
                ? -1
                : 0
            ),
      };
    case DELETE_PLANT:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PLANT_SUCCESS:
      return {
        ...state,
        loading: false,
        plants: state.plants.filter((ele: any) => {
          return ele._id !== action.payload._id;
        }),
      };
    case LOAD_PLANTS_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
