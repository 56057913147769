import {
  LOAD_LOCATIONS,
  LOAD_LOCATIONS_SUCCESS,
  LOAD_LOCATIONS_ERROR,
  UPDATE_LOCATION,
  UPDATE_LOCATION_SUCCESS,
  DELETE_LOCATION,
  DELETE_LOCATION_SUCCESS,
} from "../Types/locations.constants";

const INITIAL_STATE = {
  loading: false,
  locations: [],
  error: null,
};

export const locationsListReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_LOCATIONS:
      return { ...state, loading: true, locations: [] };
    case LOAD_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: action.payload,
      };
    case UPDATE_LOCATION:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: Array.isArray(action.payload)
          ? [
              ...state.locations.filter((ele: any) => {
                return ele._id !== action.payload._id;
              }),
              ...action.payload,
            ]
          : [
              ...state.locations.filter((ele: any) => {
                return ele._id !== action.payload._id;
              }),
              action.payload,
            ],
      };

    case DELETE_LOCATION:
      return {
        ...state,
        loading: true,
      };
    case DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: state.locations.filter((ele: any) => {
          return ele._id !== action.payload._id;
        }),
      };
    case LOAD_LOCATIONS_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
