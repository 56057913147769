import { ReactElement } from "react";
import { dashboardStyles } from "../DowntimeLandingPageStyles";
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

export interface confirmDailog {
  // Dialog open and titles
  open: boolean;
  title: string;
  secondaryTitle?: string;

  // Dialong content
  primaryListTitle?: string;

  // Dialog primary button text and action
  primaryButtonText: string;
  primaryAcion(): void;
  primaryButtonClass?: any;

  // Dialong secondary button and action
  secondaryButtonText: string;
  secondaryAction(): void;
  secondaryButtonClass?: any;

  // Any specific icon
  icon?: any;
}

const ConfirmAlertDialog = (props: confirmDailog): ReactElement => {
  const classes = dashboardStyles();
  return (
    <Dialog
      open={props.open}
      aria-labelledby="form-dialog-title"
      maxWidth={false}
      className={classes.tickeDialog}
    >
      <div className={classes.confirmDialogWrapper}>
        <div className="contentWrapper">
          <div className={classes.confirmDialogTitleAndIcon}>
            {props.icon}

            <DialogTitle>
              {props.title}

              {props.secondaryTitle && (
                <Typography variant="body1">{props.secondaryTitle}</Typography>
              )}
            </DialogTitle>

            {props.primaryListTitle && (
              <List sx={{ listStyleType: "disc" }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary={props.primaryListTitle} />
                </ListItem>
              </List>
            )}
          </div>
          <Grid item md={12} className="btnFoot">
            {props.secondaryButtonText && (
              <Button
                className={`${props.secondaryButtonClass}`}
                variant="outlined"
                onClick={() => {
                  if (typeof props.secondaryAction === "function") {
                    props.secondaryAction();
                  }
                }}
              >
                {props.secondaryButtonText}
              </Button>
            )}
            {props.primaryButtonText && (
              <Button
                onClick={() => {
                  if (typeof props.primaryAcion === "function") {
                    props.primaryAcion();
                  }
                }}
                className={`${props.primaryButtonClass} saveBtn`}
              >
                {props.primaryButtonText}
              </Button>
            )}
          </Grid>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmAlertDialog;
