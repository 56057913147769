import { useState, useEffect, useMemo, FC } from "react";
import { ImageOverlay, MapContainer, Marker } from "react-leaflet";
import { useSelector } from "react-redux";
import { CircularProgress, Typography } from "@mui/material";
import L from "leaflet";
import {
  SeedElement,
  MapObject,
  PlantBoundDetails,
  NavQualityWdhHgt,
} from "../Helpers/interfaces";
import {
  getPlantLayoutImg,
  getPlantCoordinates,
  setDynamicZoomLevelToMap,
  lgvNameRenderer,
  convertReactComponentToHtmlIcon,
  coordinateCorrection,
  handleMouseOver,
  handleMouseOut,
  getNavQualityWdhHgt,
} from "../Helpers/utils";
import LgvIcon from "../Component/LgvIcon";
import {
  rectBottomLeftXNew,
  rectBottomLeftYNew,
  rectTopRightXNew,
  rectTopRightYNew,
} from "../Constants";
import NoDataDisplay from "../Component/NoDataDisplay";

interface Range {
  range: [number, number];
  color: string;
  name: string;
}
interface LgvConfig {
  chartTitle: string;
  legendTitle: string;
  ranges: Range[];
}

interface DynamicPlantLayoutProps {
  plantCode: string;
  lgvData: object[];
  lgvConfig: LgvConfig;
  timeMachineOn: boolean;
  lgvPathData: object[];
  selectedAssets: string[];
  mapContainerZoomLevel: any;
}
const DynamicPlantLayout: FC<DynamicPlantLayoutProps> = ({
  plantCode,
  lgvData,
  lgvConfig,
  timeMachineOn,
  lgvPathData,
  selectedAssets,
  mapContainerZoomLevel,
}) => {
  const seedsList = useSelector((state: any) => state.seedsList);
  const snackBarState = useSelector((state: any) => state.snackBar);
  const [mapObject, setMapObject] = useState<MapObject>();
  const [plantBoundDetails, setPlantBoundDetails] =
    useState<PlantBoundDetails>();
  const [convertedLgvData, setConvertedLgvData] = useState<any>();
  const [convertedValidLiveLgvData, setConvertedValidLiveLgvData] =
    useState<any>();
  const [convertedLgvPathData, setConvertedLgvPathData] = useState<any>();
  const [hoverLgvNumber, setHoverLgvNumber] = useState<string>("");
  const [lgvCorrectionValues, setLgvCorrectionValues] = useState<any>();
  const [canUpdateData, setCanUpdateData] = useState<boolean>(true);
  const [isNoData, setIsNoData] = useState<boolean>(false);
  const [navQualityWdhHgt, setNavQualityWdhHgt] = useState<NavQualityWdhHgt>({
    path: {
      width: "14px",
      height: "14px",
    },
    icon: {
      width: "18px",
      height: "18px",
      fontSize: "12px",
    },
  });

  const plantHeatMapDetails = seedsList.seeds.find((ele: SeedElement) => {
    return ele.seed_key_name === "LgvHealthConfig";
  })?.seed_data_value;
  const lgvHealthConfig = seedsList.seeds.find((seed: any) => {
    return seed.seed_key_name === "LGVHealth";
  })?.seed_data_value;
  const lgvNavQualityConfigValues = useMemo(() => {
    // If LGVHealth configuration exists, filter LGV_NAV_QUALITY config
    if (!lgvHealthConfig) return;

    const lgvNavQualityConfig = lgvHealthConfig.filter(
      ({ key }: any) => key === "LGV_NAV_QUALITY"
    );

    // Parse LGV_NAV_QUALITY config values if found
    if (lgvNavQualityConfig.length > 0) {
      return JSON.parse(lgvNavQualityConfig[0]?.value);
    }
  }, [lgvHealthConfig]);
  const navQualityHeatMapConfig =
    lgvNavQualityConfigValues["Navigation_Heat_Map"];

  const mapWhenReadyHandler = (map?: L.Map) => {
    setMapObject({ map });
  };

  //Web Object Coordinates converter
  const mapCoordinate = (
    // rescaling (downscaling form the ignition app) @tm
    coordinateX: number,
    coordinateY: number,
    renderObject?: string
  ): { x: number; y: number } | undefined => {
    if (plantCode) {
      const {
        rectBottomLeftX,
        rectBottomLeftY,
        rectTopRightX,
        rectTopRightY,
      }: any = getPlantCoordinates(plantCode, renderObject); //custom coordinates @tm
      const ratioX: number =
        (coordinateX - rectBottomLeftX) / (rectTopRightX - rectBottomLeftX);
      const ratioY: number =
        (coordinateY - rectBottomLeftY) / (rectTopRightY - rectBottomLeftY);

      const translatedX: any =
        rectBottomLeftXNew + ratioX * (rectTopRightXNew - rectBottomLeftXNew);
      const translatedY: any =
        rectBottomLeftYNew + ratioY * (rectTopRightYNew - rectBottomLeftYNew);

      return {
        x: translatedX,
        y: translatedY,
      };
    }
  };

  //Move Map to the center of view
  const centerMapView = (unit1?: any, unit2?: any) => {
    let convertedCoordinates: any;
    if (unit1 && unit2) {
      convertedCoordinates = mapCoordinate(unit1, unit2);
    } else {
      convertedCoordinates = mapCoordinate(275000, 70000);
    }
    if (convertedCoordinates) {
      const convertedPoints = L.point(
        convertedCoordinates?.x || 0,
        convertedCoordinates?.y || 0
      );
      const convertedLatLng =
        mapObject?.map.target.layerPointToLatLng(convertedPoints);
      mapObject?.map.target.setView([
        convertedLatLng?.lat || 0,
        convertedLatLng?.lng || 0,
      ]);
    }
  };

  //Convert LGV Coordinate from Ignition system to LGV One system
  const lgvCoordinateConverter = () => {
    try {
      let lgvRecords: any = [];
      let lgvPathRecords: any = [];
      let lgvValidLiveRecords: any = [];

      lgvData.map((asset: any) => {
        if (selectedAssets.includes(asset?.lgvId || asset?.deviceName)) {
          lgvRecords.push(asset);
        }
      });
      lgvPathData.map((asset: any) => {
        if (selectedAssets.includes(asset?.lgvId || asset?.deviceName)) {
          lgvPathRecords.push(asset);
        }
      });

      if (mapObject) {
        lgvRecords?.forEach((marker: any) => {
          if (marker && marker?.xPos && marker?.yPos) {
            const convertedCoordinates: any = mapCoordinate(
              marker["xPos"],
              marker["yPos"],
              "lgv"
            );
            const convertedPoints = L.point(
              convertedCoordinates?.x,
              convertedCoordinates?.y
            );
            const convertedLatLng =
              mapObject?.map?.target?.layerPointToLatLng(convertedPoints);
            marker.currentPos = {
              lat: convertedLatLng?.lat,
              lng: convertedLatLng?.lng,
            };
          }
        });

        lgvPathRecords?.forEach((marker: any) => {
          if (marker && marker?.xPos && marker?.yPos) {
            const convertedCoordinates: any = mapCoordinate(
              marker["xPos"],
              marker["yPos"],
              "lgv"
            );
            const convertedPoints = L.point(
              convertedCoordinates?.x,
              convertedCoordinates?.y
            );
            const convertedLatLng =
              mapObject?.map?.target?.layerPointToLatLng(convertedPoints);
            marker.currentPos = {
              lat: convertedLatLng?.lat,
              lng: convertedLatLng?.lng,
            };
          }
        });

        setConvertedLgvPathData(lgvPathRecords);
        if (timeMachineOn) {
          setConvertedLgvData(lgvRecords);
        } else {
          if (!convertedValidLiveLgvData?.length) {
            setConvertedValidLiveLgvData(lgvRecords);
            setConvertedLgvData(lgvRecords);
          } else {
            selectedAssets.forEach((item: any) => {
              if (item.trim() !== "All Assets") {
                const lgvNewData = lgvRecords?.find(
                  (lgvRec: any) =>
                    item === (lgvRec?.lgvId || lgvRec?.deviceName)
                );
                if (
                  lgvNewData &&
                  lgvNewData?.xPos &&
                  lgvNewData?.yPos &&
                  lgvNewData?.navQuality &&
                  lgvNewData?.segmentId
                ) {
                  lgvValidLiveRecords.push(lgvNewData);
                } else {
                  const lgvOldData = convertedValidLiveLgvData?.find(
                    (lgvRec: any) =>
                      item === (lgvRec?.lgvId || lgvRec?.deviceName)
                  );
                  if (lgvOldData) lgvValidLiveRecords.push(lgvOldData);
                }
              }
            });
            setConvertedValidLiveLgvData(lgvValidLiveRecords);
            setConvertedLgvData(lgvValidLiveRecords);
          }
        }
      }
    } catch (err: any) {
      throw new Error(err);
    }
  };
  //Rendering Map on the Map container with Map Object and coordinates
  const RenderMap = useMemo(() => {
    if (mapObject) {
      if (
        plantBoundDetails !== undefined &&
        Object.keys(plantBoundDetails)?.length > 0
      ) {
        const { leftBottom, rightTop } = plantBoundDetails?.bounds || {};
        const { image } = plantBoundDetails;
        const convertedCoordinates: any = mapCoordinate(
          leftBottom.lat,
          leftBottom.lng,
          "map"
        ); //L , B
        const convertedPoints: any = L.point(
          convertedCoordinates?.x,
          convertedCoordinates?.y
        );
        const convertedLatLng: any =
          mapObject.map.target.layerPointToLatLng(convertedPoints);

        const convertedCoordinates1: any = mapCoordinate(
          rightTop.lat,
          rightTop.lng,
          "map"
        ); //R,T
        const convertedPoints1: any = L.point(
          convertedCoordinates1?.x,
          convertedCoordinates1?.y
        );
        const convertedLatLng1: any =
          mapObject.map.target.layerPointToLatLng(convertedPoints1);

        const overlayBounds: any = [
          [convertedLatLng?.lat, convertedLatLng?.lng],
          [convertedLatLng1?.lat, convertedLatLng1?.lng],
        ];
        return <ImageOverlay url={image} bounds={overlayBounds} />;
      }
    }
  }, [mapObject, plantBoundDetails]);

  //Render LGV Markers on the map
  const RenderLgv = useMemo(() => {
    if (convertedLgvData) {
      return convertedLgvData?.map((marker: any, index: number) => {
        let markerData = marker;
        if (!navQualityHeatMapConfig || !markerData?.xPos || !markerData?.yPos)
          return;

        const reactIcon = convertReactComponentToHtmlIcon(
          <LgvIcon
            data={lgvNameRenderer(markerData?.lgvId || markerData?.deviceName)}
            navQuality={markerData?.navQuality}
            type={"icon"}
            hoverLgvId={hoverLgvNumber}
            LgvConfig={lgvConfig}
            navQualityHeatMapConfig={navQualityHeatMapConfig}
            component={"dynamicPlantLayout"}
            navQualityWdhHgt={navQualityWdhHgt?.icon}
          />,
          "lgv-marker",
          [15, 15],
          [40.5, 48]
        );
        const lgvCorrection = coordinateCorrection(
          plantCode,
          lgvCorrectionValues
        );
        return (
          <Marker
            key={index}
            riseOnHover
            position={[
              markerData?.currentPos?.lat + lgvCorrection.x,
              markerData?.currentPos?.lng + lgvCorrection.y,
            ]}
            icon={reactIcon}
            eventHandlers={{
              mouseover: (event: any) => {
                handleMouseOver(event, marker, lgvConfig);
                setHoverLgvNumber(marker?.lgvId || marker?.deviceName);
              },
              mouseout: (event: any) => {
                handleMouseOut(event);
                setHoverLgvNumber("");
              },
            }}
          ></Marker>
        );
      });
    }
  }, [convertedLgvData, navQualityWdhHgt]);

  const RenderLgvPath = useMemo(() => {
    if (convertedLgvPathData) {
      return convertedLgvPathData?.map((marker: any, index: number) => {
        const markerData = marker;
        if (!navQualityHeatMapConfig || !markerData?.xPos || !markerData?.yPos)
          return;
        const reactIcon = convertReactComponentToHtmlIcon(
          <LgvIcon
            data={markerData?.lgvId}
            navQuality={markerData?.navQuality}
            type={"path"}
            hoverLgvId={hoverLgvNumber}
            navQualityHeatMapConfig={navQualityHeatMapConfig}
            component={"dynamicPlantLayout"}
            navQualityWdhHgt={navQualityWdhHgt?.path}
          />,
          "lgv-marker",
          [15, 15],
          [40.5, 48]
        );
        const lgvCorrection = coordinateCorrection(
          plantCode,
          lgvCorrectionValues
        );
        return (
          <Marker
            key={index}
            riseOnHover
            position={[
              markerData?.currentPos?.lat + lgvCorrection.x,
              markerData?.currentPos?.lng + lgvCorrection.y,
            ]}
            icon={reactIcon}
            eventHandlers={{
              mouseover: (e: any) => e?.originalEvent?.stopPropagation(),
              mouseout: (e: any) => e?.originalEvent?.stopPropagation(),
            }}
          ></Marker>
        );
      });
    }
  }, [convertedLgvPathData, hoverLgvNumber, navQualityWdhHgt]);

  useEffect(() => {
    if ((canUpdateData || timeMachineOn) && lgvData.length) {
      lgvCoordinateConverter();
      setCanUpdateData(false);
    }
  }, [lgvData]);

  useEffect(() => {
    if (!canUpdateData) {
      setTimeout(() => {
        setCanUpdateData(true);
      }, 1000);
    }
  }, [canUpdateData]);

  useEffect(() => {
    setHoverLgvNumber("");
  }, [timeMachineOn]);

  useEffect(() => {
    const idInstance: any = document.getElementById(
      "dynamic-plant-layout-id-reference"
    );
    const mapContainerZoom: any = idInstance?.style?.transform;
    if (mapContainerZoomLevel) {
      setNavQualityWdhHgt(
        getNavQualityWdhHgt(mapContainerZoomLevel || mapContainerZoom)
      );
    }
  }, [mapContainerZoomLevel]);

  useEffect(() => {
    plantHeatMapDetails?.map((ele: any) => {
      if (ele?.key === "plantDetails") {
        const plantsValue = JSON.parse(ele?.value);
        setPlantBoundDetails({
          image: getPlantLayoutImg(plantCode),
          ...plantsValue?.[plantCode],
        });
      }
      if (ele?.key === "lgvCorrection") {
        setLgvCorrectionValues(JSON.parse(ele?.value));
      }
    });
  }, [plantHeatMapDetails, plantCode]);

  useEffect(() => {
    if (mapObject) {
      const screenSize = window.innerWidth;
      if (screenSize >= 1440 && screenSize < 1800) {
        setTimeout(() => {
          centerMapView();
          setDynamicZoomLevelToMap("1.0", "dynamic-plant-layout-id-reference");
        }, 1000);
      } else if (screenSize >= 1800 && screenSize < 2500) {
        setTimeout(() => {
          centerMapView(275000, 70000);
          setDynamicZoomLevelToMap("1.0", "dynamic-plant-layout-id-reference");
        }, 500);
      } else if (screenSize >= 2560 && screenSize < 3000) {
        setTimeout(() => {
          centerMapView(280000, 80000);
          setDynamicZoomLevelToMap("1.0", "dynamic-plant-layout-id-reference");
        }, 500);
      } else if (screenSize >= 3000 && screenSize < 4000) {
        setTimeout(() => {
          centerMapView(312000, 60000);
          setDynamicZoomLevelToMap("1.0", "dynamic-plant-layout-id-reference");
        }, 500);
      } else if (screenSize >= 4000 && screenSize < 5000) {
        setTimeout(() => {
          centerMapView(350000, 40000);
          setDynamicZoomLevelToMap("1.0", "dynamic-plant-layout-id-reference");
        }, 500);
      } else if (screenSize >= 5000 && screenSize < 6000) {
        setTimeout(() => {
          centerMapView(312000, 60000);
          setDynamicZoomLevelToMap("2.0", "dynamic-plant-layout-id-reference");
        }, 500);
      } else {
        centerMapView();
      }
      mapObject.map.target.doubleClickZoom.disable();
    }
  }, [mapObject, window.innerWidth]);

  useEffect(() => {
    if (snackBarState?.open) setIsNoData(true);
    else {
      if (lgvPathData?.length) setIsNoData(false);
    }
  }, [snackBarState, lgvPathData]);

  return (
    <>
      {isNoData && timeMachineOn ? (
        <NoDataDisplay />
      ) : !lgvPathData.length && timeMachineOn ? (
        <CircularProgress style={{ margin: "15%", marginLeft: "50%" }} />
      ) : !selectedAssets.filter((item) => item?.includes("LGV"))?.length ? (
        <NoDataDisplay />
      ) : (
        <MapContainer
          id="dynamic-plant-layout-id-reference"
          style={{
            height: "90%",
            width: "100%",
            background: "#293368",
            overflow: "hidden",
            border: "none",
          }}
          center={[0, 0]}
          zoom={0}
          maxZoom={6}
          maxBoundsViscosity={1.0}
          crs={L.CRS.Simple}
          attributionControl={false}
          scrollWheelZoom={false}
          whenReady={mapWhenReadyHandler}
          dragging={true}
          zoomControl={false}
          doubleClickZoom={false}
        >
          {RenderMap}
          {RenderLgv}
          {timeMachineOn ? RenderLgvPath : null}
        </MapContainer>
        // )
      )}
    </>
  );
};

export default DynamicPlantLayout;
