import {
  LOAD_SATHIERARCHYDATA,
  LOAD_SATHIERARCHYDATA_SUCCESS,
  LOAD_SATHIERARCHYDATA_ERROR,
} from "../Types/satHierarchy.constants";

const INITIAL_STATE = {
  loading: false,
  satHierarchy: {},
  error: null,
};

export const satHierarchyPlantReducer = (
  state = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case LOAD_SATHIERARCHYDATA:
      return { ...state, loading: true, satHierarchy: {} };
    case LOAD_SATHIERARCHYDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        satHierarchy: action.payload,
      };
    case LOAD_SATHIERARCHYDATA_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
