import React, { ReactElement } from "react";
import ClosureMoldQuarterlyPopUp from "./ClosureMoldQuarterlyPopUp";
import HotRunnerRefurbishmentPopUp from "./HotRunnerRefurbishmentPopUp";
import CycleTimePopUp from "./CycleTimePopUp";
import BlockedCavityPopUp from "./BlockedCavityPopUp";

const InjectionAnalyticsPopUp = (props: any): ReactElement => {
  let subType = "";

  const messageData = props.alert?.message_data || {};

  const alertSubType = messageData?.AlertSubType || "";

  const alertSplit = alertSubType?.split("-");
  if (alertSplit && alertSplit.length > 0) {
    subType = alertSplit[1]?.trim();
  }

  switch (subType) {
    case "Closure Mold Quarterly PM":
      return (
        <ClosureMoldQuarterlyPopUp
          open={props?.open}
          handleClose={props?.handleClose}
          alert={props?.alert}
          setOpen={props?.setOpen}
        />
      );
    case "Hot Runner Refurbishment":
      return (
        <HotRunnerRefurbishmentPopUp
          open={props?.open}
          handleClose={props?.handleClose}
          alert={props?.alert}
          setOpen={props?.setOpen}
        />
      );
    case "Cycle Time":
      return (
        <CycleTimePopUp
          open={props?.open}
          handleClose={props?.handleClose}
          alert={props?.alert}
          setOpen={props?.setOpen}
        />
      );
    case "Blocked Cavity":
      return (
        <BlockedCavityPopUp
          open={props?.open}
          handleClose={props?.handleClose}
          alert={props?.alert}
          setOpen={props?.setOpen}
        />
      );

    default:
      return <></>;
  }
};

export default React.memo(InjectionAnalyticsPopUp);
