import { SNACKBAR_OPEN, SNACKBAR_CLOSE } from "../Types/snackBar.constants";

const INITIAL_STATE = {
  type: "",
  message: "",
  open: false,
};

export const snackBarReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SNACKBAR_OPEN:
      const { open = false, message = "", type = "" } = action.payload;
      return {
        ...state,
        open,
        message,
        type,
      };
    case SNACKBAR_CLOSE:
      return {
        ...state,
        type: "",
        message: "",
        open: false,
      };
    default:
      return state;
  }
};
