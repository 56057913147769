import { FC, useContext, useMemo } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AutoCompleteDropdown from '../../../../../componets/Inputs/AutoCompleteDropDown'
import { NavBarProps, Plant } from '../Helpers/interfaces'
import { LGVHealthContext, LGVHealthContextValue } from './Context'
import { AssetIndicators } from '../PLSSensors/helpers/styles'

const useStyles = makeStyles(() => ({
	autoDropdown: {
		'& .MuiInputBase-root': {
			display: 'flex'
		},
		'& .MuiInputBase-input': {
			width: '100% !important'
		}
	},
	templateDropdown: {
		'& .MuiInputBase-root': {
			width: '225px',
			height: '50px'
		},
		'&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
			fontSize: '12px',
			padding: '7.5px 4px 7.5px 6px !important'
		}
	},
	typographyContainer: {
		'&.MuiTypography-root': {
			display: 'flex',
			alignItems: 'center'
		}
	}
}))

const NavBar: FC<NavBarProps> = ({
	navigationUrl,
	plantFilter,
	arrow,
	page,
	customNavigation,
	goToTickets = true,
	showLiveIndicator = false
}) => {
	const classes = useStyles()
	const liveIndicatorClasses = AssetIndicators()

	const navigate = useNavigate()
	const { selectedPlant, setSelectedPlant } =
		useContext<LGVHealthContextValue>(LGVHealthContext)

	const plantsList: Plant[] = useSelector(
		(state: any) => state.plantsList.plants
	)
	const seedsList = useSelector((state: any) => state.seedsList)

	const lgvHealthConfig = seedsList.seeds.find((seed: any) => {
		return seed.seed_key_name === 'LgvHealthConfig'
	})

	const lgvPlantCodes = useMemo<string[]>(
		() =>
			lgvHealthConfig?.seed_data_value?.find((keyObj: any) => {
				return keyObj?.key === 'availableLgvUseCasePlantList'
			})?.value || [],
		[lgvHealthConfig]
	)

	const plantCodesAvailable = useMemo<Plant[]>(
		() =>
			plantsList.filter((plant: Plant) =>
				lgvPlantCodes.includes(plant.plant_code)
			),
		[lgvPlantCodes, plantsList]
	)


	return (
		<Grid item xs={12}>
			<Grid container>
				<Grid item xs={5} textAlign='left'>
					{/* Plant Selection */}
					{plantFilter && (
						<AutoCompleteDropdown
							className={`${classes.autoDropdown} ${classes.templateDropdown}`}
							options={plantCodesAvailable}
							value={selectedPlant}
							handleChange={(_event: any, newPlant: Plant) => {
								// const newPlant = plantsList.find(
								// 	plant => plant.plant_code === newValue
								// )


								setSelectedPlant(newPlant)
							}}
							isOptionEqualToValue={(option: Plant, value: Plant) =>
								option._id === value._id
							}
							placeholder='Select Plant'
							optionLabel='plant_name'
							showLabel={false}
						/>
					)}
					{arrow && (
						<Button
							style={{ width: '65px' }}
							variant='outlined'
							onClick={() => {
								if (typeof customNavigation === 'function') customNavigation()

								navigate(navigationUrl)
							}}
							startIcon={<ArrowBackIcon style={{ margin: 0 }} />}
						/>
					)}
				</Grid>
				<Grid item xs>
					<Typography
						variant='h2'
						textAlign='start'
						sx={{ fontSize: '1.2rem' }}
						className={`${
							showLiveIndicator ? classes.typographyContainer : ''
						}`}
						mt={1.4}
					>
						{page}
						{showLiveIndicator && (
							<span className={liveIndicatorClasses.liveIndicator}>
								<span className={liveIndicatorClasses.liveDot}></span> Live
							</span>
						)}
					</Typography>
				</Grid>
				{goToTickets && (
					<Grid item xs textAlign='right'>
						<Button
							size='large'
							variant='outlined'
							onClick={() => {
								navigate(`/maintenance/tickets`, {
									replace: true
								})
							}}
						>
							Go To Tickets
						</Button>
					</Grid>
				)}
			</Grid>
		</Grid>
	)
}

export default NavBar
