export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const ADD_NOTIFICATION_SUCCESS = "ADD_NOTIFICATION_SUCCESS";
export const ADD_NOTIFICATION_ERROR = "ADD_NOTIFICATION_ERROR";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const CLEAR_NOTIFICATIONS_SUCCESS = "CLEAR_NOTIFICATIONS_SUCCESS";
export const CLEAR_NOTIFICATIONS_ERROR = "CLEAR_NOTIFICATIONS_ERROR";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const REMOVE_NOTIFICATION_SUCCESS = "REMOVE_NOTIFICATION_SUCCESS";
export const REMOVE_NOTIFICATION_ERROR = "REMOVE_NOTIFICATION_ERROR";
export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS";
export const READ_NOTIFICATION_ERROR = "READ_NOTIFICATION_ERROR";
