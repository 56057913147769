import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootSaga from "./root-sagas";
import rootReducer from "./root-reducers";

//Const Saga Middleware Initialization
const sagaMiddleware = createSagaMiddleware();
//const middleware = [logger, sagaMiddleware];

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;
