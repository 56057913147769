/* eslint-disable no-console,func-names,react/no-multi-comp */
import React from 'react';
import { cloneDeep, get, isEmpty, set } from 'lodash';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

const DynamicQuery = (props: any) => {
  const { items, onSeqChange } = props;

  const handleChange = (value: string, editIndex: number) => {
    let clonedUpdatedItems = cloneDeep(items);
    set(clonedUpdatedItems, 'assetTags', get(clonedUpdatedItems, 'assetTags', []).map((asset: any, index: number) =>{
      if (editIndex === index) {
        const nonValue = value === '0' ? '1' : '0';
        asset.value[value] = 'running';
        asset.value[nonValue] = 'notrunning';
      }
      return asset; 
    }))

    onSeqChange(clonedUpdatedItems);
  }

  if (isEmpty(items) || !items.assetTags) return null;

  const { assetTags } = items;

  return (
    <div className="selfConfigComponent">
      <div style={{ marginTop: '16px', width: '470px', maxHeight: '500px', overflowY: 'auto' }}>
        {assetTags.map((asset: any, index: number) => (
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">{asset.asset.toUpperCase()}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={get(asset, 'value.0') === 'running' ? 0 : 1}
              onChange={(event) => handleChange(event.target.value, index)}
            >
              <FormControlLabel value="0" control={<Radio />} label="Running with 0" />
              <FormControlLabel value="1" control={<Radio />} label="Running with 1" />
            </RadioGroup>
          </FormControl>
        ))}
      </div>
    </div>
  );
}


export default DynamicQuery;
