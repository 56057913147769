import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  Dispatch,
  FC,
  MutableRefObject,
  RefObject,
  SetStateAction,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios from "../../../../../Utils/AxiosConfig/axiosConfig";
import { defaultErrorAction } from "../../../../../Utils/util";
import { Annotation } from "../Helpers/interfaces";
import DeleteAnnotations from "./DeleteAnnotations";
import EditAnnotations from "./EditAnnotations";

interface HighchartsRef {
  chart: Highcharts.Chart;
}
interface ViewAnnotationsProps {
  open: boolean;
  onClose: () => void;
  annotations: Annotation[];
  setAnnotations: Dispatch<SetStateAction<Annotation[]>>;
  title: string;
  closeButton: string;
  chartRefs?: MutableRefObject<{ [key: string]: RefObject<HighchartsRef> }>;
  pageName: string;
  setFilteredAnnotations?: Dispatch<SetStateAction<Annotation[]>>;
  setClickedLink?: Dispatch<SetStateAction<number>>;
  loader: boolean;
}
// TODO: To get Annotation based on plant and the asset id , the API call integration is pending
const ViewAnnotations: FC<ViewAnnotationsProps> = ({
  open,
  onClose,
  annotations,
  setAnnotations,
  title = "",
  closeButton,
  chartRefs,
  pageName,
  setFilteredAnnotations,
  setClickedLink,
  loader,
}) => {
  const dispatch = useDispatch();
  const usersList = useSelector((state: any) => state.usersList);
  const [editAnnotationId, setEditAnnotationId] = useState<string | null>(null);
  const [editedAnnotationText, setEditedAnnotationText] = useState<string>("");
  const [deleteAnnotationId, setDeleteAnnotationId] = useState<string | null>(
    null
  );

  // Edit Annotation
  const handleEdit = (index: string) => {
    setEditAnnotationId(index);
    setEditedAnnotationText(
      annotations.find((data) => data._id === index)?.message ?? ""
    );
  };

  const handleEditClose = () => {
    setEditAnnotationId(null);
    setEditedAnnotationText("");
  };

  const editAnnotation = (
    editAnnotationId: string,
    newText: string,
    timestamp: string
  ) => {
    setAnnotations((annotations) =>
      annotations.map((annotation) => {
        if (annotation._id === editAnnotationId) {
          return { ...annotation, message: newText, timestamp: timestamp };
        }
        return annotation;
      })
    );
  };

  const handleSaveEdit = async (newText: string) => {
    if (editAnnotationId !== null) {
      const timestamp = moment().format("DD MMM YYYY hh:mm:ss a"); // Get the current timestamp
      try {
        const response = await Axios.put(`/annotations/${editAnnotationId}`, {
          message: newText,
          timestamp: timestamp,
          userId: usersList.currentUser._id,
        });
        if (response.status === 200) {
          dispatch(defaultErrorAction("Annotation Updated!", "success"));
          editAnnotation(editAnnotationId, newText, timestamp);
          if (setFilteredAnnotations) {
            setFilteredAnnotations((prevAnnotations) =>
              prevAnnotations.map((annotation) =>
                annotation._id === editAnnotationId
                  ? {
                      ...annotation,
                      message: newText,
                      timestamp,
                      userId: usersList.currentUser._id,
                    }
                  : annotation
              )
            );
          }
        }
      } catch (error) {
        dispatch(defaultErrorAction("Error Updating Annotation"));
      }
      setEditAnnotationId(null);
      setEditedAnnotationText("");
    }
  };

  // Delete Annotation
  const handleDelete = (index: string) => {
    setDeleteAnnotationId(index);
  };

  const deleteAnnotationFromDB = async (id: string) => {
    try {
      const response = await Axios.delete(`/annotations/${id}`);
      if (response.status === 200) {
        dispatch(defaultErrorAction("Annotation deleted.", "success"));
        deleteAnnotation(id);
        // update filterAnnotations and
        if (setFilteredAnnotations) {
          setFilteredAnnotations((prevAnnotations) =>
            prevAnnotations.filter((annotation) => annotation._id !== id)
          );
        }
      }
    } catch (error) {
      dispatch(defaultErrorAction("Error deleting annotation."));
    }
  };

  const deleteAnnotation = (deleteAnnotationId: string) => {
    setAnnotations((annotations) =>
      annotations.filter((annotation) => annotation._id !== deleteAnnotationId)
    );
  };

  const handleDeleteConfirm = async () => {
    if (deleteAnnotationId !== null) {
      await deleteAnnotationFromDB(deleteAnnotationId);
      setDeleteAnnotationId(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteAnnotationId(null);
  };

  const handleVisualizationLink = (annotation: Annotation) => {
    if (
      pageName === "Inverter Detail Page" ||
      pageName === "Battery Detail Page"
    ) {
      const element = document.getElementById(annotation.type);
      const chartRef = chartRefs?.current[annotation.type]?.current?.chart;
      if (element && chartRef) {
        // Close the Popup
        onClose();
        // Move in to the current view
        element.scrollIntoView({ behavior: "smooth" });
        // get the access for the clicked annotation point
        let seriesPoint: any = null;
        chartRef?.series?.map((item: any) => {
          if (item?.name === annotation?.scatter_plot_type) {
            seriesPoint = item;
          }
        });
        const point = seriesPoint?.data?.find(
          (p: any) => p?.x === new Date(annotation?.x).valueOf()
        );
        if (point) {
          // Refresh tooltip to show the point's details
          chartRef.tooltip.refresh(point);

          // Optionally, set a small delay to ensure tooltip is displayed properly
          setTimeout(() => {
            // Trigger a tooltip event to ensure visibility
            chartRef.tooltip.refresh(point);
          }, 100);
        }
      }
    } else if (pageName === "LGV Nav Detail Page") {
      const element = document.getElementById(annotation.type);
      if (element) {
        onClose();
        element.scrollIntoView({ behavior: "smooth" });
        // show star
        if (setClickedLink) {
          setClickedLink(new Date(annotation.x).valueOf());
        }
      }
      onClose();
    }
  };

  const AnnotationsTable = (
    <div style={{ maxHeight: "600px", overflowY: "auto" }}>
      {loader ? (
        <CircularProgress style={{ margin: "15%", marginLeft: "50%" }} />
      ) : (
        <TableContainer sx={{ width: "100%" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: "center", textTransform: "none" }}>
                  Annotation Text
                </TableCell>
                <TableCell sx={{ textAlign: "center", textTransform: "none" }}>
                  User
                </TableCell>
                <TableCell sx={{ textAlign: "center", textTransform: "none" }}>
                  Timestamp
                </TableCell>
                <TableCell sx={{ textAlign: "center", textTransform: "none" }}>
                  Visualization Link
                </TableCell>
                <TableCell sx={{ textAlign: "center", textTransform: "none" }}>
                  Update
                </TableCell>
                <TableCell sx={{ textAlign: "center", textTransform: "none" }}>
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {annotations?.length === 0 ? (
                <Typography p={1} mt={2} variant="h6" fontSize={14}>
                  No Annotation Found
                </Typography>
              ) : (
                annotations?.map((annotation) => {
                  return (
                    <TableRow
                      key={annotation?._id}
                      sx={{
                        "&:nth-of-type(even)": {
                          backgroundColor: "#232A58",
                        },
                        "&:nth-of-type(odd)": {
                          backgroundColor: "#293368",
                        },
                      }}
                    >
                      <TableCell>{annotation.message}</TableCell>
                      <TableCell>
                        {usersList.users.find((user: any) => {
                          return user._id === annotation.userId;
                        })?.display_name || "-"}
                      </TableCell>
                      <TableCell>{annotation.timestamp}</TableCell>
                      <TableCell>
                        <Button
                          variant="text"
                          startIcon={
                            <OpenInNewIcon style={{ marginRight: "5px" }} />
                          }
                          disableFocusRipple
                          disableRipple
                          onClick={() => handleVisualizationLink(annotation)}
                          sx={{
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          Link
                        </Button>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <EditIcon onClick={() => handleEdit(annotation._id)} />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <DeleteIcon
                          onClick={() => handleDelete(annotation._id)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth={"md"}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "16px",
          }}
        >
          {title}
          <Button
            onClick={onClose}
            sx={{
              fontSize: "16px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {closeButton}
          </Button>
        </DialogTitle>
        <DialogContent>{AnnotationsTable}</DialogContent>
      </Dialog>
      <EditAnnotations
        open={editAnnotationId !== null}
        onClose={handleEditClose}
        handleSaveEdit={handleSaveEdit}
        handleEditClose={handleEditClose}
        editedAnnotationText={editedAnnotationText}
        title="Update Comment"
        button1="Cancel"
        button2="Update"
        setEditedAnnotationText={setEditedAnnotationText}
      />
      {deleteAnnotationId !== null && (
        <DeleteAnnotations
          open={true}
          onClose={handleDeleteCancel}
          handleDeleteCancel={handleDeleteCancel}
          handleDeleteConfirm={handleDeleteConfirm}
          title="Delete Comment?"
          description="The comment will be deleted from the ARCH System"
          button1="Cancel"
          button2="Delete"
        />
      )}
    </>
  );
};

export default ViewAnnotations;
