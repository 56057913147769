import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography, Grid, Button } from "@mui/material";
import { Theme } from "@material-ui/core";
import { Box, ListItem } from "@material-ui/core";
import { ReactComponent as DangerIcon } from "./icons/AlertCancelIcon.svg";
import { ReactComponent as TickIcon } from "./icons/AlertTickIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { darkLightBgChange } from "../../../../theme/muiGlobalCss";
import findAsset from "../../../../Utils/UtilFunctions/findAsset";
import CustomScrollbars from "../../../../componets/CustomScrollbars/CustomScrollbars";
import {
  getFormatedDate,
  getTimeZoneTimeStamp,
} from "../../../../Utils/UtilFunctions/timeZoneFormatter";
import { CustomProvider, DateRangePicker } from "rsuite";
import { ExcelService } from "../../../../Utils/ExportToExcelTicketDetails";
import {
  GREEN,
  GREENBG1,
  GREENBG2,
  RED,
  REDBG1,
  REDBG2,
} from "../../../../Utils/UtilFunctions/constant";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    "& .MuiTypography-root": {
      fontWeight: 400,
      fontSize: "14px",
      color: "#F7F7F7",
    },
  },
  circleIcon: {
    position: "relative",
    top: "3px",
    marginRight: "10px",
  },
  textfieldContainer: {
    height: "100%",
    overflow: "auto",
    borderRadius: "12px",
    border: "1px solid #E5E7F1",
  },
  boxPointer: {
    "&.MuiBox-root": {
      cursor: "pointer",
    },
  },
  historySectionTitle: {
    "&.MuiTypography-root": {
      fontWeight: 500,
      fontSize: "13px",
      lineHeight: "20px",
    },
  },
  container: {
    // marginTop: "25px",
    // background: "white",
  },
  backgroundItem: {
    height: "113vh",
    overflow: "auto",
  },
  danger: {
    background: REDBG1,
    borderBottom: `2px solid ${RED}`,
    borderRadius: "8px",

    "&.MuiListItem-root.Mui-selected": {
      background: REDBG2,
    },
  },
  acceptable: {
    background: GREENBG1,
    borderBottom: `2px solid ${GREEN}`,
    borderRadius: "8px",

    "&.MuiListItem-root.Mui-selected": {
      background: GREENBG2,
    },
  },
  calendarOutline: {
    background: darkLightBgChange(theme),
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    color: theme.palette.mode === "dark" ? "#bebebe" : "#000",
    padding: "20px",
    height: "10px",
    width: "100%",
    outline: "none",
  },
  calendarInner: {
    "&.react-datepicker": {
      background: darkLightBgChange(theme),
      border: "1px solid rgba(0, 0, 0, 0.1)",
      borderRadius: "5px",
      color: theme.palette.mode === "dark" ? "#bebebe" : "#000",
      cursor: "pointer",
    },
  },
  progressSpinner: {
    marginTop: window.innerHeight * 0.25,
    marginBottom: window.innerHeight * 0.2,
    marginLeft: window.innerWidth * 0.05,
  },
}));

function UsecaseAlertHistory(props: any) {
  const appMode = localStorage.getItem("appMode");
  const classes = useStyles();
  const dispatch = useDispatch();
  const ticketsList = useSelector((state: any) => state.ticketsList);
  const plantsList = useSelector((state: any) => state.plantsList);
  const levelsList = useSelector((state: any) => state.levelsList);
  const [allMessages, setAllMessages] = useState<any>([]);
  const messagesList = useSelector((state: any) => state.messagesList);
  const [data, setData] = useState<any>([]);
  const [convertedData, setConvertedData] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [defaultDate, setDefaultDate] = useState<{
    start: any;
    end: any;
  }>({
    start: null,
    end: null,
  });
  const [parentAsset, setParentAsset] = useState<any>({});

  const findParentAssetName = (assetId: number) => {
    const assetData = findAsset(levelsList, assetId);
    const parentAsset = findAsset(levelsList, assetData?.parent_asset);
    const grandParentAsset = findAsset(levelsList, parentAsset?.parent_asset);
    setParentAsset(grandParentAsset);
  };

  const flattenMessageDataUtils = (messages: any) => {
    const flatData = messages?.flatMap((val: any) =>
      val.message_data.UtilitiesAlarm.flatMap((x: any) => ({
        ...val,
        ...x,
      }))
    );

    // Loop thorough the array and remove two paramerts with same date and time
    const uniqueData = [
      ...new Map(
        flatData.map((item: any) => [
          `${item["TIMESTAMP"]}-${item["PARAMETERNAME"]}`,
          item,
        ])
      ).values(),
    ];

    const sortedParameters = uniqueData.sort(function (a: any, b: any) {
      return a.TIMESTAMP > b.TIMESTAMP ? -1 : a.TIMESTAMP < b.TIMESTAMP ? 1 : 0;
    });

    return sortedParameters;
  };

  const getModdedArray = (alert: any) => {
    return {
      "Ticket ID": ticketsList.allTicket.find((ticket: any) => {
        return ticket._id === alert.ticket_id;
      })?.ticketId_alias,
      "Alert Type": alert.alert_type,
      Tag: alert.tag,
      "Asset Name":
        levelsList.levels.find((data: any) => {
          return data._id === alert.asset_id;
        })?.asset_alias ||
        levelsList.levels.find((data: any) => {
          return data._id === alert.asset_id;
        })?.asset_name,
      "Device Name": alert.device_name,
      "Area/Line": parentAsset?.asset_alias || parentAsset?.asset_name || "NA",
      "Upper Limit": alert.UPPERLIMIT,
      "Current Value": Number(alert.TAGVALUE).toFixed(2),
      "Lower Limit": alert.LOWERLIMIT,
      "Parameter Name": alert.PARAMETERNAME,
      "Parameter Counter": alert.PARAMETERCOUNTER,
    };
  };

  const exportCSV = () => {
    if (data.length > 0) {
      const excelService = new ExcelService();
      const moddedArray = [
        data.map((alert: any) => {
          return getModdedArray(alert);
        }),
      ];
      excelService.exportAsExcelFile(
        moddedArray,
        `NB ARCH - Ticket Alert History Utilities`,
        ["Alerts"]
      );
    } else {
      dispatch({
        type: "LOAD_SNACKBAR",
        payload: {
          message: "No Data to Export",
          type: "info",
          open: true,
        },
      });
    }
  };

  // useEffect(() => {
  //   if (props.clickedAsset?._id) {
  //     findParentAssetName(props.clickedAsset?._id);
  //   }
  // }, [props.clickedAsset?._id]);

  React.useEffect(() => {
    const messageList = messagesList.messages
      .filter((message: any) => {
        return message.alert_type === "Utilities CBM/PdM";
      })
      .sort(function (a: any, b: any) {
        return a.timestamp > b.timestamp
          ? -1
          : a.timestamp < b.timestamp
          ? 1
          : 0;
      });

    const sortedParameters = flattenMessageDataUtils(messageList);
    setAllMessages(sortedParameters);
  }, [messagesList.messages]);
  const useDidMountEffect = (func: any, deps: any) => {
    const didMount = useRef(false);

    useEffect(() => {
      if (didMount.current) func();
      else didMount.current = true;
    }, deps);
  };
  useDidMountEffect(() => {
    const allParameters = allMessages
      .filter((ele: any) => {
        return ele.plant_id === props.selectedPlant._id;
      })
      .map((data: any) => {
        return {
          ...data,
          timestamp: getTimeZoneTimeStamp(
            plantsList.plants.find((plant: any) => {
              return plant._id === data.plant_id;
            }).plant_timezone,
            data.TIMESTAMP
          ),
          format: getFormatedDate(
            plantsList.plants.find((plant: any) => {
              return plant._id === data.plant_id;
            }).plant_timezone,
            getTimeZoneTimeStamp(
              plantsList.plants.find((plant: any) => {
                return plant._id === data.plant_id;
              }).plant_timezone,
              data.TIMESTAMP
            )
          ),
        };
      });
    // console.log("alerts", props.allMessages, allParameters);
    setConvertedData(allParameters);

    // Comment the getting timestamp from overall Alert message history.
    // const endDate = allParameters[0]?.timestamp;
    // const startDate = allParameters[allParameters.length - 1]?.timestamp;

    // Limiting the Alert history for last one week by default.
    const currentDate = moment.tz(props.selectedPlant?.plant_timezone).startOf("day");
    const endDate = moment.tz(props.selectedPlant?.plant_timezone).valueOf();
    const startDate = currentDate.subtract(7, 'days').startOf("day").valueOf();

    setStartDate(new Date(startDate));
    setEndDate(new Date(endDate));

    setDefaultDate({
      start: startDate,
      end: endDate,
    });
  }, [allMessages, plantsList]);

  useDidMountEffect(() => {
    // const data = convertedData.filter((message: any) => {
    //   return message.timestamp >= startDate && message.timestamp <= endDate;
    // });
    var filterData = convertedData.filter(
      (obj: any) => obj.timestamp >= startDate && obj.timestamp <= endDate
    );
    setData(filterData);
  }, [convertedData]);

  return (
    <Grid
      container
      justifyContent="start"
      alignItems="center"
      style={{ marginBottom: "5px" }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={8}>
          <Typography
            style={{ display: "inline", fontSize: "14px" }}
            gutterBottom
          >
            Alert History
          </Typography>
          <Typography
            style={{
              display: "inline",
              border: "1px solid #7879F1",
              borderRadius: "15px",
              padding: "4px 10px",
              color: "#fff",
              backgroundColor: appMode === "Dark" ? "#7879F1" : "#1245A8",
              height: "28px",
              margin: "0px 10px",
            }}
            gutterBottom
          >
            {data?.length}
          </Typography>
        </Grid>
        {/* <Grid item xs={2}>
       
        </Grid> */}
        <Grid item xs={4} display="flex" justifyContent="end">
          <Button
            variant="contained"
            sx={{ width: "70px", marginLeft: "20px" }}
            onClick={() => {
              exportCSV();
            }}
          >
            Export
          </Button>
        </Grid>
        <Grid item xs={12} mt={2}>
          <CustomProvider theme="dark">
            <DateRangePicker
              style={{ width: "100%" }}
              ranges={[]}
              showOneCalendar
              defaultValue={[defaultDate.start, defaultDate.end]}
              value={[startDate, endDate]}
              placeholder="Set Date Range"
              onChange={(value: any) => {
                if (value === null) {
                  setStartDate(defaultDate.start);
                  setEndDate(defaultDate.end);
                } else {
                  setStartDate(moment(value[0]).startOf("day").valueOf());
                  setEndDate(moment(value[1]).endOf("day").valueOf());

                  var filterdata = convertedData.filter(
                    (obj: any) =>
                      obj.timestamp >= (moment(value[0]).tz(props.selectedPlant?.plant_timezone) ) && ( obj.timestamp <= moment(value[1]).tz(props.selectedPlant?.plant_timezone))
                  );
                  
                  setData(filterdata);
                }
              }}
            />
          </CustomProvider>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          height: "88%",
          backgroundColor: "#293368",
          padding: "5px",
          marginTop: "10px",
        }}
      >
        <CustomScrollbars style={{ height: "78vh" }}>
          {data.length === 0 && messagesList.loading === false ? (
            <Typography variant="h4" align="center" m={1}>
              No tickets Found
            </Typography>
          ) : messagesList.loading ? (
            <CircularProgress className={classes.progressSpinner} />
          ) : (
            data.map((message: any, i: any) => {
              const asset = findAsset(levelsList, message?.asset_id);
              const parentAsset = findAsset(levelsList, asset?.parent_asset);
              const grandParentAsset = findAsset(
                levelsList,
                parentAsset?.parent_asset
              );
              return (
                <ListItem
                  style={{
                    margin: "10px 0px",
                    minHeight: "120px",
                    // maxHeight: "130px",
                    boxSizing: "border-box",
                  }}
                  key={i}
                  sx={{
                    p: 2,
                    display: { xs: "block", sm: "flex" },
                  }}
                  // onClick={() => {
                  //   props.setCurrentMessage(message);
                  //   props.setCurrentAlarm(message);
                  // }}
                  // selected={
                  //   `${
                  //     props.currentMessage.PARAMETERNAME +
                  //     props.currentMessage.TAGVALUE
                  //   }` === `${message.PARAMETERNAME + message.TAGVALUE}`
                  // }
                  className={
                    Number(message.TAGVALUE).toFixed(2) >= message.LOWERLIMIT &&
                    Number(message.TAGVALUE).toFixed(2) <= message.UPPERLIMIT
                      ? classes.acceptable
                      : classes.danger
                  }
                >
                  <Box className={classes.boxPointer} flex="1">
                    <Box
                      display="flex"
                      justifyContent="space-around"
                      alignItems="center"
                      // flexDirection="column"
                    >
                      <Box mr={1}>
                        {Number(message.TAGVALUE).toFixed(2) >=
                          message.LOWERLIMIT &&
                        Number(message.TAGVALUE).toFixed(2) <=
                          message.UPPERLIMIT ? (
                          <TickIcon />
                        ) : (
                          <DangerIcon />
                        )}
                      </Box>
                      <Box sx={{ maxWidth: "180px" }}>
                        <Typography
                          className={classes.historySectionTitle}
                          style={{ fontWeight: 800, fontSize: "1.25em" }}
                        >
                          {
                            ticketsList.allTicket.find((ticket: any) => {
                              return message.ticket_id === ticket._id;
                            })?.ticketId_alias
                          }
                        </Typography>
                        <Typography className={classes.historySectionTitle}>
                          Ticket status:{" "}
                          {
                            ticketsList.allTicket.find((ticket: any) => {
                              return message.ticket_id === ticket._id;
                            })?.status[0].device_status
                          }
                        </Typography>
                        <Typography className={classes.historySectionTitle}>
                          {message.PARAMETERNAME}
                        </Typography>
                        <Typography>
                          {`${asset?.asset_alias || asset?.asset_name}  (${
                            message.device_name
                          })`}
                        </Typography>

                        <Typography>
                          {grandParentAsset?.asset_alias ||
                            grandParentAsset?.asset_name ||
                            "NA"}
                        </Typography>
                        <Typography>{message.format}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
              );
            })
          )}
        </CustomScrollbars>
      </Grid>
    </Grid>
  );
}

export default UsecaseAlertHistory;
