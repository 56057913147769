import { Range } from './interfaces'

export function getFormattedRange(selectedRange: Range, timezone = '') {
	const dateDisplayFormat = 'dddd, MMMM Do YYYY, h:mm:ss a'

	const start = selectedRange.start.tz(timezone).format(dateDisplayFormat)

	const end = selectedRange.end.tz(timezone).format(dateDisplayFormat)

	return `${start} - ${end}`
}
