import {
  LOAD_KPIS,
  LOAD_KPIS_SUCCESS,
  LOAD_KPIS_ERROR,
} from "../Types/kpis.constants";

const INITIAL_STATE = {
  loading: false,
  kpis: [],
  error: null,
};

export const kpisListReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_KPIS:
      return { ...state, loading: true, kpis: [] };
    case LOAD_KPIS_SUCCESS:
      return {
        ...state,
        loading: false,
        kpis: action.payload,
      };
    case LOAD_KPIS_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
