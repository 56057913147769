import {
    LOAD_SATPLANTS,
    LOAD_SATPLANTS_SUCCESS,
    LOAD_SATPLANTS_ERROR,
  } from "../Types/satPlants.constants";


  
  const INITIAL_STATE = {
    loading: false,
    satPlants: [],
    error: null,
  };
  
  export const satPlantsListReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
      case LOAD_SATPLANTS:
        return { ...state, loading: true, satPlants: [] };
      case LOAD_SATPLANTS_SUCCESS:
        return {
          ...state,
          loading: false,
          satPlants: action.payload,
        };
      case LOAD_SATPLANTS_ERROR:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  
 