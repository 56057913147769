import {
  useState,
  useEffect,
  FC,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import AnnotationsModule from "highcharts/modules/annotations";
import NoData from "highcharts/modules/no-data-to-display";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import Axios from "../../../../../Utils/AxiosConfig/axiosConfig";
import {
  getFormatedDate,
  getTimeZoneTimeStamp,
} from "../../../../../Utils/UtilFunctions/timeZoneFormatter";
import { LgvNavQualityOverTimeSeriesData } from "../Helpers/sampleData";
import UnAnnotatePoint from "../../../../../Icons/lgv-nav-quality/unAnnotate-point.svg";
import AnnotatePoint from "../../../../../Icons/lgv-nav-quality/annotate-point.svg";
import ClickedAnnotate from "../../../../../Icons/lgv-nav-quality/star-clicked.svg";
import { Annotation, SingleLgv } from "../Helpers/interfaces";
import OutOfFrame from "../Component/OutOfFrame";
import { LGVHealthContext, LGVHealthContextValue } from "../Component/Context";

const visualizationType = "Nav Quality Over Time";

const defaultAnnotation: any = {
  labelOptions: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    verticalAlign: "top",
    y: 20,
  },
  labels: [
    {
      point: {
        x: moment("2024-03-01T05:30:00.000Z").valueOf(),
        y: 44,
        xAxis: 0,
        yAxis: 0,
      },
      text: "Annotation 1",
      style: {
        display: "none",
      },
    },
  ],
};

NoData(Highcharts);
AnnotationsModule(Highcharts);

interface lgvNavQualTimeSeriesDataType {
  x: number;
  y: number | undefined;
}

// interface Annotation {
//   _id: string;
//   message: string;
//   reportName: string;
//   x: string;
//   y: string;
//   userId: string;
//   tagType: string;
//   timestamp: string;
//   type: string;
//   plantId?: string;
//   title?: string;
//   scatter_plot_type?: string;
//   use_case_name?: string;
// }

interface LgvNavQualityOverTimeProps {
  chartTitle?: string;
  legendItems: any[];
  secondaryLegend: boolean;
  plant: any;
  assetId: string;
  reportName: string;
  clickedLink: number;
  setFilteredAnnotations: Dispatch<SetStateAction<Annotation[]>>;
  filteredAnnotations: Annotation[];
}

const LgvNavQualityOverTime: FC<LgvNavQualityOverTimeProps> = ({
  chartTitle,
  legendItems = [],
  secondaryLegend,
  plant,
  reportName,
  assetId,
  clickedLink,
  filteredAnnotations,
  setFilteredAnnotations,
}) => {
  const dispatch = useDispatch();
  const { selectedPlant } = useContext<LGVHealthContextValue>(LGVHealthContext);
  const usersList = useSelector((state: any) => state.usersList);
  const lgvStoreData = useSelector((state: any) => state.lgvHealth);
  const plantsList = useSelector((state: any) => state.plantsList.plants);

  const [chartAnnotations, setchartAnnotations] = useState<any[]>([]);
  const [clickedTime, setClickedTime] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [annotationText, setAnnotationText] = useState<string>("");
  const [options, setOptions] = useState<Highcharts.Options>({});
  const [annotationObj, setAnnotationObj] = useState<any>({
    kpiName: "",
    xAxis: "",
    value: "",
    timestamp: "",
    plantName: "",
    selectedEquipment: "",
    annotation: "",
    addedBy: "",
  });

  const [stateClicked, setStateClicked] = useState<
    lgvNavQualTimeSeriesDataType[]
  >([]);

  const [lgvNavQulTimeSeriesData, setLgvNavQulTimeSeriesData] = useState<
    lgvNavQualTimeSeriesDataType[]
  >([]);

  const formattedAssetId: string = assetId.substring(
    assetId.indexOf("LGV ") + 4
  );

  const [allLgvData, setAllLgvData] = useState<{ [key: number]: SingleLgv }[]>(
    lgvStoreData.historicalData
  );
  const [outOfTimeFrameOpen, setOutOfTimeFrameOpen] = useState<boolean>(false);

  const timeZone =
    plantsList?.find((plant: any) => {
      return plant.plant_code === selectedPlant?.plant_code;
    })?.plant_timezone || "America/Chicago";

  const dateFormatter = (item: any) => {
    if (moment(item).isValid()) {
      return getFormatedDate(
        timeZone,
        getTimeZoneTimeStamp(
          timeZone,
          moment.utc(item).valueOf(),
          "YYYY-MM-DD hh:mma"
        ),
        "MM/DD hh:mma"
      );
    } else {
      return item;
    }
  };

  useEffect(() => {
    setOptions({
      chart: {
        type: "line",
        zoomType: "x",
        panKey: "shift",
        backgroundColor: "#293368",
        plotBackgroundColor: "#293368",
        scrollablePlotArea: {
          minWidth: 600,
        },
        style: {
          fontFamily: "Roboto",
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
        align: "left",
        style: {
          color: "#ffffff",
          fontSize: "18px",
          fontStyle: "Roboto",
          textTransform: "capitalize",
        },
      },
      xAxis: {
        type: "datetime",
        labels: {
          formatter: (object: any) => {
            return dateFormatter(object.value);
          },
          format: "{value:%m/%d %I:%M%p}",
          style: {
            color: "white",
          },
        },
        accessibility: {
          rangeDescription: "Range: March 1st, 2024 8:00am to 10:00am",
        },
      },
      yAxis: {
        tickLength: 0,
        title: {
          useHTML: false,
          text: "Nav Quality",
          style: {
            fontSize: "14px",
            color: "#ffffff",
          },
        },
        plotBands: legendItems?.map(({ range, color, legendName }: any) => ({
          from: range[0],
          to: range[1] || Infinity,
          color: `${color}`,
          label: {
            text: secondaryLegend ? legendName : "",
            style: {
              color: "white",
            },
          },
        })),
      },
      tooltip: {
        useHTML: true,
        backgroundColor: "#000000",
        style: {
          color: "#fff",
        },
        headerFormat: "Time: {point.x:%Y-%m-%d %H:%M}<br>",
        pointFormat: "Nav Quality: {point.y} m a. s. l.",
        shared: true,
        formatter: function () {
          const temp = this as any;
          const point = temp.points[0];
          const annotation = point.series.chart.annotations.find(
            (annotation: any) =>
              annotation.options.labels[0].point.x === point.x
          );
          let annotationText = "";
          if (annotation) {
            annotationText = annotation.options.labels[0].text;
          }
          return `
          ${getFormatedDate(
            timeZone,
            getTimeZoneTimeStamp(
              timeZone,
              moment.utc(temp.x).valueOf(),
              "YYYY-MM-DD hh:mmA"
            ),
            "M/DD/YY hh:mmA z"
          )}
          <br>${point.series.name}: <b>${point.y}</b>
          <br>${annotationText ? `Annotation: ${annotationText}` : ""}
          `;
        },
      },
      annotations: chartAnnotations,
      series: [
        {
          type: "line",
          data: lgvNavQulTimeSeriesData,
          color: "white", // Set line color to white
          showInLegend: false, // Show in legend
          point: {
            events: {
              click: function (e: any) {
                const rawObj: any = {
                  xAxis: e.point.series.name,
                  x: e.point.category,
                  value: e.point.y,
                  timestamp: setClickedTime(
                    getFormatedDate(
                      timeZone,
                      getTimeZoneTimeStamp(
                        timeZone,
                        moment.utc(e.point.category).valueOf(),
                        "YYYY-MM-DD hh:mmA"
                      ),
                      "YYYY-MM-DD hh:mmA z"
                    )
                  ),
                  annotation: "",
                  addedBy: "",
                };
                setAnnotationObj(rawObj);
                setOpen(true); //opening dialog for annotations
              },
            },
          },
        },
      ],
    });
  }, [lgvNavQulTimeSeriesData, chartAnnotations]);

  useEffect(() => {
    const optionsDpCpy = JSON.parse(JSON.stringify(options));
    const filteredTimeSeriesData: any = [];
    optionsDpCpy.annotations = [...chartAnnotations];
    setOptions(optionsDpCpy);

    const assetIdSplit = assetId.split(" ");
    const assetIdNum = parseInt(assetIdSplit[1], 10);

    allLgvData.forEach((lgvItem) => {
      if (lgvItem[assetIdNum]) {
        const lgvAsset = lgvItem[assetIdNum];
        if (lgvAsset.navQuality !== undefined) {
          const x = lgvAsset.timeStamp;
          const y = lgvAsset.navQuality;

          // Check if there's any annotation with the same x value
          const annotationExists = chartAnnotations.some((annotationMark) => {
            const annotationPointAxisObj = annotationMark?.labels[0]?.point;
            return (
              annotationPointAxisObj?.x === x &&
              annotationMark?.labels[0]?.text?.trim() !== ""
            );
          });

          filteredTimeSeriesData.push({
            x,
            y,
            marker: {
              symbol: `url(${
                annotationExists ? AnnotatePoint : UnAnnotatePoint
              })`,
            },
          });
        }
      }
    });

    setLgvNavQulTimeSeriesData(filteredTimeSeriesData);
    setStateClicked(filteredTimeSeriesData);
  }, [chartAnnotations, allLgvData]);

  useEffect(() => {
    setAllLgvData(lgvStoreData.historicalData);
  }, [lgvStoreData.historicalData]);

  useEffect(() => {
    if (clickedLink) {
      updateSymbolOnClick(clickedLink);
    }
  }, [clickedLink]);

  const updateSymbolOnClick = (clickedLink: number) => {
    const updatedData = stateClicked.map((dataPoint) => {
      const dataPointValue = new Date(dataPoint.x).valueOf();
      if (dataPointValue === clickedLink) {
        return {
          ...dataPoint,
          marker: {
            symbol: `url(${ClickedAnnotate})`,
          },
        };
      } else {
        setOutOfTimeFrameOpen(true); // this
      }
      return dataPoint;
    });

    setLgvNavQulTimeSeriesData(updatedData);
  };

  useEffect(() => {
    DarkUnica(Highcharts);

    if (!plant || chartAnnotations.length) {
      return;
    }
    const seriesDataObj: { [key: number]: number | undefined } = {};

    LgvNavQualityOverTimeSeriesData.forEach(({ x, y }) => {
      seriesDataObj[x] = y;
    });

    const generateLabel = (data: any) => {
      const xVal = moment(data.x).valueOf();
      return {
        point: {
          x: xVal,
          y: seriesDataObj[xVal],
          xAxis: 0,
          yAxis: 0,
        },
        text: data.message,
        style: {
          display: "none",
        },
      };
    };

    const handleResponse = (data: any) => {
      const allLabels = [];
      const annotationsObj = { ...defaultAnnotation };
      for (const annotation of data.data) {
        const generatedLabel = generateLabel(annotation);
        annotationsObj.labels = [{ ...generatedLabel }];
        annotationsObj.annotationObjId = annotation._id;
        allLabels.push({ ...annotationsObj });
      }
      annotationsObj.finalData = [...allLabels];
      setchartAnnotations(annotationsObj.finalData);
    };

    Axios.get(
      `/annotations/allByLgv?plantId=${plant._id}&lgvNum=${formattedAssetId}&type=${visualizationType}`
    )
      .then((response: any) => {
        handleResponse(response.data);
      })
      .catch(() => {
        // Handle the error
        dispatch({
          type: "LOAD_SNACKBAR",
          payload: {
            message: "Error getting LGV annotation data",
            type: "error",
            open: true,
          },
        });
      });
  }, []);

  const updateAnnotationInDB = async (data: any): Promise<boolean> => {
    return Axios.put("/annotations/" + data.id, { message: data.message })
      .then((response: any) => {
        if (
          response.data &&
          typeof response.data === "object" &&
          response.data._id
        ) {
          return true;
        } else {
          throw new Error("Invalid response data");
        }
      })
      .catch(() => {
        dispatch({
          type: "LOAD_SNACKBAR",
          payload: {
            message: "Failed to update annotation",
            type: "error",
            open: true,
          },
        });
        return false;
      });
  };

  //function to make api call to create new annotation
  const handleAddAnnotation = async () => {
    const createAnnotation = async () => {
      const currTime = moment(annotationObj.x).format("DD MMM YYYY hh:mm:ss a");
      const currentTime = moment().format("DD MMM YYYY hh:mm:ss a");
      const newDBAnnotation = {
        message: annotationText,
        reportName: reportName,
        tagType: "LGV",
        title: chartTitle,
        x: currTime,
        y: annotationObj.value,
        timestamp: currentTime,
        plantId: plant._id,
        lgvNumber: formattedAssetId,
        userId: usersList.currentUser._id,
        type: visualizationType,
      };

      try {
        const response = await Axios.post("/annotations", newDBAnnotation);
        if (response.data && response.data._id) {
          return response.data;
        }
        throw new Error("Failed to create annotation");
      } catch (error) {
        dispatch({
          type: "LOAD_SNACKBAR",
          payload: {
            message: "Failed to add annotation",
            type: "error",
            open: true,
          },
        });
        return null;
      }
    };
    const updateAnnotation = async (id: string, message: string) => {
      const updateDBAnnotation = {
        id,
        message,
      };

      return await updateAnnotationInDB(updateDBAnnotation);
    };

    const existingAnnotation = chartAnnotations.find(
      (annotation) => annotation.labels[0].point.x === annotationObj.x
    );

    if (existingAnnotation) {
      const updateSuccess = await updateAnnotation(
        existingAnnotation.annotationObjId,
        annotationText
      );
      if (updateSuccess) {
        existingAnnotation.labels[0].text = annotationText;
        setchartAnnotations([...chartAnnotations]); // Update chartAnnotations state
        setOptions({
          ...options,
          annotations: [...chartAnnotations], // Update options with new annotation
        });
        if (setFilteredAnnotations) {
          setFilteredAnnotations((prevAnnotations) =>
            prevAnnotations.map((annotation) =>
              annotation._id === existingAnnotation.annotationObjId
                ? {
                    ...annotation,
                    message: annotationText,
                    timestamp: moment().format("DD MMM YYYY hh:mm:ss a"),
                    userId: usersList.currentUser._id,
                  }
                : annotation
            )
          );
        }
      }
    } else {
      // Add new annotation
      const newAnnotation = {
        labelOptions: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          verticalAlign: "top",
          y: 20,
        },
        labels: [
          {
            point: {
              x: annotationObj.x,
              y: annotationObj.value,
              xAxis: 0,
              yAxis: 0,
            },
            text: annotationText,
            style: {
              display: "none",
            },
          },
        ],
      };
      const createSuccess = await createAnnotation();
      if (createSuccess) {
        const currTime = moment(annotationObj.x).format(
          "DD MMM YYYY hh:mm:ss a"
        );

        const newFilteredAnnotations: Annotation[] = [
          ...filteredAnnotations,
          {
            _id: createSuccess._id,
            reportName: reportName,
            tagType: "LGV",
            type: visualizationType,
            message: annotationText,
            timestamp: moment().format("DD MMM YYYY hh:mm:ss a"),
            userId: usersList.currentUser._id,
            x: currTime,
            y: annotationObj.value,
            plantId: plant._id,
          },
        ];
        setchartAnnotations([...chartAnnotations, newAnnotation]); // Update chartAnnotations state
        setFilteredAnnotations(newFilteredAnnotations); // Update the filteredAnnotations state in parent
        setOptions({
          ...options,
          annotations: [...chartAnnotations, newAnnotation], // Update options with new annotation
        });
      }
    }

    setOpen(false); // Close the dialog
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ margin: "20px 0px" }}>
      <div
        id="Nav Quality Over Time"
        style={{ position: "relative", width: "100%", height: "100%" }}
      >
        <HighchartsReact highcharts={Highcharts} options={options} />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ fontSize: "18px" }} id="alert-dialog-title">
            Add Annotation
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography style={{ marginBottom: "10px", width: "400px" }}>
                Adding annotation at- {clickedTime}
              </Typography>
              <TextField
                id="outlined-multiline-static"
                onChange={(event: any) => {
                  setAnnotationText(event?.target.value);
                }}
                placeholder="Add annotation..."
                fullWidth
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose} autoFocus>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleAddAnnotation}
              autoFocus
              disabled={annotationText === ""}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
        <OutOfFrame
          open={outOfTimeFrameOpen}
          onClose={() => setOutOfTimeFrameOpen(false)}
        />
      </div>
    </div>
  );
};

export default LgvNavQualityOverTime;
