import { ReactElement } from "react";
import { ticketDetailsStyles } from "../TicketDetailsStyles";
import { Dialog, DialogTitle, Divider } from "@mui/material";
import RichTextEditor from "../../../../maintainence/ticket-details/CommentSection/RichTextEditor";
import { ReactComponent as CloseIcon } from "../Icons/close-icon.svg";

export interface commentDialogIntrerface {
  open: boolean;
  onConfirm(comment: any): void;
  onClose(): void;
}
const CommentDialog = (props: commentDialogIntrerface): ReactElement => {
  const classes: any = ticketDetailsStyles();
  return (
    <Dialog
      open={props.open}
      aria-labelledby="form-dialog-title"
      maxWidth={false}
      className={classes.imageViewerDialog}
    >
      <div className={classes.imagePreviewWrapper}>
        <div className="contentWrapper">
          <div className={classes.imagePreviewBody}>
            <DialogTitle>Comments</DialogTitle>
            <CloseIcon onClick={props.onClose} />
          </div>
          <Divider />
          <RichTextEditor
            handleSubmit={(rawValue: any) => {
              props.onConfirm(rawValue);
            }}
            editorType="closeTicket"
            text={{}}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CommentDialog;
