import {
  LOAD_USER_ACCESS,
  LOAD_USER_ACCESS_SUCCESS,
  LOAD_USER_ACCESS_ERROR,
} from "../Types/userAccess.constants";

import axios from "../../Utils/AxiosConfig/axiosConfig";
import { put, call, takeEvery } from "redux-saga/effects";
import store from "../store";
import { SET_SCM_USER_PLANT_SUCCESS, SET_SCM_USER_PLANT_ERROR, SET_CURRENT_USER_SUCCESS, SET_CURRENT_USER_ACTIONS } from "../Types/users.constants";

//function to get current user role from the api
const getUserAccess = async () => {
  let res = null;
  res = await axios.get(`users/UserByADId`);
  return res.data.row;
};

export function* listUserAccess(): any {
  try {
    const data = yield call(() =>
      getUserAccess().then((res) => {
        return res;
      })
    );
    yield put({
      type: SET_CURRENT_USER_SUCCESS,
      payload: data[0],
    });
    yield put({
      type: LOAD_USER_ACCESS_SUCCESS,
      payload: data,
    });
    yield put({
      type: SET_SCM_USER_PLANT_SUCCESS,
      payload: data[0].scm_user_plant_group ? data[0].scm_user_plant_group : [],
    });
    yield put({
      type: SET_CURRENT_USER_ACTIONS,
      payload: data[0].user_actions ? data[0].user_actions : {},
    });
  } catch (error) {
    yield put({
      type: LOAD_USER_ACCESS_ERROR,
      payload: null,
    });
    yield put({
      type: SET_SCM_USER_PLANT_ERROR,
      payload: null,
    });
  }
}

export function* listUserAccessSaga() {
  yield takeEvery(LOAD_USER_ACCESS, listUserAccess);
}
