import {
  LOAD_OEM,
  LOAD_OEM_SUCCESS,
  LOAD_OEM_ERROR,
} from "../Types/OEM.constants";

// import axios from "axios";
import axios from "../../Utils/AxiosConfig/axiosConfig";
import { put, call, takeEvery } from "redux-saga/effects";

//function to get all OEMs from the api
const getOEMs = async () => {
  let OEMs: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`oem?pageSize=100&page=${page++}`);
    OEMs = OEMs.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);
  return OEMs;
};

export function* listOEMs(): any {
  try {
    const data = yield call(() =>
      getOEMs().then((res) => {
        return res;
      })
    );
    yield put({
      type: LOAD_OEM_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    console.error(error.response);
    yield put({
      type: LOAD_OEM_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export function* listOEMSaga() {
  yield takeEvery(LOAD_OEM, listOEMs);
}
