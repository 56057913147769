import { useState, useMemo, FC } from "react";
import { Box, Grid, Typography, Stack, Switch } from "@mui/material";
import { useSelector } from "react-redux";
import ZoomIn from "../Component/Icons/zoom-in-icon.svg";
import ZoomOut from "../Component/Icons/zoom-out-icon.svg";
import { HeatmapRespData } from "../Helpers/interfaces";
import {
  checkZoomInLimit,
  checkZoomOutLimit,
  zoomIn,
  zoomOut,
} from "../Helpers/utils";
import TimeSlider from "./TimeSlider";
import GradientLegend from "../Component/GradientLegend";
import DynamicPlantLayout from "./DynamicPlantLayout";
import { DynamicPlantLayoutWrapper as useStyles } from "./helpers/styles";
import moment from "moment";
import { Range } from "./helpers/interfaces";

interface NumberedObject<Type> {
  [key: number]: Type;
}

interface DynamicPlantLayoutWrapperProps {
  plantLayoutLgvRecords: { [lgvId: string]: HeatmapRespData[] };
  timeSliderStart: Date;
  timeSliderEnd: Date;
  timeMachineEnabled: boolean;
  handleTimeMachineChange: () => void;
  plantCode: string;
  HeatMapData: NumberedObject<HeatmapRespData>[];
  navHeatMapConfig: any;
  formattedSelectedAssetsData: string[];
}

const DynamicPlantLayoutWrapper: FC<DynamicPlantLayoutWrapperProps> = ({
  plantLayoutLgvRecords,
  timeSliderStart,
  timeSliderEnd,
  timeMachineEnabled,
  handleTimeMachineChange,
  plantCode,
  HeatMapData,
  navHeatMapConfig,
  formattedSelectedAssetsData,
}) => {
  const classes = useStyles();

  const lgvStoreLiveData = useSelector((state: any) =>
    !timeMachineEnabled ? state.lgvHealth.liveData : null
  );
  const seedsList = useSelector((state: any) => state.seedsList);

  const [selectedRange, setSelectedRange] = useState<Range>({
    start: moment(timeSliderStart),
    end: moment(timeSliderStart).add(1, "seconds"),
  });
  const [mapContainerZoomLevel, setMapContainerZoomLevel] = useState<any>(null);

  const availablePlantLayouts =
    seedsList.seeds
      .find((seed: any) => {
        return seed.seed_key_name === "LgvHealthConfig";
      })
      ?.seed_data_value?.find((keyObj: any) => {
        return keyObj?.key === "availablePlantLayout";
      })?.value || [];

  const filteredRecords = useMemo(() => {
    return Object.values(plantLayoutLgvRecords)
      .map((records) => {
        for (let i = records.length - 1; i >= 0; i--) {
          const record = records[i];

          if (
            moment(record.timeStamp).isBetween(
              selectedRange.start,
              selectedRange.end,
              undefined,
              "[)"
            )
          )
            return record;
        }
      })
      .filter(Boolean);
  }, [plantLayoutLgvRecords, selectedRange, formattedSelectedAssetsData]);

  return (
    <Box
      sx={{
        background: "#293368",
        padding: "10px",
        borderRadius: "8px",
        marginBottom: "20px",
      }}
    >
      <Grid item>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              p={1}
              sx={{
                color: "#ffffff",
                fontSize: "18px",
                fontStyle: "Roboto",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              Dynamic Plant Layout
            </Typography>
            {!timeMachineEnabled && (
              <span className={classes.liveIndicator}>
                <span className={classes.liveDot}></span> Live
              </span>
            )}
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ fontSize: "16px", marginRight: "2em" }}>
              Time Machine
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Switch
                classes={{
                  root: classes.root,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
                }}
                checked={timeMachineEnabled}
                onChange={handleTimeMachineChange}
              />
            </Stack>
          </div>
        </Box>
        {timeMachineEnabled && (
          <TimeSlider
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
            startDate={timeSliderStart}
            endDate={timeSliderEnd}
          />
        )}
      </Grid>
      {availablePlantLayouts.includes(plantCode) ? (
        <>
          <Grid item>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Grid className={classes.zoomControlCenter}>
                <Grid item xs={12}>
                  <button
                    id="map-zoom-in-btn"
                    className={[
                      classes.zoomControls,
                      classes.zoomInControl,
                    ].join(" ")}
                    style={{ cursor: "pointer" }}
                    disabled={checkZoomInLimit(
                      "dynamic-plant-layout-id-reference"
                    )}
                    onClick={() =>
                      zoomIn(
                        "dynamic-plant-layout-id-reference",
                        setMapContainerZoomLevel
                      )
                    }
                  >
                    <img src={ZoomIn} alt="+" />
                  </button>
                </Grid>
                <Grid item xs={12}>
                  <button
                    id="map-zoom-out-btn"
                    className={[
                      classes.zoomControls,
                      classes.zoomOutControl,
                    ].join(" ")}
                    style={{ cursor: "pointer" }}
                    disabled={checkZoomOutLimit(
                      "dynamic-plant-layout-id-reference"
                    )}
                    onClick={() =>
                      zoomOut(
                        "dynamic-plant-layout-id-reference",
                        setMapContainerZoomLevel
                      )
                    }
                  >
                    <img src={ZoomOut} alt="-" />
                  </button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item className={classes.dynamicPlantLayoutContainer}>
            <DynamicPlantLayout
              plantCode={plantCode}
              lgvData={timeMachineEnabled ? filteredRecords : lgvStoreLiveData}
              timeMachineOn={timeMachineEnabled}
              lgvConfig={navHeatMapConfig}
              lgvPathData={HeatMapData}
              selectedAssets={formattedSelectedAssetsData}
              mapContainerZoomLevel={mapContainerZoomLevel}
            />
          </Grid>
          <GradientLegend data={navHeatMapConfig} />
        </>
      ) : (
        <Typography textAlign="center" p={1} sx={{ fontSize: "16px" }}>
          Plant Layout not available
        </Typography>
      )}
    </Box>
  );
};

export default DynamicPlantLayoutWrapper;
