import React from 'react';
import { cloneDeep, get, isArray, isEmpty } from 'lodash';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const AsepticIcon = (props: any) => {
  const { items, onSeqChange, dropdownOptions } = props;

  const handleChange = (newValue: any, editIndex: number) => {
    let clonedUpdatedItems = cloneDeep(items);

    clonedUpdatedItems.map((item: any, index: number) =>{
      if (index === editIndex) {
        item.value = newValue;
      }
      return item; 
    })

    onSeqChange(clonedUpdatedItems);
  }

  const getUnassignedDropdownOptions = (currentIndex: number) => {
    let otherItemsValues: any[] = [];
    items.forEach((item: any, index: number) => {
      if (index !== currentIndex) {
        otherItemsValues = [
          ...otherItemsValues,
          ...item.value
        ]
      }
    })
    const returnOptions: any[] = dropdownOptions.filter((option: any) => {
      if (items[currentIndex].value.includes(option.asset_alias)) return true;
      if (!otherItemsValues.includes(option.asset_alias)) return true;
      return false;
    });
    
    return returnOptions.map((option: any) => get(option, 'asset_alias'));
  }

  if (isEmpty(items) || !isArray(items)) return null;
  if (!items[0].key) return null;

  return (
    <div className="selfConfigComponent">
      {items.map((item: any, index: number) => (
        <Autocomplete
          multiple
          getOptionLabel={(option: any) => option}
          options={getUnassignedDropdownOptions(index)}
          value={[...item.value]}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={item.key}
            />
          )}
          onChange={(event, newValue) => handleChange(newValue, index)}
          sx={{
            marginTop: '16px',
            maxWidth: '550px',
            maxHeight: '100px',
            overflow: 'auto',
            overflowX: 'hidden',
          }}
        />
      ))}
    </div>
  );
}


export default AsepticIcon;
