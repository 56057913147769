export const LOAD_USERS = "LOAD_USERS";
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_USERS_ERROR = "LOAD_USERS_ERROR";
export const LOAD_PENDING_APPROVAL_USERS = "LOAD_PENDING_APPROVAL_USERS";
export const LOAD_PENDING_APPROVAL_USERS_SUCCESS = "LOAD_PENDING_APPROVAL_USERS_SUCCESS";
export const LOAD_PENDING_APPROVAL_USERS_ERROR = "LOAD_PENDING_APPROVAL_USERS_ERROR";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_USER_SUCCESS = "SET_CURRENT_USER_SUCCESS";
export const SET_CURRENT_USER_IMAGE = "SET_CURRENT_USER_IMAGE";
export const SET_CURRENT_USER_IMAGE_SUCCESS = "SET_CURRENT_USER_IMAGE_SUCCESS";
export const SET_CURRENT_USER_PERMISSIONS = "SET_CURRENT_USER_PERMISSIONS";
export const SET_CURRENT_USER_PERMISSIONS_SUCCESS =
  "SET_CURRENT_USER_PERMISSIONS_SUCCESS";
export const SET_CURRENT_ACCOUNT_PERMISSIONS =
  "SET_CURRENT_ACCOUNT_PERMISSIONS";
export const SET_CURRENT_ACCOUNT_PERMISSIONS_SUCCESS =
  "SET_CURRENT_ACCOUNT_PERMISSIONS_SUCCESS";
export const SET_ACCESSIBLE_PLANTS = "SET_ACCESSIBLE_PLANTS";
export const SET_ACCESSIBLE_PLANTS_SUCCESS = "SET_ACCESSIBLE_PLANTS_SUCCESS";
export const STORE_ALL_USERS_IMAGE = "STORE_ALL_USERS_IMAGE";
export const STORE_ALL_USERS_IMAGE_SUCCESS = "STORE_ALL_USERS_IMAGE_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const UPDATE_CURRENT_USER_SUCCESS = "UPDATE_CURRENT_USER_SUCCESS";
export const UPDATE_CURRENT_USER_ERROR = "UPDATE_CURRENT_USER_ERROR";
export const SET_CURRENT_USER_ACTIONS = "SET_CURRENT_USER_ACTIONS";
export const SET_SCM_USER_PLANT = "SET_SCM_USER_PLANT";
export const SET_SCM_USER_PLANT_SUCCESS = "SET_SCM_USER_PLANT_SUCCESS";
export const SET_SCM_USER_PLANT_ERROR = "SET_SCM_USER_PLANT_ERROR";
