import {
  LOAD_ALL_USERS_IMAGE,
  LOAD_ALL_USERS_IMAGE_SUCCESS,
  LOAD_ALL_USERS_IMAGE_ERROR,
} from "../Types/userImages.constants";
import Axios from "../../Utils/AxiosConfig/axiosConfig";
import { put, call, takeEvery } from "redux-saga/effects";
import { SetMultipleUserImage } from "../../pages/master/User/AllUsersProfilePicture";
import { returnAccessToken } from "../../Services/authService";

//function to get all users from the api
const getUsers = async () => {
  let users: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await Axios.get(`users?pageSize=100&page=${page++}`);
    users = users.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);
  return users;
};

// function to get all users image from graph
const getUsersImage = async (users: any, accessToken: any) => {
  const result = await Promise.all(
    users.map(async (data: any) => {
      const response = await SetMultipleUserImage(data.user_ad_id, accessToken);
      return { id: data.user_ad_id, image: response };
    })
  );
  return result;
};

export function* storeUsersImage(action: any): any {
  try {
    const acessToken = yield call(() =>
      returnAccessToken().then((res) => {
        return res;
      })
    );

    const users = yield call(() =>
      getUsers().then((res) => {
        return res;
      })
    );
    // console.log("Spa Acess Token", acessToken);
    if (users) {
      const userImages = yield call(() =>
        getUsersImage(users, acessToken).then((res) => {
          return res;
        })
      );

      const result = userImages.map((e: any) => {
        let temp = users.find((element: any) => element.user_ad_id === e.id);
        if (temp) {
          temp.userImage = e.image;
        }
        return temp;
      });

      yield put({
        type: LOAD_ALL_USERS_IMAGE_SUCCESS,
        payload: result,
      });
    }
  } catch (error) {
    // console.error(error)
  }
}
export function* userImagesSaga() {
  yield takeEvery(LOAD_ALL_USERS_IMAGE, storeUsersImage);
}
