import { ReactElement, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import { ticketDetailsStyles } from "../TicketDetailsStyles";
import { ReactComponent as CloseIcon } from "../Icons/close-icon.svg";
import AttachFile from "@mui/icons-material/AttachFile";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import uploadFileToBlob from "../../../../../Services/azure-storage-blob";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
export interface logilityInferface {
  open: boolean;
  onConfirm(logilityObject: any): void;
  onClose(): void;
  plant_downtime_start_date: Dayjs | null | any;
  plant_downtime_end_date: Dayjs | null;
  changeStatus: String | null;
  currentStatus: String | null;
}

const Logility = (props: logilityInferface): ReactElement => {
  const classes: any = ticketDetailsStyles();
  const min_date =
    props.plant_downtime_start_date < dayjs(moment().format("MM-DD-YYYY HH:MM"))
      ? props.plant_downtime_start_date
      : dayjs(moment().format("MM-DD-YYYY HH:MM"));
  const [startDate, setStartDate] = useState<any>(
    props.plant_downtime_start_date
  );
  const [endDate, setEndDate] = useState<any>(props.plant_downtime_end_date);

  const [comment, setComment] = useState("");
  const [attachment, setAttachment] = useState<any>({
    file: null,
    url: null
  });
  const [loading, setLoadering] = useState<boolean>(false);
  const usersList = useSelector((state: any) => state.usersList);
  const dispatch = useDispatch();
  const currentUser = usersList.currentUser;
  const [actionLoader, setActionLoader] = useState<boolean>(false);
  const handleCloseStatusChangeConfirm = () => {
    props.onClose();
  };

  const handleFileChange = async (event: any): Promise<void> => {
    try {
      setLoadering(true);
      const selectedFile = event?.target?.files[0];
      const url = await uploadFileToBlob(selectedFile);
      if (!url) {
        return;
      }
      setAttachment({
        file: selectedFile,
        url: url
      });
    } catch (error) {
      console.error("Error:[handleFileChange]", error);
      dispatch({
        type: "LOAD_SNACKBAR",
        payload: {
          message: "File format not supprted. Please try again",
          type: "error",
          open: true
        }
      });
    } finally {
      setLoadering(false);
      event.target.value = "";
    }
  };

  const handleConfirmStatusChange = () => {
    setActionLoader(true);
    const commentRawValue = {
      blocks: [
        {
          key: comment?.trim(),
          text: attachment?.file?.name + " " + comment?.trim(),
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [
            {
              offset: 0,
              length: attachment?.file?.name?.length,
              key: 0
            }
          ],
          data: {}
        }
      ],
      entityMap: {
        "0": {
          type: "LINK",
          mutability: "IMMUTABLE",
          data: {
            url: attachment.url,
            targetOption: "_blank"
          }
        }
      }
    };

    const downtime_start_date = new Date(startDate);
    const downtime_end_date = new Date(endDate);

    if (downtime_start_date.getTime() == downtime_end_date.getTime()) {
      dispatch({
        type: "LOAD_SNACKBAR",
        payload: {
          message: "Plant downtime start and end dates should not be same",
          type: "error",
          open: true
        }
      });
      return;
    }

    if (downtime_start_date.getTime() > downtime_end_date.getTime()) {
      dispatch({
        type: "LOAD_SNACKBAR",
        payload: {
          message:
            "Plant downtime start date should not be greater than end date",
          type: "error",
          open: true
        }
      });
      return;
    }

    const logility = {
      attachmentUrl: attachment.url,
      message: comment?.trim(),
      plant_downtime_start_date: moment(new Date(startDate)).format(
        "MM/DD/YYYY"
      ),
      plant_downtime_end_date: moment(new Date(endDate)).format("MM/DD/YYYY"),
      status: props.changeStatus,
      created_on: new Date().toISOString(),
      created_by: currentUser._id,
      user_role_name:
        props.changeStatus === "Master Planner Confirmed"
          ? "Core Master Planner"
          : "FG Planner"
    };
    props.onConfirm({
      rawValue: commentRawValue,
      logility: logility
    });
  };

  return (
    <Dialog
      open={props.open}
      aria-labelledby="form-dialog-title"
      maxWidth={false}
      className={classes.imageViewerDialog}
    >
      <div className={classes.logilityDialogWrapper}>
        <div className="contentWrapper">
          <div className={classes.logilityDialogTitle}>
            <DialogTitle>Logility</DialogTitle>
            <CloseIcon onClick={handleCloseStatusChangeConfirm} />
          </div>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="20px"
                  className={classes.statusAttechment}
                >
                  <label>Attach Screenshot *</label>
                  <input
                    accept="image/*,.pdf,.doc,.docx"
                    style={{ display: "none" }}
                    id="fileInput"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="fileInput">
                    <Button
                      className={classes.statusAttechmentButton}
                      variant="contained"
                      component="span"
                      endIcon={<AttachFile />}
                    >
                      Browse
                    </Button>
                  </label>
                  {attachment?.file && (
                    <Box ml={2} display="flex" alignItems="center">
                      <Typography variant="body1">
                        {attachment?.file?.name}
                      </Typography>
                      <HighlightOffRoundedIcon
                        className={classes.attachmentCloseIcon}
                        onClick={() => {
                          setAttachment({
                            file: null,
                            url: null
                          });
                        }}
                      />
                    </Box>
                  )}
                  {loading && (
                    <CircularProgress
                      sx={{
                        color: "white",
                        height: "1px",
                        marginRight: "10px"
                      }}
                      size={18}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.statusDatePiker}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <Grid item xs={12}>
                        <DatePicker
                          minDate={min_date}
                          label="Plant Downtime Start Date"
                          value={startDate}
                          onChange={(newValue) => setStartDate(newValue)}
                          format="MM/DD/YYYY"
                          disabled={props?.currentStatus !== "TBD"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DatePicker
                          label="Plant Downtime End Date"
                          value={endDate}
                          onChange={(newValue) => setEndDate(newValue)}
                          minDate={startDate}
                          format="MM/DD/YYYY"
                          disabled={props?.currentStatus !== "TBD"}
                        />
                      </Grid>
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Comment"
                  multiline
                  rows={8}
                  value={comment}
                  className={classes.statusCommentBox}
                  onChange={(e) => setComment(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid
                item
                md={12}
                textAlign={"right"}
                marginTop={"8px"}
                className="btnFoot"
              >
                <Button
                  onClick={handleCloseStatusChangeConfirm}
                  variant="outlined"
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleConfirmStatusChange}
                  className="saveBtn"
                  disabled={
                    !(
                      attachment?.file &&
                      startDate &&
                      endDate &&
                      comment &&
                      comment?.trim()
                    ) || actionLoader
                  }
                >
                  {actionLoader ? (
                    <CircularProgress
                      sx={{
                        color: "white",
                        height: "1px",
                        marginRight: "10px"
                      }}
                      size={18}
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </Dialog>
  );
};

export default Logility;
