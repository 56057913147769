import {
  LOAD_TICKETS,
  LOAD_TICKETS_SUCCESS,
  LOAD_TICKETS_ERROR,
  LOAD_TICKETS_BY_ALERTS,
  LOAD_TICKETS_BY_ALERTS_SUCCESS,
  LOAD_TICKETS_BY_ALERTS_ERROR,
  UPDATE_PAGINATION,
  UPDATE_PAGINATION_SUCCESS,
  UPDATE_PAGINATION_ERROR,
  LOAD_ALL_TICKETS,
  LOAD_ALL_TICKETS_SUCCESS,
  LOAD_ALL_TICKETS_ERROR,
  EXPORT_TICKETS,
  EXPORT_TICKETS_SUCCESS,
  EXPORT_TICKETS_ERROR,
  FILTER_STATUS,
  FILTER_STATUS_SUCCESS,
  FILTER_STATUS_ERROR,
  UPDATE_FILTER_PARAMS,
  UPDATE_FILTER_PARAMS_SUCCESS,
  UPDATE_FILTER_PARAMS_ERROR,
  LOAD_ALL_TICKETS_USECASE,
  LOAD_ALL_TICKETS_USECASE_ERROR,
  LOAD_ALL_TICKETS_USECASE_SUCCESS,
  SET_CURRENT_TICKET,
  SET_CURRENT_TICKET_SUCCESS,
  SET_CURRENT_TICKET_ERROR,
  UPDATE_CURRENT_TICKET,
} from "../Types/tickets.contants";

const INITIAL_STATE = {
  loading: false,
  tickets: [],
  error: null,
  ticketsByAlerts: [],
  pagination: { pageNo: 1, pageSize: 10 },
  totalTicketsCount: 0,
  allTicket: [],
  exportListData: [],
  filterStatus: false,
  filterParam: {
    category: "all_tickets",
    ticketId: "",
    useCaseId: "",
    plantId: "",
    asset_area: "",
    status: "",
    assignee: "",
    asset_alias: "",
    usecase_name: "",
    healthStatus: "",
    created_on: "",
    sort: { created_on: -1 },
  },
  alertStatus: false,
  currentTicket: {},
};

export const ticketsListReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_TICKETS:
      return { ...state, loading: true };
    case LOAD_TICKETS_SUCCESS:
      return {
        ...state,
        tickets: action.payload.ticketsRes,
        totalTicketsCount: action.payload.ticketDataCount,
        loading: false,
        alertStatus: false,
      };
    case LOAD_TICKETS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case LOAD_TICKETS_BY_ALERTS:
      return { ...state, loading: true };
    case LOAD_TICKETS_BY_ALERTS_SUCCESS:
      return {
        ...state,
        loading: false,
        ticketsByAlerts: action.payload,
      };
    case LOAD_TICKETS_BY_ALERTS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case SET_CURRENT_TICKET:
      return { ...state };
    case SET_CURRENT_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        currentTicket: action.payload,
      };
    case SET_CURRENT_TICKET_ERROR:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_CURRENT_TICKET:
      return { ...state, currentTicket: action.payload };
    case UPDATE_PAGINATION:
      return { ...state, loading: true };

    case UPDATE_PAGINATION_SUCCESS:
      return { ...state, pagination: action.payload };

    case UPDATE_PAGINATION_ERROR:
      return { ...state, error: action.payload };
    case LOAD_ALL_TICKETS:
      return { ...state, loading: true };
    case LOAD_ALL_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTicket: action.payload,
      };
    case LOAD_ALL_TICKETS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case LOAD_ALL_TICKETS_USECASE:
      return { ...state, loading: true };
    case LOAD_ALL_TICKETS_USECASE_SUCCESS:
      return {
        ...state,
        tickets: action.payload,
        loading: false,
      };
    case LOAD_ALL_TICKETS_USECASE_ERROR:
      return { ...state, loading: false, error: action.payload };
    case EXPORT_TICKETS:
      return { ...state, loading: true };
    case EXPORT_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        exportListData: action.payload,
      };
    case EXPORT_TICKETS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case FILTER_STATUS:
      return { ...state };
    case FILTER_STATUS_SUCCESS:
      return {
        ...state,
        filterStatus: action.payload,
      };
    case FILTER_STATUS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case UPDATE_FILTER_PARAMS:
      return { ...state };
    case UPDATE_FILTER_PARAMS_SUCCESS:
      return {
        ...state,
        filterParam: action.payload,
      };
    case UPDATE_FILTER_PARAMS_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
