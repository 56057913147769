import {
  LOAD_USER_PERMISSIONS,
  LOAD_USER_PERMISSIONS_SUCCESS,
  LOAD_USER_PERMISSIONS_ERROR,
  UPDATE_USER_PERMISSION,
  UPDATE_USER_PERMISSIONS_SUCCESS,
  UPDATE_USER_PERMISSIONS_ERROR,
  DELETE_USER_PERMISSION,
  DELETE_USER_PERMISSIONS_SUCCESS,
  DELETE_USER_PERMISSIONS_ERROR,
} from "../Types/userPermissions.constants";

const INITIAL_STATE = {
  loading: false,
  userPermissions: [],
  error: null,
};

export const userPermissionsListReducer = (
  state = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case LOAD_USER_PERMISSIONS:
      return { ...state, loading: true, userPermissions: [] };
    case LOAD_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        userPermissions: action.payload,
      };
    case LOAD_USER_PERMISSIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_USER_PERMISSION:
      return { ...state, loading: true };
    case UPDATE_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        userPermissions:
          state.userPermissions.filter((ele: any) => {
            return ele._id === action.payload._id;
          }).length === 0
            ? [...state.userPermissions, action.payload]
            : [
                ...state.userPermissions.filter((ele: any) => {
                  return ele._id !== action.payload._id;
                }),
                action.payload,
              ],
      };
    case UPDATE_USER_PERMISSIONS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case DELETE_USER_PERMISSION:
      return { ...state, loading: true };
    case DELETE_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        userPermissions: state.userPermissions.filter((ele: any) => {
          return ele._id !== action.payload._id;
        }),
      };
    case DELETE_USER_PERMISSIONS_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
