import {
  LOAD_MESSAGES,
  LOAD_MESSAGES_SUCCESS,
  LOAD_MESSAGES_ERROR,
  ADD_MESSAGE,
  ADD_MESSAGE_ERROR,
  ADD_MESSAGE_SUCCESS,
  LOAD_ALL_MESSAGES,
  LOAD_ALL_MESSAGES_SUCCESS,
  LOAD_ALL_MESSAGES_ERROR,
  UPDATE_MESSAGE_PAGINATION,
  UPDATE_MESSAGE_PAGINATION_SUCCESS,
  UPDATE_MESSAGE_PAGINATION_ERROR,
  UPDATE_MESSAGE_FILTER_PARAMS,
  UPDATE_MESSAGE_FILTER_PARAMS_SUCCESS,
  UPDATE_MESSAGE_FILTER_PARAMS_ERROR,
} from "../Types/messages.contants";

const INITIAL_STATE = {
  loading: false,
  messages: [],
  error: null,
  pagination: { pageNo: 1, pageSize: 10 },
  totalMessagesCount: 0,
  paginatedMessages: [],
  filterParams: {},
};

export const messagesListReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_MESSAGES:
      return { ...state, loading: true };
    case LOAD_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: action.payload,
      };
    case LOAD_MESSAGES_ERROR:
      return { ...state, loading: false, error: action.payload };

    case ADD_MESSAGE:
      return { ...state, loading: true };
    case ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: [...state.messages, action.payload],
      };
    case ADD_MESSAGE_ERROR:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_MESSAGE_PAGINATION:
      return { ...state };
    case UPDATE_MESSAGE_PAGINATION_SUCCESS:
      return { ...state, pagination: action.payload };
    case UPDATE_MESSAGE_PAGINATION_ERROR:
      return { ...state, error: action.payload };
    case LOAD_ALL_MESSAGES:
      return { ...state, loading: true };
    case LOAD_ALL_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        paginatedMessages: action.payload.messagesRes,
        totalMessagesCount: action.payload.messagesDataCount,
      };
    case LOAD_ALL_MESSAGES_ERROR:
      return { ...state, loading: false, paginatedMessages: action.payload };
    case UPDATE_MESSAGE_FILTER_PARAMS:
      return { ...state };
    case UPDATE_MESSAGE_FILTER_PARAMS_SUCCESS:
      return { ...state, filterParams: action.payload };
    case UPDATE_MESSAGE_FILTER_PARAMS_ERROR:
      return { ...state, filterParams: action.payload };
    default:
      return state;
  }
};
