import { ReactElement } from "react";
import { dashboardStyles } from "../DowntimeLandingPageStyles";
import { Tabs, Tab } from "@mui/material";
import { Check } from "@mui/icons-material";

export interface tabOptions {
  label: string;
  key: string;
  disabled?: boolean;
}

export interface TabsControlInterface {
  tabValue: number;
  handleChange(event: any, newValue: number, tabItem: any): void;
  className?: any;
  options: Array<tabOptions>;
  type?: string;
  isFlexible?: boolean;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabsControl = (props: TabsControlInterface): ReactElement => {
  const classes = dashboardStyles();

  const renderEachTab = (
    item: tabOptions,
    index: number,
    value: number,
    isFlexible?: boolean
  ): ReactElement => {
    return (
      <Tab
        disabled={item?.disabled}
        {...a11yProps(index)}
        label={item.label}
        icon={props.type === "scheduler" && value === index ? <Check /> : <></>}
        iconPosition="start"
        wrapped={isFlexible ? true : undefined}
      />
    );
  };

  return (
    <Tabs
      value={props.tabValue}
      onChange={(event: any, newValue: any) => {
        if (typeof props.handleChange === "function") {
          props.handleChange(event, newValue, props.options[newValue]);
        }
      }}
      aria-label="basic tabs example"
      className={props.className || classes.tabsControl}
      variant={props?.isFlexible ? "fullWidth" : "scrollable"}
      scrollButtons="auto"
    >
      {props.options.map((item: tabOptions, index: number) => {
        return renderEachTab(item, index, props.tabValue, props?.isFlexible);
      })}
    </Tabs>
  );
};

export default TabsControl;
