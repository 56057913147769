import moment from 'moment'

export const lgvSampleData = []

export const getHeatmapDetails = [
	{
		timeStamp: new Date(
			moment.utc().subtract(300, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 1',
		locationCode: 'HOU',
		xPos: 238412,
		yPos: 89264,
		segmentId: 85880,
		navQuality: 55
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(300, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 2',
		locationCode: 'HOU',
		xPos: 237988,
		yPos: 89270,
		segmentId: 85881,
		navQuality: 50
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(300, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 3',
		segmentId: 85882,
		navQuality: 45,
		locationCode: 'HOU',
		yPos: 89249,
		xPos: 127406
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(299, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 4',
		segmentId: 85883,
		navQuality: 40,
		locationCode: 'HOU',
		yPos: 89246,
		xPos: 159406
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(299, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 5',
		segmentId: 85884,
		navQuality: 35,
		locationCode: 'HOU',
		yPos: 54028,
		xPos: 60101
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(299, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 1',
		segmentId: 85885,
		navQuality: 30,
		locationCode: 'HOU',
		yPos: 54029,
		xPos: 60100
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(298, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 2',
		segmentId: 85886,
		navQuality: 25,
		locationCode: 'HOU',
		yPos: 89254,
		xPos: 284734
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(298, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 3',
		segmentId: 85887,
		navQuality: 20,
		locationCode: 'HOU',
		yPos: 89247,
		xPos: 262638
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(29, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 4',
		segmentId: 85888,
		navQuality: 15,
		locationCode: 'HOU',
		yPos: 89246,
		xPos: 262639
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(29, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 5',
		segmentId: 85889,
		navQuality: 10,
		locationCode: 'HOU',
		yPos: 89247,
		xPos: 159403
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(100, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 1',
		segmentId: 85810,
		navQuality: 5,
		locationCode: 'HOU',
		yPos: 63611,
		xPos: 41155
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(110, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 2',
		segmentId: 85811,
		navQuality: 100,
		locationCode: 'HOU',
		yPos: 39889,
		xPos: 122805
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(150, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 3',
		segmentId: 85812,
		navQuality: 95,
		locationCode: 'HOU',
		yPos: 89251,
		xPos: 104552
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(295, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 4',
		segmentId: 85813,
		navQuality: 90,
		locationCode: 'HOU',
		yPos: 58700,
		xPos: 65324
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(10, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 5',
		segmentId: 85814,
		navQuality: 85,
		locationCode: 'HOU',
		yPos: 63621,
		xPos: 50353
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(20, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 1',
		segmentId: 85815,
		navQuality: 80,
		locationCode: 'HOU',
		yPos: 58854,
		xPos: 59163
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(68, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 2',
		xPos: 267653,
		locationCode: 'HOU',
		segmentId: 85816,
		navQuality: 75,
		yPos: 80250
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(4, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 3',
		xPos: 267653,
		locationCode: 'HOU',
		segmentId: 85817,
		navQuality: 70,
		yPos: 80250
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(2, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 4',
		xPos: 267653,
		locationCode: 'HOU',
		segmentId: 85818,
		navQuality: 65,
		yPos: 80251
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(200, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 5',
		xPos: 267654,
		locationCode: 'HOU',
		segmentId: 85819,
		navQuality: 60,
		yPos: 80250
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(253, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 1',
		xPos: 267653,
		locationCode: 'HOU',
		segmentId: 85820,
		navQuality: 55,
		yPos: 80249
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(292, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 2',
		xPos: 267657,
		locationCode: 'HOU',
		segmentId: 85821,
		navQuality: 50,
		yPos: 80247
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(292, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 3',
		locationCode: 'HOU',
		segmentId: 85822,
		navQuality: 45,
		yPos: 89301,
		xPos: 113423
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(178, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 4',
		locationCode: 'HOU',
		segmentId: 85823,
		navQuality: 40,
		yPos: 52902,
		xPos: 237636
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(291, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 5',
		locationCode: 'HOU',
		segmentId: 85824,
		navQuality: 35,
		yPos: 52902,
		xPos: 237635
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(291, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 1',
		locationCode: 'HOU',
		segmentId: 85825,
		navQuality: 30,
		yPos: 52902,
		xPos: 237634
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(159, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 2',
		locationCode: 'HOU',
		segmentId: 85826,
		navQuality: 25,
		yPos: 52901,
		xPos: 237634
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(290, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 3',
		locationCode: 'HOU',
		segmentId: 85827,
		navQuality: 20,
		yPos: 52909,
		xPos: 251099
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(289, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 4',
		locationCode: 'HOU',
		segmentId: 85828,
		navQuality: 15,
		yPos: 70098,
		xPos: 274186
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(289, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 5',
		locationCode: 'HOU',
		segmentId: 85829,
		navQuality: 10,
		yPos: 70078,
		xPos: 275249
	},
	{
		timeStamp: new Date(
			moment.utc().subtract(288, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		lgvId: 'LGV 1',
		locationCode: 'HOU',
		segmentId: 85830,
		navQuality: 5,
		yPos: 70053,
		xPos: 276495
	}
]

export const staticLgvPathData = [
	//1
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 7
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 6
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 5
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 4
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 3
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 2
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * -1
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * -2
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * -3
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * -4
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * -5
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * -6
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * -7
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * -8
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691 + 12 * -1,
			lng: -538.2763739376771 + 14 * -8
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691 + 12 * -2,
			lng: -538.2763739376771 + 14 * -8
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691 + 12 * -3,
			lng: -538.2763739376771 + 14 * -8
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691 + 12 * -4,
			lng: -538.2763739376771 + 14 * -8
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691 + 12 * -5,
			lng: -538.2763739376771 + 14 * -8
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691 + 12 * -6,
			lng: -538.2763739376771 + 14 * -8
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691 + 12 * -7,
			lng: -538.2763739376771 + 14 * -8
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '1',
		currentPos: {
			lat: 8.86952184851691 + 12 * -8,
			lng: -538.2763739376771 + 14 * -8
		}
	},
	//2
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -4,
			lng: -120.28938243626067
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -5,
			lng: -120.28938243626067
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -6,
			lng: -120.28938243626067
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -7,
			lng: -120.28938243626067
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -8,
			lng: -120.28938243626067
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -9,
			lng: -120.28938243626067
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -10,
			lng: -120.28938243626067
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -11,
			lng: -120.28938243626067
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -12,
			lng: -120.28938243626067
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -13,
			lng: -120.28938243626067
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -13,
			lng: -120.28938243626067 + 14 * 1
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -13,
			lng: -120.28938243626067 + 14 * 2
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -13,
			lng: -120.28938243626067 + 14 * 3
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -13,
			lng: -120.28938243626067 + 14 * 4
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -13,
			lng: -120.28938243626067 + 14 * 5
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -13,
			lng: -120.28938243626067 + 14 * 6
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -13,
			lng: -120.28938243626067 + 14 * 7
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -13,
			lng: -120.28938243626067 + 14 * 8
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -13,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -15,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -16,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -17,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -18,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -19,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -20,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '2',
		currentPos: {
			lat: 8.86952184851691 + 12 * -21,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	//3
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691 + 12 * -8,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691 + 12 * -7,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691 + 12 * -6,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691 + 12 * -5,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691 + 12 * -4,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691 + 12 * -3,
			lng: -120.28938243626067 + 14 * 9
		}
	},

	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691 + 12 * -2,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691 + 12 * -1,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691,
			lng: -120.28938243626067 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691,
			lng: -120.28938243626067 + 14 * 8
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691,
			lng: -120.28938243626067 + 14 * 7
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691,
			lng: -120.28938243626067 + 14 * 6
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691,
			lng: -120.28938243626067 + 14 * 5
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691,
			lng: -120.28938243626067 + 14 * 4
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691,
			lng: -120.28938243626067 + 14 * 3
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691,
			lng: -120.28938243626067 + 14 * 2
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691,
			lng: -120.28938243626067 + 14
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691,
			lng: -120.28938243626067
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691,
			lng: -120.28938243626067 + 14 * -1
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '3',
		currentPos: {
			lat: 8.86952184851691,
			lng: -120.28938243626067 + 14 * -2
		}
	},
	//4
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '4',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '4',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '4',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 2
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '4',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 3
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '4',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 4
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '4',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 5
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '4',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 6
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '4',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 7
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '4',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 8
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '4',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '4',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 10
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '4',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 11
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '4',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 12
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '4',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 13
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '4',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 14
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '4',
		currentPos: {
			lat: 8.86952184851691,
			lng: -538.2763739376771 + 14 * 15
		}
	},
	//5
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771 + 14 * -15
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771 + 14 * -14
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771 + 14 * -13
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771 + 14 * -12
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771 + 14 * -11
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771 + 14 * -10
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771 + 14 * -9
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771 + 14 * -8
		}
	},

	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771 + 14 * -7
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771 + 14 * -6
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771 + 14 * -5
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771 + 14 * -4
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771 + 14 * -3
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771 + 14 * -2
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771 + 14 * -1
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771 + 14
		}
	},
	{
		segmentId: 28745,
		locationCode: 'HOU',
		timeStamp: new Date(
			moment.utc().subtract(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.000')
		),
		navQuality: 60,
		lgvId: '5',
		currentPos: {
			lat: 8.86952184851691 + 12 * -14,
			lng: -538.2763739376771
		}
	}
]

export const LgvNavQualityOverTimeSeriesData = [
	{
		x: moment('2024-03-01T02:30:00.000Z').valueOf(),
		y: 42
	},
	{
		x: moment('2024-03-01T03:30:00.000Z').valueOf(),
		y: undefined
	},
	{
		x: moment('2024-03-01T04:30:00.000Z').valueOf(),
		y: 50
	},
	{
		x: moment('2024-03-01T05:30:00.000Z').valueOf(),
		y: 44
	},
	{ x: moment('2024-03-01T06:30:00.000Z').valueOf(), y: 80 }
]

export const PlsStatusSummaryTableRecords = [
	{
		sensorName: 'S300Mini Vertical Right Sensor',
		dirtyStatus: 'Clean',
		alarmStatus: 'Okay'
	},
	{
		sensorName: 'S300Mini Vertical Left Sensor',
		dirtyStatus: 'Clean',
		alarmStatus: 'Okay'
	},
	{
		sensorName: 'S300Mini Backward Right Sensor',
		dirtyStatus: 'Clean',
		alarmStatus: 'Okay'
	},
	{
		sensorName: 'S300Mini Backward Left Sensor',
		dirtyStatus: 'Clean',
		alarmStatus: 'Okay'
	},
	{
		sensorName: 'S300 Backward Sensor',
		dirtyStatus: 'Clean',
		alarmStatus: 'Okay'
	},
	{
		sensorName: 'S300 Fordward Sensor',
		dirtyStatus: 'Dirty',
		alarmStatus: 'Critical'
	}
]

export const lgvPlsSensorAnnotations = [
	{
		label: 'Front PLS',
		type: 'dirty',
		haveAlarm: true
	},
	{
		label: 'PLS Lateral Right',
		type: 'clean',
		haveAlarm: true
	},
	{
		label: 'PLS Lateral Left',
		type: 'clean'
	},
	{
		label: 'PLS Lateral Right Vertical',
		type: 'clean'
	},
	{
		label: 'PLS Lateral Left Vertical',
		type: 'clean'
	},
	{
		label: 'Rear PLS',
		type: 'clean',
		haveAlarm: true
	}
]
