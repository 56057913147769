import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  TextField,
} from "@mui/material";
import moment from "moment";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { formatDate } from "../Helpers/utils";
import { Range } from "./helpers/interfaces";
import { TimeSlider as useStyles } from "./helpers/styles";
import { LGVHealthContext } from "../Component/Context";
import { getFormattedRange } from "./helpers/utils";

type Granularity = {
  label: string;
  duration: moment.Duration;
};

type Mode = "add" | "subtract";

const granularitiesAvailable: Granularity[] = [
  {
    label: "1 Sec",
    duration: moment.duration({ seconds: 1 }),
  },
  {
    label: "2 Sec",
    duration: moment.duration({ seconds: 2 }),
  },
  {
    label: "5 Sec",
    duration: moment.duration({ seconds: 5 }),
  },
  {
    label: "30 Sec",
    duration: moment.duration({ seconds: 30 }),
  },
  {
    label: "1 Min",
    duration: moment.duration({ minutes: 1 }),
  },
  {
    label: "1 Hour",
    duration: moment.duration({ hours: 1 }),
  },
];

const momentMin = (date1: moment.Moment, date2: moment.Moment): moment.Moment =>
  date1.isBefore(date2) ? date1 : date2;

const momentMax = (date1: moment.Moment, date2: moment.Moment): moment.Moment =>
  date1.isAfter(date2) ? date1 : date2;

interface TimeSliderProps {
  marksCount?: number;
  startDate: Date;
  endDate: Date;
  selectedRange: Range;
  setSelectedRange: Dispatch<SetStateAction<Range>>;
}

const TimeSlider: FC<TimeSliderProps> = ({
  marksCount = 12,
  startDate,
  endDate,
  selectedRange,
  setSelectedRange,
}) => {
  const classes = useStyles();
  const [granularityIndex, setGranularityIndex] = useState<number>(0);
  const { selectedPlant } = useContext(LGVHealthContext);

  const granularity = granularitiesAvailable[granularityIndex];

  const lowerBound = useMemo<moment.Moment>(
    () => moment(startDate),
    [startDate]
  );

  const upperBound = useMemo<moment.Moment>(
    () => moment(endDate).subtract(granularity.duration),
    [endDate, granularity]
  );

  // Number of ticks on the slider
  const ticks = useMemo(() => {
    const diff = moment(endDate).diff(startDate, "s");
    return diff / granularity.duration.asSeconds();
  }, [endDate, startDate, granularity]);

  const sliderValue = Math.round(
    selectedRange.start.diff(startDate, "s") / granularity.duration.asSeconds()
  );

  const marks = useMemo(() => {
    const tickInterval = 100 / (marksCount - 1);
    const marks = [];

    for (let i = 0; i < marksCount; i++) {
      const date = new Date(
        startDate.getTime() +
          (i * tickInterval * (endDate.getTime() - startDate.getTime())) / 100
      );
      marks.push(formatDate(date, selectedPlant.plant_timezone));
    }

    return marks;
  }, [startDate, endDate]);

  const handleRangeChange = (mode: Mode) => () => {
    const newDate = selectedRange.start[mode](granularity.duration);

    const newStart = momentMin(
      momentMax(newDate, lowerBound),
      upperBound
    ).clone();

    const newEnd = newStart.clone().add(granularity.duration);

    setSelectedRange({
      start: newStart,
      end: newEnd,
    });
  };

  const handleGranularityChange = (event: SelectChangeEvent<number>) => {
    const newGranularityIndex = event.target.value as number;
    const newGranularity = granularitiesAvailable[newGranularityIndex];

    setSelectedRange({
      start: selectedRange.start,
      end: selectedRange.start.clone().add(newGranularity.duration),
    });

    setGranularityIndex(newGranularityIndex);
  };

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    newValue = newValue as number;

    const diff = newValue - sliderValue;

    setSelectedRange({
      start: selectedRange.start.add(
        granularity.duration.asSeconds() * diff,
        "s"
      ),
      end: selectedRange.end.add(granularity.duration.asSeconds() * diff, "s"),
    });
  };

  return ticks ? (
    <div className={classes.root}>
      <Box className={classes.container}>
        <TextField
          disabled
          style={{ marginRight: "1rem", width: "100%" }}
          label="Selected Range"
          value={getFormattedRange(selectedRange, selectedPlant.plant_timezone)}
        />
        <FormControl
          margin="none"
          variant="outlined"
          style={{ minWidth: 150 }}
          size="medium"
        >
          <InputLabel className={classes.inputFieldLabelPosition}>
            Select Granularity
          </InputLabel>
          <Select onChange={handleGranularityChange} value={granularityIndex}>
            {granularitiesAvailable.map(({ label }, index) => (
              <MenuItem key={index} value={index}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom={"2rem"}
      >
        <Button
          sx={{ width: 20, marginRight: 4 }}
          onClick={handleRangeChange("subtract")}
          disabled={selectedRange.start.isSame(lowerBound)}
        >
          <ArrowBackIcon fontSize="large" />
        </Button>

        <Slider
          value={sliderValue}
          onChange={handleSliderChange}
          color="secondary"
          className={classes.slider}
          max={ticks - 1}
        />

        <Button
          sx={{ width: 20, marginLeft: 4 }}
          onClick={handleRangeChange("add")}
          disabled={selectedRange.start.isSame(upperBound)}
        >
          <ArrowForwardIcon fontSize="large" />
        </Button>
      </Box>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "8px",
        }}
      >
        {marks.map((label: string, index: number) => (
          <div key={index} style={{ fontSize: "12px" }}>
            {label}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default TimeSlider;
