import {
  LOAD_SENSORS,
  LOAD_SENSORS_SUCCESS,
  LOAD_SENSORS_ERROR,
} from "../Types/sensors.constants";

const INITIAL_STATE = {
  loading: false,
  sensors: [],
  error: null,
};

export const sensorListReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_SENSORS:
      return { ...state, loading: true, sensors: [] };
    case LOAD_SENSORS_SUCCESS:
      return {
        ...state,
        loading: false,
        sensors: action.payload,
      };
    case LOAD_SENSORS_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
