export const LOAD_MESSAGES = "LOAD_MESSAGES";
export const LOAD_MESSAGES_SUCCESS = "LOAD_MESSAGES_SUCCESS";
export const LOAD_MESSAGES_ERROR = "LOAD_MESSAGES_ERROR";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS";
export const ADD_MESSAGE_ERROR = "ADD_MESSAGE_ERROR";
export const UPDATE_MESSAGE_PAGINATION = "UPDATE_MESSAGE_PAGINATION";
export const UPDATE_MESSAGE_PAGINATION_SUCCESS =
  "UPDATE_MESSAGE_PAGINATION_SUCCESS";
export const UPDATE_MESSAGE_PAGINATION_ERROR =
  "UPDATE_MESSAGE_PAGINATION_ERROR";
export const LOAD_ALL_MESSAGES = "LOAD_ALL_MESSAGES";
export const LOAD_ALL_MESSAGES_SUCCESS = "LOAD_ALL_MESSAGES_SUCCESS";
export const LOAD_ALL_MESSAGES_ERROR = "LOAD_ALL_MESSAGES_ERROR";
export const UPDATE_MESSAGE_FILTER_PARAMS = "UPDATE_MESSAGE_FILTER_PARAMS";
export const UPDATE_MESSAGE_FILTER_PARAMS_SUCCESS =
  "UPDATE_MESSAGE_FILTER_PARAMS_SUCCESS";
export const UPDATE_MESSAGE_FILTER_PARAMS_ERROR =
  "UPDATE_MESSAGE_FILTER_PARAMS_ERROR";
