import moment from "moment";
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { defaultPlantCodes } from "../Constants";
import { Plant } from "../Helpers/interfaces";
import { AppContext } from "../../../../../App";

type UseCaseType =
  | "navQuality"
  | "plsSensor"
  | "hydraulic"
  | "inverter"
  | "battery";

export interface LGVHealthContextValue {
  cubeName: string;
  setCubeName: Dispatch<SetStateAction<string>>;
  correspondingCubes: any;
  setCorrespondingCubes: Dispatch<SetStateAction<any>>;
  selectedPlant: Plant;
  setSelectedPlant: Dispatch<SetStateAction<any>>;
  dateRange: string[];
  setDateRange: Dispatch<SetStateAction<any>>;
  useCase: UseCaseType;
}

export const LGVHealthContext = createContext<LGVHealthContextValue>({
  cubeName: "",
  setCubeName: () => undefined,
  correspondingCubes: [],
  setCorrespondingCubes: () => undefined,
  selectedPlant: {} as Plant,
  setSelectedPlant: () => undefined,
  dateRange: [],
  setDateRange: () => undefined,
  useCase: "navQuality",
});

interface LGVHealthContextProviderProps {
  children: ReactNode;
  useCase: UseCaseType;
}

const LGVHealthContextProvider: FC<LGVHealthContextProviderProps> = ({
  children,
  useCase,
}) => {
  const appContextProps: any = useContext(AppContext); //consuming context created in App.tsx
  const plantsList = useSelector((state: any) => state.plantsList.plants);
  const defaultPlantCode: string = defaultPlantCodes[useCase];
  const defaultPlant: Plant = useMemo(() => {
    const plant = plantsList.find(
      (plant: Plant) => plant.plant_code === defaultPlantCode
    );
    appContextProps?.setSelectedPlantForCube(plant?.plant_code);
    // if (plant && defaultPlantCode === 'TAC') {
    return {
      ...plant,
      plant_code: plant?.plant_code + "LGV",
    };
    // }
    return plant;
  }, [defaultPlantCode, plantsList]);

  const [cubeName, setCubeName] = useState<string>("");
  const [correspondingCubes, setCorrespondingCubes] = useState<any>([]);
  const [selectedPlant, setSelectedPlant] = useState<Plant>(defaultPlant);
  const [dateRange, setDateRange] = useState<string[]>([
    moment.utc().subtract(24, "hours").format("YYYY-MM-DDTHH:mm:ss.000"),
    moment.utc().format("YYYY-MM-DDTHH:mm:ss.000"),
  ]);

  useEffect(() => {
    const plantCode = selectedPlant?.plant_code;
    if (plantCode && !plantCode.includes("LGV")) {
      setSelectedPlant({
        ...selectedPlant,
        plant_code: plantCode + "LGV",
      });
    }
  }, [selectedPlant, setSelectedPlant]);

  useEffect(() => {
    setSelectedPlant(defaultPlant);
  }, [defaultPlant]);

  return selectedPlant ? (
    <LGVHealthContext.Provider
      value={{
        cubeName,
        setCubeName,
        correspondingCubes,
        setCorrespondingCubes,
        selectedPlant,
        setSelectedPlant,
        dateRange,
        setDateRange,
        useCase,
      }}
    >
      {children}
    </LGVHealthContext.Provider>
  ) : (
    <></>
  );
};

export default LGVHealthContextProvider;
