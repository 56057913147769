import {
  LOAD_ALIAS,
  LOAD_ALIAS_SUCCESS,
  LOAD_ALIAS_ERROR,
} from "../Types/alias.constants";

// import axios from "axios";
import axios from "../../Utils/AxiosConfig/axiosConfigMonarch";
import { put, call, takeEvery } from "redux-saga/effects";

//function to get all plants from the api
const getAlias = async () => {
  let alias: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  res = await axios.get(`aliasviews`);
  alias = res.data;
  return alias;
};

export function* listAlias(): any {
  try {
    const data = yield call(() =>
      getAlias().then((res) => {
        return res;
      })
    );
    yield put({
      type: LOAD_ALIAS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // console.error(error.response);
    yield put({
      type: LOAD_ALIAS_ERROR,
      payload: null,
    });
  }
}

export function* listAliasSaga() {
  yield takeEvery(LOAD_ALIAS, listAlias);
}
