import React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { CircularProgress } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import { Plant, Asset } from "../../models/Types_Interfaces";
import { useNavigate } from "react-router-dom";
import {
  getFormatedDate,
  getTimeZoneTimeStamp,
} from "../../Utils/UtilFunctions/timeZoneFormatter";
import { GREEN, ORANGE, RED, YELLOW } from "../../Utils/UtilFunctions/constant";

function RunHoursAlert(props: any) {
  const ticketsList = useSelector((state: any) => state.ticketsList);
  const plantsList = useSelector((state: any) => state.plantsList);
  const levelsList = useSelector((state: any) => state.levelsList);
  const messagesList = useSelector((state: any) => state.messagesList);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const OPERATING_HOURS_50 = "Operating Hours 50%";
  const OPERATING_HOURS_BEFORE_85 = "Operating Hours 24hr before 85%";
  const OPERATING_HOURS_85 = "Operating Hours 85%";
  const OPERATING_HOURS_100 = "Operating Hours 100%";
  const OPERATING_HOURS_120 = "Operating Hours 120%";

  return (
    <Modal open={props.open} onClose={props.handleClose} closeAfterTransition>
      <Fade in={props.open}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: window?.innerWidth < 400 ? "300px" : "550px",
            boxShadow: "24",
            padding: "16px 40px",
            color: "white",
            borderRadius: "12px",
            backgroundColor:
              props.alert.message_data.AlertSubType === OPERATING_HOURS_50
                ? YELLOW
                : props.alert.message_data.AlertSubType ===
                  OPERATING_HOURS_BEFORE_85
                ? ORANGE
                : props.alert.message_data.AlertSubType === OPERATING_HOURS_85
                ? GREEN
                : RED,
            border:
              props.alert.message_data.AlertSubType === OPERATING_HOURS_50
                ? `3px solid ${YELLOW}`
                : props.alert.message_data.AlertSubType ===
                  OPERATING_HOURS_BEFORE_85
                ? `3px solid ${ORANGE}`
                : props.alert.message_data.AlertSubType === OPERATING_HOURS_85
                ? `3px solid ${GREEN}`
                : `3px solid ${RED}`,
          }}
        >
          <div
            style={{
              marginTop: "20px",
            }}
          >
            {props.alert.message_data.AlertSubType === OPERATING_HOURS_50 ? (
              <InfoIcon sx={{ fontSize: "60px" }} />
            ) : props.alert.message_data.AlertSubType ===
              OPERATING_HOURS_BEFORE_85 ? (
              <WarningIcon sx={{ fontSize: "60px" }} />
            ) : props.alert.message_data.AlertSubType === OPERATING_HOURS_85 ? (
              <CheckCircleIcon sx={{ fontSize: "60px" }} />
            ) : (
              <CancelIcon sx={{ fontSize: "60px" }} />
            )}
          </div>

          <div
            style={{
              marginBottom: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h5 style={{ fontSize: "1.2rem" }}>
              {plantsList.plants.find((plant: Plant) => {
                return plant._id === props.alert.plant_id;
              }) !== undefined
                ? plantsList.plants.find((plant: Plant) => {
                    return plant._id === props.alert.plant_id;
                  }).plant_name
                : "Plant not found"}
            </h5>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                color: "black",
                padding: "12px",
                borderRadius: "3px",
                boxShadow: "1px 1px 0px 0px #000000",
                width: "100%",
                margin: "5px",
              }}
            >
              <h5
                style={{
                  fontWeight: "800",
                  color: "#000",
                  textAlign: "left",
                  fontSize: "12px",
                }}
              >
                Line Name
              </h5>

              <p style={{ textAlign: "left", fontSize: "12px" }}>
                {props.alert.message_data !== undefined
                  ? props.alert.message_data?.LINE
                  : "Line Not Found"}
              </p>
            </div>

            {/* <div
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                color: "black",
                padding: "12px",
                borderRadius: "3px",
                boxShadow: "1px 1px 0px 0px #000000",
                width: "98%",
                margin: "5px",
              }}
            >
              <h5
                style={{
                  fontWeight: "800",
                  color: "#000",
                  textAlign: "left",
                  fontSize: "12px",
                }}
              >
                Health Status
              </h5>

              <p style={{ textAlign: "left", fontSize: "12px" }}>
                {props.alert.HLEnableFlag === false ||
                props.alert.HLEnableFlag === "false"
                  ? "Alarm"
                  : props.alert.message_data.AlertSubType === OPERATING_HOURS_50
                  ? "Alert"
                  : props.alert.message_data.AlertSubType ===
                    OPERATING_HOURS_BEFORE_85
                  ? "Monitor"
                  : props.alert.message_data.AlertSubType === OPERATING_HOURS_85
                  ? "Acceptable"
                  : "Danger"}
              </p>
            </div> */}
          </div>

          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              color: "black",
              padding: "12px",
              borderRadius: "3px",
              boxShadow: "1px 1px 0px 0px #000000",
              width: "98%",
              margin: "5px",
            }}
          >
            <h5
              style={{
                fontWeight: "800",
                color: "#000",
                fontSize: "12px",
              }}
            >
              Description
            </h5>

            <p>
              {props.alert.message_data !== undefined &&
                `${
                  props.alert.message_data.ProjectedReleaseDate !== undefined &&
                  props.alert.message_data.ProjectedReleaseDate !== null &&
                  getFormatedDate(
                    plantsList.plants.find((plant: any) => {
                      return plant._id === props.alert.plant_id;
                    })?.plant_timezone,
                    getTimeZoneTimeStamp(
                      plantsList.plants.find((plant: any) => {
                        return plant._id === props.alert.plant_id;
                      })?.plant_timezone,
                      props.alert.message_data.ProjectedReleaseDate
                    ),
                    "MM/DD/yyyy"
                  )
                }: ${
                  props.alert.message_data?.AlertSubType === OPERATING_HOURS_50
                    ? "PM's scheduled to release"
                    : props.alert.message_data?.AlertSubType ===
                      OPERATING_HOURS_BEFORE_85
                    ? "PM's scheduled to release"
                    : props.alert.message_data?.AlertSubType ===
                      OPERATING_HOURS_85
                    ? "PM's are released"
                    : props.alert.message_data?.AlertSubType ===
                      OPERATING_HOURS_100
                    ? "PM's are due"
                    : `${
                        props.alert.message_data !== undefined
                          ? props.alert.message_data?.LINE
                          : "Line Not Found"
                      } is overdue for a preventative maintenance, please take the necessary actions to schedule and perform a PM.`
                }`}
            </p>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <button
              style={{
                color: "black",
                backgroundColor: "white",
                marginLeft: "2em",
                margin: "20px 5px",
                width: "45%",
                padding: "8px",
                borderRadius: "8px",
                fontWeight: "bold",
              }}
              onClick={() => {
                props.handleClose();
              }}
            >
              Close
            </button>

            {props.alert.message_data.AlertSubType === OPERATING_HOURS_120 ? (
              <button
                style={{
                  color: "black",
                  backgroundColor: "white",
                  marginLeft: "2em",
                  margin: "20px 5px",
                  width: "45%",
                  padding: "8px",
                  borderRadius: "8px",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  props.setOpen(false);
                  navigate(
                    `/maintenance/ticket-details/${props.alert.ticket_id}/${props.alert.message_id}`,
                    {
                      replace: true,
                    }
                  );
                }}
                disabled={ticketsList.loading || messagesList.loading}
              >
                {(ticketsList.loading || messagesList.loading) && (
                  <CircularProgress
                    sx={{
                      color: "black",
                      height: "1px",
                      margin: "0px 20px 0px 10px",
                    }}
                    size={18}
                  />
                )}
                Go to Ticket
              </button>
            ) : null}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default RunHoursAlert;
