import { FC, ReactNode } from "react";
import { experimentalStyled } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { Outlet } from "react-router-dom";
import Sidebar from "../../componets/Sidebar";
import Header from "../../componets/Header";

interface SidebarWithHeaderLayoutProps {
  children?: ReactNode;
}

const MainWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: ${theme.sidebar.width};
        }
`
);

const MainContent = experimentalStyled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
`
);

const SidebarWithHeaderLayout: FC<SidebarWithHeaderLayoutProps> = () => {
  return (
    <>
      <Sidebar />
      <MainWrapper>
        <Header />
        <MainContent>
          <Outlet />
        </MainContent>
      </MainWrapper>
    </>
  );
};

export default SidebarWithHeaderLayout;
