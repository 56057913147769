import {
  LOAD_ALIAS,
  LOAD_ALIAS_SUCCESS,
  LOAD_ALIAS_ERROR,
} from "../Types/alias.constants";

const INITIAL_STATE = {
  loading: false,
  alias: [],
  error: null,
};

export const aliasListReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_ALIAS:
      return { ...state, loading: true, alias: [] };
    case LOAD_ALIAS_SUCCESS:
      return {
        ...state,
        loading: false,
        alias: action.payload,
      };
    case LOAD_ALIAS_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
