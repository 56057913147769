import {
  LOAD_VISUALS,
  LOAD_VISUALS_SUCCESS,
  LOAD_VISUALS_ERROR,
} from "../Types/visuals.constants";

// import axios from "axios";
import axios from "../../Utils/AxiosConfig/axiosConfigMonarch";
import { put, call, takeEvery } from "redux-saga/effects";

//function to get all plants from the api
const getVisuals = async () => {
  let visuals: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  res = await axios.get(`visualbuilders`);
  visuals = res.data;
  return visuals;
};

export function* listVisuals(): any {
  try {
    const data = yield call(() =>
      getVisuals().then((res) => {
        return res;
      })
    );
    yield put({
      type: LOAD_VISUALS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // console.error(error.response);
    yield put({
      type: LOAD_VISUALS_ERROR,
      payload: null,
    });
  }
}

export function* listVisualsSaga() {
  yield takeEvery(LOAD_VISUALS, listVisuals);
}
