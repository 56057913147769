import {
  useMemo,
  useContext,
  useCallback,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Cube, CubejsApi } from "@cubejs-client/core";
import cubeQuery from "./cubeQueries";
import {
  NAV_QUALITY_HISTORICAL,
  DEFAULT_SNACKBAR_STATE,
  NAV_QUALITY_HISTORICAL_CUBE,
  DATA_NOT_FOUND,
} from "../Constants";
import { SingleLgv, HeatmapRespData } from "./interfaces";
import { transformData, transformToHeatMapData } from "./utils";
import { CubeContext, useCubeMeta } from "@cubejs-client/react";
import { useDispatch } from "react-redux";
import { flow, groupBy } from "lodash";
import { defaultErrorAction } from "../../../../../Utils/util";
import { useLoader } from "../../../../../Utils/hooks";
import moment from "moment";
import Axios from "../../../../../Utils/AxiosConfig/axiosConfig";
import { UseCustomApiCallProps } from "./interfaces";

const USE_REAL_DATA = true;

interface LoadSnackbarAction {
  type: string;
  payload: {
    message: string;
    type: string;
    open: boolean;
  };
}

export const getDataFromCube = async (
  query: any,
  cubejsApi: CubejsApi,
  dispatch: Dispatch<LoadSnackbarAction>
) => {
  try {
    const resultSet = await cubejsApi.load(query);
    const fetchedData = resultSet
      .serialize()
      .loadResponse.results.map(({ data }: any) => data);
    return fetchedData;
  } catch (err) {
    dispatch(defaultErrorAction(DEFAULT_SNACKBAR_STATE.message));
  }
};

const groupByLGVId = (plantLayoutLgvRecords: any) =>
  groupBy(plantLayoutLgvRecords, "lgvId");

interface HandleCubeFetchParams {
  startTimeDate: Date;
  endTimeDate: Date;
  setAllLgvData: Dispatch<SetStateAction<{ [key: number]: SingleLgv }[]>>;
  setHeatMapData: Dispatch<SetStateAction<any[]>>;
  setPlantLayoutLgvRecords?: Dispatch<
    SetStateAction<{ [lgvId: string]: HeatmapRespData[] }> //DynamicPlantLayoutData
  >;
}

export const useCubeFetch = () => {
  const metaCubeNames = useCubeMeta();
  const dispatch = useDispatch();
  const cubejsApi: CubejsApi = useContext(CubeContext).cubejsApi;

  const { startLoading, endLoading, isLoading } = useLoader();

  const correspondingCube = useMemo(() => {
    if (metaCubeNames.response) {
      return metaCubeNames.response.cubes.find(
        (cube: Cube) => cube.name === NAV_QUALITY_HISTORICAL_CUBE
      );
    }
  }, [metaCubeNames.response]);

  const cubeFetch = useCallback(
    ({
      startTimeDate,
      endTimeDate,
      setAllLgvData,
      setHeatMapData,
      setPlantLayoutLgvRecords = () => null,
    }: HandleCubeFetchParams) => {
      if (!USE_REAL_DATA) return;

      const cubeQueryCalls = async () => {
        startLoading();

        try {
          if (!correspondingCube) return;

          const cubeName = correspondingCube.name;

          if (startTimeDate === undefined || endTimeDate === undefined) return;

          const query = cubeQuery(
            NAV_QUALITY_HISTORICAL,
            cubeName,
            startTimeDate.toISOString(),
            endTimeDate.toISOString()
          );

          const [response]: any = await getDataFromCube(
            query,
            cubejsApi,
            dispatch
          );

          if (!response.length)
            return dispatch(defaultErrorAction(DATA_NOT_FOUND));

          const handleAllLgvData = flow(transformData, setAllLgvData);
          handleAllLgvData(response);

          setHeatMapData(transformToHeatMapData(response));

          if (!setPlantLayoutLgvRecords) return;

          const handlePlantLayoutLgvRecords = flow(
            transformToHeatMapData,
            groupByLGVId,
            setPlantLayoutLgvRecords
          );

          handlePlantLayoutLgvRecords(response);
        } catch (error) {
          dispatch(defaultErrorAction(DEFAULT_SNACKBAR_STATE.message));
        }

        endLoading();
      };

      cubeQueryCalls();
    },
    [correspondingCube, dispatch, cubejsApi]
  );

  return { cubeFetch, isLoading };
};

type useLiveDataDelayProps = {
  canUpdateData: boolean;
  setCanUpdateData: React.Dispatch<React.SetStateAction<boolean>>;
  callback: () => void;
};

export const useLiveDataDelay = ({
  canUpdateData,
  setCanUpdateData,
  callback,
}: useLiveDataDelayProps) => {
  useEffect(() => {
    let timeout: NodeJS.Timeout | number;
    if (!canUpdateData) {
      timeout = setTimeout(() => {
        setCanUpdateData(true);
      }, 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [canUpdateData, setCanUpdateData]);

  useEffect(() => {
    if (canUpdateData) {
      callback();
      setCanUpdateData(false);
    }
  }, [canUpdateData, callback, setCanUpdateData]);
};

export const useCustomApiCall = ({
  apiUrl,
  startDateOffset,
  endDate,
  plantCode,
  lgvId,
  processResponse,
}: UseCustomApiCallProps) => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      // Calculate the start date
      const startTimeDate = moment()
        .utc()
        .subtract(startDateOffset, "days")
        .format("YYYY-MM-DD");

      // Perform the API call
      let apiURL = `${apiUrl}?startDate=${startTimeDate}&endDate=${endDate}&whse=${plantCode}`;
      if (lgvId) {
        apiURL = apiURL + `&lgvId=${lgvId}`;
      }
      const apiResponse = await Axios.get(apiURL);
      const response = apiResponse.data;

      // Process the response data
      if (response?.data?.length > 0) {
        setData(processResponse(response));
      }
    } catch (error) {
      dispatch(
        defaultErrorAction(
          "Something went wrong, failed to fetch API Response!"
        )
      );
    } finally {
      setLoading(false);
    }
  }, [apiUrl, startDateOffset, plantCode, dispatch]);

  return { loading, data, fetchData };
};
