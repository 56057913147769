import { makeStyles } from "@mui/styles";
import { Theme } from "@material-ui/core";
export const dataTableStyles = makeStyles((theme: Theme) => ({
  progressSpinner: {
    marginTop: window.innerHeight * 0.25,
    marginBottom: window.innerHeight * 0.2,
    marginLeft: window.innerWidth * 0.4,
  },

  tablePointer: {
    "&.MuiTypography-root": {
      cursor: "pointer",
    },
  },

  iconColor: {
    "&.MuiIconButton-root": {
      color: "#1976d2",
    },
  },
  iconFilterButtonBg: {
    "&.MuiIconButton-root": {
      color: "#1976d2",
      backgroundColor: "#E5E7F1",
    },
  },

  iconFilterButtonNonBg: {
    "&.MuiIconButton-root": {
      color: "#1976d2",
      backgroundColor: "transparent",
    },
  },

  tableBodyTypography: {
    "&.MuiTypography-root": {
      fontWeight: 500,
      fontSize: "14px",
      color: "#FFF",
    },
  },
  tableHeadTypography: {
    "&.MuiTableCell-root": {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "31px",
      color: "#E6E1E5",
      textTransform: "capitalize",
    },
  },
  cardBg: {
    "&.MuiCard-root": {
      background: "#293368",
      padding: "20px 0px 25px 0px",
    },
  },

  actionIcon: {
    justifyContent: "space-between",
    cursor: "pointer",
  },
  hoverCursor: {
    cursor: "pointer",
  },
}));
