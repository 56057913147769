import {
  LOAD_LEVELS,
  LOAD_LEVELS_SUCCESS,
  LOAD_LEVELS_ERROR,
  UPDATE_LEVEL,
  UPDATE_LEVEL_SUCCESS,
  DELETE_LEVEL,
  DELETE_LEVEL_SUCCESS,
} from "../Types/levels.constants";

const INITIAL_STATE = {
  loading: false,
  levels: [],
  uniqueUsecases: [],
  error: null,
};

export const levelsListReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_LEVELS:
      return { ...state, loading: true, levels: [] };
    case LOAD_LEVELS_SUCCESS:
      return {
        ...state,
        loading: false,
        levels: action.payload.levels,
        uniqueUsecases: action.payload.uniqueUsecaseCollection,
      };
    case UPDATE_LEVEL:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        levels: Array.isArray(action.payload)
          ? [...state.levels, ...action.payload]
          : [
              ...state.levels.filter((ele: any) => {
                return ele._id !== action.payload._id;
              }),
              action.payload,
            ],
      };
    case DELETE_LEVEL:
      return {
        ...state,
        loading: true,
      };
    case DELETE_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        levels: state.levels.filter((ele: any) => {
          return ele._id !== action.payload._id;
        }),
      };
    case LOAD_LEVELS_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
