import React, { useState } from 'react'
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	CircularProgress
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import LoadingButton from '@mui/lab/LoadingButton'

interface Props {
	open: boolean
	onClose: () => void
	handleDeleteCancel: () => void
	handleDeleteConfirm: () => void
	title: string
	description: string
	button1: string
	button2: string
}

const DeleteAnnotation: React.FC<Props> = ({
	open,
	onClose,
	handleDeleteCancel,
	handleDeleteConfirm,
	title,
	description,
	button1,
	button2
}) => {
	const [loader, setLoader] = useState(!open)

	return (
		<Dialog
			open={open}
			onClose={onClose}
			PaperProps={{
				style: {
					height: '200px'
				}
			}}
		>
			<Grid container justifyContent='center'>
				<DeleteIcon sx={{ color: 'red', fontSize: 40 }} />
			</Grid>
			<DialogTitle sx={{ textAlign: 'center', fontSize: 24 }}>
				{title}
				<DialogContent
					sx={{
						textAlign: 'center',
						paddingTop: '10px',
						paddingBottom: '10px',
						fontSize: 12
					}}
				>
					{description}
				</DialogContent>
			</DialogTitle>
			<Grid container justifyContent='center' spacing={2}>
				<Grid item>
					<Button variant='outlined' onClick={handleDeleteCancel}>
						{button1}
					</Button>
				</Grid>
				<Grid item>
					<LoadingButton
						variant='contained'
						onClick={() => {
							setLoader(true)
							if (typeof handleDeleteConfirm === 'function') {
								handleDeleteConfirm()
							}
						}}
						autoFocus
						loading={loader}
						loadingIndicator={
							<CircularProgress size={20} color={'secondary'} />
						}
						style={{ background: 'red', backgroundColor: 'red' }}
					>
						{button2}
					</LoadingButton>
				</Grid>
			</Grid>
		</Dialog>
	)
}

export default DeleteAnnotation
