import {
    VIEW_ASSET,
    VIEW_ASSET_SUCCESS,
    VIEW_ASSET_ERROR,
    SCHEDULER_CURRENT_DATE,
    SCHEDULER_CURRENT_DATE_SUCCESS,
    SCHEDULER_CURRENT_DATE_ERROR,
    SCHEDULER_CURRENT_VIEW,
    SCHEDULER_CURRENT_VIEW_SUCCESS,
    SCHEDULER_CURRENT_VIEW_ERROR,
    SCHEDULER_FILTER_DATA,
    SCHEDULER_FILTER_DATA_SUCCESS,
    SCHEDULER_FILTER_DATA_ERROR,
  } from "../Types/schdeuler.constant";
  
  import { put, takeEvery } from "redux-saga/effects";
  
  export function* viewAssetDetaiils(action: any): any {
    try {
      yield put({
        type: VIEW_ASSET_SUCCESS,
        payload: action.payload,
      });
    } catch (error) {
      //  / console.error(error.response);
      yield put({
        type: VIEW_ASSET_ERROR,
        payload: null,
      });
    }
  }
  
  export function* schedulerCurrentDate(action: any): any {
    try {
      yield put({
        type: SCHEDULER_CURRENT_DATE_SUCCESS,
        payload: action.payload,
      });
    } catch (error) {
      //  / console.error(error.response);
      yield put({
        type: SCHEDULER_CURRENT_DATE_ERROR,
        payload: null,
      });
    }
  }
  
  export function* schedulerCurrentView(action: any): any {
    try {
      yield put({
        type: SCHEDULER_CURRENT_VIEW_SUCCESS,
        payload: action.payload,
      });
    } catch (error) {
      //  / console.error(error.response);
      yield put({
        type: SCHEDULER_CURRENT_VIEW_ERROR,
        payload: null,
      });
    }
  }
  
  export function* schedulerFilter(action: any): any {
    try {
      yield put({
        type: SCHEDULER_FILTER_DATA_SUCCESS,
        payload: action.payload,
      });
    } catch (error) {
      //  / console.error(error.response);
      yield put({
        type: SCHEDULER_FILTER_DATA_ERROR,
        payload: null,
      });
    }
  }
  
  export function* schedulerSaga() {
    yield takeEvery(VIEW_ASSET, viewAssetDetaiils);
    yield takeEvery(SCHEDULER_CURRENT_DATE, schedulerCurrentDate);
    yield takeEvery(SCHEDULER_CURRENT_VIEW, schedulerCurrentView);
    yield takeEvery(SCHEDULER_FILTER_DATA, schedulerFilter);
  }
  