import { HubConnectionBuilder } from "@microsoft/signalr";
import { env } from "../env";

export default class SignalRConnection {
  public static connectToSignalR = () => {
    let connection = new HubConnectionBuilder()
      .withUrl(env.signalrConnectionUrl)
      .withAutomaticReconnect()
      .build();
    return connection;
  };
}
