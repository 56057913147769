import {
  LOAD_USERS,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_ERROR,
  SET_CURRENT_USER,
  SET_CURRENT_USER_SUCCESS,
  SET_CURRENT_USER_IMAGE,
  SET_CURRENT_USER_IMAGE_SUCCESS,
  SET_CURRENT_USER_PERMISSIONS,
  SET_CURRENT_USER_PERMISSIONS_SUCCESS,
  SET_CURRENT_ACCOUNT_PERMISSIONS,
  SET_CURRENT_ACCOUNT_PERMISSIONS_SUCCESS,
  SET_ACCESSIBLE_PLANTS,
  SET_ACCESSIBLE_PLANTS_SUCCESS,
  STORE_ALL_USERS_IMAGE,
  STORE_ALL_USERS_IMAGE_SUCCESS,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER,
  DELETE_USER,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_SUCCESS,
  UPDATE_CURRENT_USER_ERROR,
  SET_SCM_USER_PLANT,
  SET_SCM_USER_PLANT_SUCCESS,
  SET_SCM_USER_PLANT_ERROR,
  LOAD_PENDING_APPROVAL_USERS,
  LOAD_PENDING_APPROVAL_USERS_SUCCESS,
  LOAD_PENDING_APPROVAL_USERS_ERROR
} from "../Types/users.constants";

// import axios from "axios";
import axios from "../../Utils/AxiosConfig/axiosConfig";
import { put, call, takeEvery } from "redux-saga/effects";
import { LOAD_SNACKBAR } from "../Types/snackBar.constants";
import { SetMultipleUserImage } from "../../pages/master/User/AllUsersProfilePicture";
import { returnAccessToken } from "../../Services/authService";
import { LOAD_ALL_USERS_IMAGE_SUCCESS } from "../Types/userImages.constants";

//function to get all users from the api
const getUsers = async () => {
  let users: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`users/all-users?pageSize=1000&page=${page++}`);
    users = users.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);
  return users;
};

const getPendingApprovalUsers = async () => {
  let users: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`accessRequests/approved?pageSize=100&page=${page++}`);
    users = users.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);
  return users;
};

// function to get all users image from graph
const getUsersImage = async (users: any, accessToken: any) => {
  const result = await Promise.all(
    users.map(async (data: any) => {
      const response = await SetMultipleUserImage(data.user_ad_id, accessToken);
      return { id: data.user_ad_id, image: response };
    })
  );
  return result;
};

export function* listUsers(action: any): any {
  try {
    const data = yield call(() =>
      getUsers().then((res) => {
        return res;
      })
    );

    yield put({
      type: LOAD_USERS_SUCCESS,
      payload: data,
    });

    const accessToken = yield call(() => returnAccessToken().then((res) => res));

    if (data) {
      const userImages = yield call(() => getUsersImage(data, accessToken).then((res) => res ));

      const result = userImages.map((e: any) => {
        let temp = data.find((element: any) => element.user_ad_id === e.id);
        if (temp) {
          temp.userImage = e.image;
        }
        return temp;
      });

      yield put({
        type: LOAD_ALL_USERS_IMAGE_SUCCESS,
        payload: result,
      });
    }

    if (action.payload && action.payload?.isShow) {
      const { message = "", type = "", open = false } = action?.payload;
      yield put({
        type: LOAD_SNACKBAR,
        payload: {
          message,
          type,
          open,
        },
      });
    }
  } catch (error) {
    //  / console.error(error.response);
    yield put({
      type: LOAD_USERS_ERROR,
      payload: null,
    });
  }
}

export function* listPendingApprovalUsers(action: any): any {
  try {
    const data = yield call(() =>
      getPendingApprovalUsers().then((res) => {
        return res;
      })
    );

    yield put({
      type: LOAD_PENDING_APPROVAL_USERS_SUCCESS,
      payload: data
    });

    if (action.payload && action.payload?.isShow) {
      const { message = "", type = "", open = false } = action?.payload;
      yield put({
        type: LOAD_SNACKBAR,
        payload: {
          message,
          type,
          open
        }
      });
    }
  } catch (error) {
    //  / console.error(error.response);
    yield put({
      type: LOAD_PENDING_APPROVAL_USERS_ERROR,
      payload: null
    });
  }
}

export function* storeUsersImage(action: any): any {
  try {
    // yield console.log("Set ALL User Image", action, action.payload);
    const users = action.payload;
    const acessToken = yield call(() =>
      returnAccessToken().then((res) => {
        return res;
      })
    );

    if (users) {
      const userImages = yield call(() =>
        getUsersImage(users, acessToken).then((res) => {
          return res;
        })
      );

      const result = userImages.map((e: any) => {
        let temp = users.find((element: any) => element.user_ad_id === e.id);
        if (temp) {
          temp.userImage = e.image;
        }
        return temp;
      });

      yield put({
        type: STORE_ALL_USERS_IMAGE_SUCCESS,
        payload: result,
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* setCurrentUser(action: any): any {
  yield put({
    type: SET_CURRENT_USER_SUCCESS,
    payload: action.payload,
  });
}

export function* setCurrentUserImage(action: any): any {
  yield put({
    type: SET_CURRENT_USER_IMAGE_SUCCESS,
    payload: action.payload,
  });
}

export function* setCurrentUserPermissions(action: any): any {
  yield put({
    type: SET_CURRENT_USER_PERMISSIONS_SUCCESS,
    payload: action.payload,
  });
}

export function* setCurrentAcountPermissions(action: any): any {
  yield put({
    type: SET_CURRENT_ACCOUNT_PERMISSIONS_SUCCESS,
    payload: action.payload,
  });
}

export function* setAccessiblePlants(action: any): any {
  yield put({
    type: SET_ACCESSIBLE_PLANTS_SUCCESS,
    payload: action.payload,
  });
}

export function* updateUser(action: any): any {
  try {
    yield put({
      type: UPDATE_USER_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    // console.error(error.response);
    yield put({
      type: UPDATE_USER_ERROR,
      payload: null,
    });
  }
}

export function* deleteUser(action: any): any {
  try {
    yield put({
      type: DELETE_USER_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    // console.error(error.response);
    yield put({
      type: DELETE_USER_ERROR,
      payload: null,
    });
  }
}

export function* updateCurrentUser(action: any): any {
  try {
    yield put({
      type: UPDATE_CURRENT_USER_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    // console.error(error.response);
    yield put({
      type: UPDATE_CURRENT_USER_ERROR,
      payload: null,
    });
  }
}

const getUserAccess = async () => {
  let res = null;
  res = await axios.get(`users/UserByADId`);
  return res.data.row[0];
};

export function* updateSCMPlantUserData(): any {
  try {
    const data = yield call(() =>
      getUserAccess().then((res) => {
        return res;
      })
    );

    yield put({
      type: SET_SCM_USER_PLANT_SUCCESS,
      payload: data.scm_user_plant_group ? data.scm_user_plant_group : [],
    });
  } catch (error) {
    yield put({
      type: SET_SCM_USER_PLANT_ERROR,
      payload: "",
    });
  }

  // let res = null;
  // res = await axios.get(`users/UserByADId`);
}

export function* listUsersSaga() {
  yield takeEvery(LOAD_USERS, listUsers);
  yield takeEvery(LOAD_PENDING_APPROVAL_USERS, listPendingApprovalUsers);
  yield takeEvery(SET_CURRENT_USER, setCurrentUser);
  yield takeEvery(SET_CURRENT_USER_IMAGE, setCurrentUserImage);
  yield takeEvery(SET_CURRENT_USER_PERMISSIONS, setCurrentUserPermissions);
  yield takeEvery(SET_CURRENT_ACCOUNT_PERMISSIONS, setCurrentAcountPermissions);
  yield takeEvery(SET_ACCESSIBLE_PLANTS, setAccessiblePlants);
  yield takeEvery(STORE_ALL_USERS_IMAGE, storeUsersImage);
  yield takeEvery(UPDATE_USER, updateUser);
  yield takeEvery(DELETE_USER, deleteUser);
  yield takeEvery(UPDATE_CURRENT_USER, updateCurrentUser);
  yield takeEvery(SET_SCM_USER_PLANT, updateSCMPlantUserData);
}
