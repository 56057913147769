import { ReactElement } from "react";
import { dashboardStyles } from "../DowntimeLandingPageStyles";
import { Autocomplete, TextField } from "@mui/material";

export interface AutoCompleteDropdownWithTextFieldInterface {
  options: Array<any>;
  value: any;
  handleChange(_event: any, newValue: any): void;
  placeholder: string;
  className?: any;
  icon?: any;
  defaultValue?: string;
  error?: boolean;
  errorText?: string;
  optionLabel?: string;
  readOnly?: boolean;
  showLabel?: boolean;
  label?: string;
  style?: React.CSSProperties;
}

const AutoCompleteDropdownWithTextField = (
  props: AutoCompleteDropdownWithTextFieldInterface
): ReactElement => {
  const classes = dashboardStyles();

  return (
    <Autocomplete
      className={props.className || classes.autoDropdown}
      options={props.options}
      disabled={props?.readOnly}
      getOptionLabel={(option: any) =>
        props.optionLabel ? option[props.optionLabel] : option.label
      }
      value={props.value}
      onChange={(_event: any, newValue: any) => {
        if (typeof props.handleChange === "function") {
          props.handleChange(_event, newValue);
        }
      }}
      popupIcon={props.icon ?? ""}
      renderInput={(params: any) => (
        <TextField
          {...params}
          placeholder={props.placeholder}
          error={props.error ?? false}
          helperText={props.error ? props.errorText : ""}
          label={props.showLabel ? props.label : ""}
        />
      )}
      disableClearable
    />
  );
};

export default AutoCompleteDropdownWithTextField;
