import React, { FC } from "react";
import { Typography, Button, Box, DialogTitle, Modal } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

interface OutOfFrameProps {
  open: boolean;
  onClose: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#232a58",
  boxShadow: "none",
  p: 4,
  borderRadius: "16px",
};

const OutOfFrame: FC<OutOfFrameProps> = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: "transparent",
        },
      }}
    >
      <Box sx={style}>
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginLeft: "auto", marginRight: "auto" }}
          >
            Out of Time Frame
          </Typography>
          <div
            style={{
              position: "absolute",
              top: "2px",
              right: "0",
              borderRadius: "35px",
              padding: "3px 7px",
              width: "40px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ClearOutlinedIcon
              onClick={onClose}
              style={{
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </div>
        </DialogTitle>

        <Typography
          style={{ textAlign: "center", padding: "10px 0", fontSize: 16 }}
          id="modal-modal-title"
        >
          Annotation is out of time frame.
        </Typography>
      </Box>
    </Modal>
  );
};

export default OutOfFrame;
