import {
  UPDATE_TAB_OPTIONS,
  DOWN_TIME_TICKET,
} from "../Types/downtime.constants";

const INITIAL_STATE = {
  tabOptions: {
    tabValue: 1,
    label: "NPI Downtime Tickets",
    category: "NPI",
  },
  ticketDetails: {},
};

export const downtimeReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case UPDATE_TAB_OPTIONS:
      return { ...state, tabOptions: action.payload };
    case DOWN_TIME_TICKET:
      return { ...state, ticketDetails: action.payload };

    default:
      return state;
  }
};
