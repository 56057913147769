import {
  LOAD_SATKPIDATA,
  LOAD_SATKPIDATA_SUCCESS,
  LOAD_SATKPIDATA_ERROR,
} from "../Types/satKpiData.constants";

const INITIAL_STATE = {
  loading: false,
  kpiData: {},
  error: null,
};

export const satKpiDataReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_SATKPIDATA:
      return { ...state, loading: true, kpiData: {} };
    case LOAD_SATKPIDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        kpiData: action.payload,
        error: false,
      };
    case LOAD_SATKPIDATA_ERROR: {
      return { ...state, loading: false, error: true, kpiData: null };
    }
    default:
      return state;
  }
};
