import {
  LOAD_USER_ACCESS,
  LOAD_USER_ACCESS_SUCCESS,
  LOAD_USER_ACCESS_ERROR,
} from "../Types/userAccess.constants";

import axios from "../../Utils/AxiosConfig/axiosConfig";
import { put, call, takeEvery } from "redux-saga/effects";
import store from "../store";
import {
  LOAD_TROUBLESHOOT_ASSET,
  LOAD_TROUBLESHOOT_ASSET_ERROR,
  LOAD_TROUBLESHOOT_ASSET_SUCCESS,
} from "../Types/troubleshoot.constants";

const allTickets = async (payload: any) => {
  try {
    const userAccessResponse = store.getState().userAccess;
    let userAccess = userAccessResponse?.userAccess;
    let UserprofileAccess = userAccess
      ? userAccess[0]?.profile_based_access
      : null;
    let isAdminRole = UserprofileAccess ? UserprofileAccess?.isAdminRole : null;
    const requestData = {
      ...payload,
    };
    if (isAdminRole !== null && isAdminRole === false) {
      requestData.UserprofileAccess = UserprofileAccess;
    }
    // Call the initial API to get the total
    const res = await axios.post("tickets/filterTickets", requestData);
    // Calculate the number of API calls needed
    const total = res.data.total;
    const pageSize = 750;
    const numberOfCalls = Math.ceil(total / pageSize);

    // Create an array of API call promises
    const apiCallPromises = [];
    for (let page = 1; page <= numberOfCalls; page++) {
      // Modify the payload to include the current page
      const modifiedPayload = { ...requestData, page };
      apiCallPromises.push(
        axios.post("tickets/filterTickets", modifiedPayload)
      );
    }
    // Wait for all API calls to complete
    const responses = await Promise.all(apiCallPromises);
    // Combine the data from all responses
    const combinedData = responses.reduce((acc, response) => {
      return acc.concat(response.data.rows);
    }, []);

    return combinedData;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

//function to get current user role from the api
const getAllTroubleAssets = async (params: any) => {
  // debugger;
  let res = null;
  let payload = {
    usecaseId: params.useCaseId,
    plantId: params.plantId,
    page: "1",
    pageSize: "10",
    deviceIds: [
      "6496f9e52cd07adb0deaca5c",
      "6496f9e52cd07adb0deaca5d",
      "6496f9e52cd07adb0deaca5e",
      "6496f9e52cd07adb0deaca5f",
      "649c2c27a49c2bc832f73dae",
      "649c2c27a49c2bc832f73dae",
    ],
  };
  res = await axios.post(`assets/getAssetsByUsecasePlantIds`, payload);
  // res = {
  //   data: {
  //     rows: [
  //       {
  //         child_asset: [],
  //         use_case: ["6418b1683307df0011707f0a"],
  //         _id: "6496fb0ecb1a43b7f472c0c0",
  //         level: {
  //           _id: "608a39155f91fe00111da08e",
  //           level_type_name: "Level 2",
  //           level_types_code: "L2",
  //           organization_id: "6082b4d50e4acb04e8b5df29",
  //           __v: 0,
  //         },
  //         asset_type: "",
  //         asset_name: "T40 Aseptic Tank 1",
  //         asset_alias: "Aseptic Tank 1",
  //         asset_category: "Asset Area",
  //         description: "T40 Aseptic Tank 1",
  //         electrical_number: "",
  //         part_number: "",
  //         devices: [
  //           {
  //             isEnabled: true,
  //             isPrimarySensor: false,
  //             _id: "649947a3c043540018b0df48",
  //             device_id: "6489bea9e695430018a4b1f8",
  //             tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticStorage_T40AsepticTankGroup_T40AsepticTank1",
  //             sensor_id: "",
  //             usecase: null,
  //           },
  //           {
  //             isEnabled: true,
  //             isPrimarySensor: false,
  //             _id: "649c2fd259b6c80018e6b7b0",
  //             device_id: "6496f9e52cd07adb0deaca5c",
  //             tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticStorage_T40AsepticTankGroup_T40AsepticTank1",
  //             sensor_id: "",
  //             usecase: "6418b1683307df0011707f0a",
  //             mapped_dashboard: "64bfb010e0bcbcf7e6c534a8",
  //           },
  //         ],
  //         OEM: null,
  //         model: null,
  //         parent_asset: "64948f2307af9f2e4f06f76d",
  //         plant_id: "64342f5cbce1570011828c07",
  //         plant_code: "TEM",
  //         isBookmark: false,
  //       },
  //       {
  //         child_asset: [],
  //         use_case: ["6418b1683307df0011707f0a"],
  //         _id: "64be9a327ec5462450f49c64",
  //         level: {
  //           _id: "608a39155f91fe00111da08e",
  //           level_type_name: "Level 2",
  //           level_types_code: "L2",
  //           organization_id: "6082b4d50e4acb04e8b5df29",
  //           __v: 0,
  //         },
  //         asset_type: "",
  //         asset_name: "T40 Flex",
  //         asset_alias: "Flex",
  //         asset_category: "Asset Area",
  //         description: "T40 Flex",
  //         electrical_number: "",
  //         part_number: "",
  //         devices: [
  //           {
  //             isEnabled: true,
  //             isPrimarySensor: false,
  //             _id: "64beaf7259392600184e2bf7",
  //             device_id: "6496f9e52cd07adb0deaca5f",
  //             tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticUltraHighTemperature_T40AsepticFlex_T40AsepticFlexDevices",
  //             sensor_id: "",
  //             usecase: "6418b1683307df0011707f0a",
  //             mapped_dashboard: "64bfbd21e0bcbcf7e6c534b0",
  //           },
  //         ],
  //         OEM: null,
  //         model: null,
  //         parent_asset: "64948f2307af9f2e4f06f76d",
  //         plant_id: "64342f5cbce1570011828c07",
  //         plant_code: "TEM",
  //         isBookmark: false,
  //       },
  //       {
  //         child_asset: [],
  //         use_case: [
  //           "60cb784aafe4530011138ca9",
  //           "6497080b2cd07adb0deaca67",
  //           "6418b1683307df0011707f0a",
  //         ],
  //         _id: "6496fb0ecb1a43b7f472c0c2",
  //         level: {
  //           _id: "608a39155f91fe00111da08e",
  //           level_type_name: "Level 2",
  //           level_types_code: "L2",
  //           organization_id: "6082b4d50e4acb04e8b5df29",
  //           __v: 0,
  //         },
  //         asset_type: "",
  //         asset_name: "T40 Aseptic VTIS",
  //         asset_alias: "Aseptic VTIS",
  //         asset_category: "Asset",
  //         description: "T40 Aseptic VTIS",
  //         electrical_number: "",
  //         part_number: "",
  //         devices: [
  //           {
  //             isEnabled: true,
  //             isPrimarySensor: false,
  //             _id: "64994832c043540018b0df4a",
  //             device_id: "6489bea9e695430018a4b1f8",
  //             tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticUltraHighTemperature_T40AsepticVTIS",
  //             sensor_id: "",
  //             usecase: "6497080b2cd07adb0deaca67",
  //           },
  //           {
  //             isEnabled: true,
  //             isPrimarySensor: false,
  //             _id: "649c303159b6c80018e6b7b2",
  //             device_id: "6496f9e52cd07adb0deaca5f",
  //             tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticUltraHighTemperature_T40AsepticVTIS",
  //             sensor_id: "",
  //             usecase: "6418b1683307df0011707f0a",
  //             mapped_dashboard: "64bfc50fe0bcbcf7e6c534be",
  //           },
  //         ],
  //         OEM: null,
  //         model: null,
  //         parent_asset: "64948f2307af9f2e4f06f76d",
  //         plant_id: "64342f5cbce1570011828c07",
  //         plant_code: "TEM",
  //         isBookmark: false,
  //       },
  //       {
  //         child_asset: [],
  //         use_case: ["6418b1683307df0011707f0a"],
  //         _id: "6496fb0ecb1a43b7f472c0c1",
  //         level: {
  //           _id: "608a39155f91fe00111da08e",
  //           level_type_name: "Level 2",
  //           level_types_code: "L2",
  //           organization_id: "6082b4d50e4acb04e8b5df29",
  //           __v: 0,
  //         },
  //         asset_type: "",
  //         asset_name: "T40 Aseptic Tank 2",
  //         asset_alias: "Aseptic Tank 2",
  //         asset_category: "Asset Area",
  //         description: "T40 Aseptic Tank 2",
  //         electrical_number: "",
  //         part_number: "",
  //         devices: [
  //           {
  //             isEnabled: true,
  //             isPrimarySensor: false,
  //             _id: "649714588002ffd633339326",
  //             device_id: "6496f9e52cd07adb0deaca5d",
  //             sensor_id: null,
  //             sensor_number: null,
  //             mapped_dashboard: "64992bc212477200449c9833",
  //             usecase: null,
  //             tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticStorage_T40AsepticTankGroup_T40AsepticTank2",
  //           },
  //           {
  //             isEnabled: true,
  //             isPrimarySensor: false,
  //             _id: "649947edc043540018b0df49",
  //             device_id: "6489bea9e695430018a4b1f8",
  //             tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticStorage_T40AsepticTankGroup_T40AsepticTank2",
  //             sensor_id: "",
  //             usecase: null,
  //           },
  //           {
  //             isEnabled: true,
  //             isPrimarySensor: false,
  //             _id: "649c2f2459b6c80018e6b7ae",
  //             device_id: "6496f9e52cd07adb0deaca5d",
  //             tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticStorage_T40AsepticTankGroup_T40AsepticTank2",
  //             sensor_id: "",
  //             usecase: "6418b1683307df0011707f0a",
  //             mapped_dashboard: "64bfb010e0bcbcf7e6c534a8",
  //           },
  //         ],
  //         OEM: null,
  //         model: null,
  //         parent_asset: "64948f2307af9f2e4f06f76d",
  //         plant_id: "64342f5cbce1570011828c07",
  //         plant_code: "TEM",
  //         isBookmark: false,
  //       },
  //       {
  //         child_asset: [],
  //         use_case: ["6418b1683307df0011707f0a"],
  //         _id: "6496fb0ecb1a43b7f472c0bc",
  //         level: {
  //           _id: "608a39155f91fe00111da08e",
  //           level_type_name: "Level 2",
  //           level_types_code: "L2",
  //           organization_id: "6082b4d50e4acb04e8b5df29",
  //           __v: 0,
  //         },
  //         asset_type: "",
  //         asset_name: "T40 Aseptic Tank 3",
  //         asset_alias: "Aseptic Tank 3",
  //         asset_category: "Asset Area",
  //         description: "T40 Aseptic Tank 3",
  //         electrical_number: "",
  //         part_number: "",
  //         devices: [
  //           {
  //             isEnabled: true,
  //             isPrimarySensor: false,
  //             _id: "649714588002ffd633339362",
  //             device_id: "6496f9e52cd07adb0deaca5e",
  //             sensor_id: null,
  //             sensor_number: null,
  //             mapped_dashboard: "64992bc212477200449c9833",
  //             usecase: null,
  //             tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticStorage_T40AsepticTankGroup_T40AsepticTank3",
  //           },
  //           {
  //             isEnabled: true,
  //             isPrimarySensor: false,
  //             _id: "649945a182faf1001813fd36",
  //             device_id: "6489bea9e695430018a4b1f8",
  //             tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticStorage_T40AsepticTankGroup_T40AsepticTank3",
  //             sensor_id: "",
  //             usecase: null,
  //           },
  //           {
  //             isEnabled: true,
  //             isPrimarySensor: false,
  //             _id: "649c2ed759b6c80018e6b7ac",
  //             device_id: "6496f9e52cd07adb0deaca5e",
  //             tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticStorage_T40AsepticTankGroup_T40AsepticTank3",
  //             sensor_id: "",
  //             usecase: "6418b1683307df0011707f0a",
  //             mapped_dashboard: "64bfb010e0bcbcf7e6c534a8",
  //           },
  //         ],
  //         OEM: null,
  //         model: null,
  //         parent_asset: "64948f2307af9f2e4f06f76d",
  //         plant_id: "64342f5cbce1570011828c07",
  //         plant_code: "TEM",
  //         isBookmark: false,
  //       },
  //     ],
  //     total: 5,
  //     page: 1,
  //     pageSize: 10,
  //     totalPages: 1,
  //   },
  // };
  console.log("userAccess", res.data.rows);
  return res.data.rows;
};

// const getAllTroubleAssets = async (params: any) => {
//   try {
//     // const userAccessResponse = store.getState().userAccess;
//     // let userAccess = userAccessResponse?.userAccess;
//     // let UserprofileAccess = userAccess
//     //   ? userAccess[0]?.profile_based_access
//     //   : null;
//     // let isAdminRole = UserprofileAccess ? UserprofileAccess?.isAdminRole : null;

//     const requestData = {
//       usecaseId: params.useCaseId,
//       plantId: params.plantId,
//       page: 1,
//       pageSize: 750,
//     };

//     // if (isAdminRole !== null && isAdminRole === false) {
//     //   requestData.UserprofileAccess = UserprofileAccess;
//     // }

//     // Call the initial API to get the total
//     // const initialRes = await axios.get(`assets/getAssetsByUsecasePlantIds`, {
//     //   params: requestData,
//     // });
//     const initialRes = {
//       data: {
//         rows: [
//           {
//             child_asset: [],
//             use_case: ["6418b1683307df0011707f0a"],
//             _id: "6496fb0ecb1a43b7f472c0c0",
//             level: {
//               _id: "608a39155f91fe00111da08e",
//               level_type_name: "Level 2",
//               level_types_code: "L2",
//               organization_id: "6082b4d50e4acb04e8b5df29",
//               __v: 0,
//             },
//             asset_type: "",
//             asset_name: "T40 Aseptic Tank 1",
//             asset_alias: "Aseptic Tank 1",
//             asset_category: "Asset Area",
//             description: "T40 Aseptic Tank 1",
//             electrical_number: "",
//             part_number: "",
//             devices: [
//               {
//                 isEnabled: true,
//                 isPrimarySensor: false,
//                 _id: "649947a3c043540018b0df48",
//                 device_id: "6489bea9e695430018a4b1f8",
//                 tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticStorage_T40AsepticTankGroup_T40AsepticTank1",
//                 sensor_id: "",
//                 usecase: null,
//               },
//               {
//                 isEnabled: true,
//                 isPrimarySensor: false,
//                 _id: "649c2fd259b6c80018e6b7b0",
//                 device_id: "6496f9e52cd07adb0deaca5c",
//                 tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticStorage_T40AsepticTankGroup_T40AsepticTank1",
//                 sensor_id: "",
//                 usecase: "6418b1683307df0011707f0a",
//                 mapped_dashboard: "64bfb010e0bcbcf7e6c534a8",
//               },
//             ],
//             OEM: null,
//             model: null,
//             parent_asset: "64948f2307af9f2e4f06f76d",
//             plant_id: "64342f5cbce1570011828c07",
//             plant_code: "TEM",
//             isBookmark: false,
//           },
//           {
//             child_asset: [],
//             use_case: ["6418b1683307df0011707f0a"],
//             _id: "64be9a327ec5462450f49c64",
//             level: {
//               _id: "608a39155f91fe00111da08e",
//               level_type_name: "Level 2",
//               level_types_code: "L2",
//               organization_id: "6082b4d50e4acb04e8b5df29",
//               __v: 0,
//             },
//             asset_type: "",
//             asset_name: "T40 Flex",
//             asset_alias: "Flex",
//             asset_category: "Asset Area",
//             description: "T40 Flex",
//             electrical_number: "",
//             part_number: "",
//             devices: [
//               {
//                 isEnabled: true,
//                 isPrimarySensor: false,
//                 _id: "64beaf7259392600184e2bf7",
//                 device_id: "6496f9e52cd07adb0deaca5f",
//                 tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticUltraHighTemperature_T40AsepticFlex_T40AsepticFlexDevices",
//                 sensor_id: "",
//                 usecase: "6418b1683307df0011707f0a",
//                 mapped_dashboard: "64bfbd21e0bcbcf7e6c534b0",
//               },
//             ],
//             OEM: null,
//             model: null,
//             parent_asset: "64948f2307af9f2e4f06f76d",
//             plant_id: "64342f5cbce1570011828c07",
//             plant_code: "TEM",
//             isBookmark: false,
//           },
//           {
//             child_asset: [],
//             use_case: [
//               "60cb784aafe4530011138ca9",
//               "6497080b2cd07adb0deaca67",
//               "6418b1683307df0011707f0a",
//             ],
//             _id: "6496fb0ecb1a43b7f472c0c2",
//             level: {
//               _id: "608a39155f91fe00111da08e",
//               level_type_name: "Level 2",
//               level_types_code: "L2",
//               organization_id: "6082b4d50e4acb04e8b5df29",
//               __v: 0,
//             },
//             asset_type: "",
//             asset_name: "T40 Aseptic VTIS",
//             asset_alias: "Aseptic VTIS",
//             asset_category: "Asset",
//             description: "T40 Aseptic VTIS",
//             electrical_number: "",
//             part_number: "",
//             devices: [
//               {
//                 isEnabled: true,
//                 isPrimarySensor: false,
//                 _id: "64994832c043540018b0df4a",
//                 device_id: "6489bea9e695430018a4b1f8",
//                 tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticUltraHighTemperature_T40AsepticVTIS",
//                 sensor_id: "",
//                 usecase: "6497080b2cd07adb0deaca67",
//               },
//               {
//                 isEnabled: true,
//                 isPrimarySensor: false,
//                 _id: "649c303159b6c80018e6b7b2",
//                 device_id: "6496f9e52cd07adb0deaca5f",
//                 tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticUltraHighTemperature_T40AsepticVTIS",
//                 sensor_id: "",
//                 usecase: "6418b1683307df0011707f0a",
//                 mapped_dashboard: "64bfc50fe0bcbcf7e6c534be",
//               },
//             ],
//             OEM: null,
//             model: null,
//             parent_asset: "64948f2307af9f2e4f06f76d",
//             plant_id: "64342f5cbce1570011828c07",
//             plant_code: "TEM",
//             isBookmark: false,
//           },
//           {
//             child_asset: [],
//             use_case: ["6418b1683307df0011707f0a"],
//             _id: "6496fb0ecb1a43b7f472c0c1",
//             level: {
//               _id: "608a39155f91fe00111da08e",
//               level_type_name: "Level 2",
//               level_types_code: "L2",
//               organization_id: "6082b4d50e4acb04e8b5df29",
//               __v: 0,
//             },
//             asset_type: "",
//             asset_name: "T40 Aseptic Tank 2",
//             asset_alias: "Aseptic Tank 2",
//             asset_category: "Asset Area",
//             description: "T40 Aseptic Tank 2",
//             electrical_number: "",
//             part_number: "",
//             devices: [
//               {
//                 isEnabled: true,
//                 isPrimarySensor: false,
//                 _id: "649714588002ffd633339326",
//                 device_id: "6496f9e52cd07adb0deaca5d",
//                 sensor_id: null,
//                 sensor_number: null,
//                 mapped_dashboard: "64992bc212477200449c9833",
//                 usecase: null,
//                 tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticStorage_T40AsepticTankGroup_T40AsepticTank2",
//               },
//               {
//                 isEnabled: true,
//                 isPrimarySensor: false,
//                 _id: "649947edc043540018b0df49",
//                 device_id: "6489bea9e695430018a4b1f8",
//                 tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticStorage_T40AsepticTankGroup_T40AsepticTank2",
//                 sensor_id: "",
//                 usecase: null,
//               },
//               {
//                 isEnabled: true,
//                 isPrimarySensor: false,
//                 _id: "649c2f2459b6c80018e6b7ae",
//                 device_id: "6496f9e52cd07adb0deaca5d",
//                 tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticStorage_T40AsepticTankGroup_T40AsepticTank2",
//                 sensor_id: "",
//                 usecase: "6418b1683307df0011707f0a",
//                 mapped_dashboard: "64bfb010e0bcbcf7e6c534a8",
//               },
//             ],
//             OEM: null,
//             model: null,
//             parent_asset: "64948f2307af9f2e4f06f76d",
//             plant_id: "64342f5cbce1570011828c07",
//             plant_code: "TEM",
//             isBookmark: false,
//           },
//           {
//             child_asset: [],
//             use_case: ["6418b1683307df0011707f0a"],
//             _id: "6496fb0ecb1a43b7f472c0bc",
//             level: {
//               _id: "608a39155f91fe00111da08e",
//               level_type_name: "Level 2",
//               level_types_code: "L2",
//               organization_id: "6082b4d50e4acb04e8b5df29",
//               __v: 0,
//             },
//             asset_type: "",
//             asset_name: "T40 Aseptic Tank 3",
//             asset_alias: "Aseptic Tank 3",
//             asset_category: "Asset Area",
//             description: "T40 Aseptic Tank 3",
//             electrical_number: "",
//             part_number: "",
//             devices: [
//               {
//                 isEnabled: true,
//                 isPrimarySensor: false,
//                 _id: "649714588002ffd633339362",
//                 device_id: "6496f9e52cd07adb0deaca5e",
//                 sensor_id: null,
//                 sensor_number: null,
//                 mapped_dashboard: "64992bc212477200449c9833",
//                 usecase: null,
//                 tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticStorage_T40AsepticTankGroup_T40AsepticTank3",
//               },
//               {
//                 isEnabled: true,
//                 isPrimarySensor: false,
//                 _id: "649945a182faf1001813fd36",
//                 device_id: "6489bea9e695430018a4b1f8",
//                 tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticStorage_T40AsepticTankGroup_T40AsepticTank3",
//                 sensor_id: "",
//                 usecase: null,
//               },
//               {
//                 isEnabled: true,
//                 isPrimarySensor: false,
//                 _id: "649c2ed759b6c80018e6b7ac",
//                 device_id: "6496f9e52cd07adb0deaca5e",
//                 tag: "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticStorage_T40AsepticTankGroup_T40AsepticTank3",
//                 sensor_id: "",
//                 usecase: "6418b1683307df0011707f0a",
//                 mapped_dashboard: "64bfb010e0bcbcf7e6c534a8",
//               },
//             ],
//             OEM: null,
//             model: null,
//             parent_asset: "64948f2307af9f2e4f06f76d",
//             plant_id: "64342f5cbce1570011828c07",
//             plant_code: "TEM",
//             isBookmark: false,
//           },
//         ],
//         total: 5,
//         page: 1,
//         pageSize: 10,
//         totalPages: 1,
//       },
//     };

//     const total = initialRes.data.total;
//     const pageSize = 750;
//     const numberOfCalls = Math.ceil(total / pageSize);

//     // Create an array of API call promises
//     const apiCallPromises = [];
//     for (let page = 1; page <= numberOfCalls; page++) {
//       // Modify the request parameters to include the current page
//       const modifiedParams = {
//         usecaseId: params.useCaseId,
//         plantId: params.plantId,
//         page,
//         pageSize,
//       };
//       // if (isAdminRole !== null && isAdminRole === false) {
//       //   modifiedParams.UserprofileAccess = UserprofileAccess;
//       // }

//       apiCallPromises.push(
//         axios.get("assets/getAssetsByUsecasePlantIds", {
//           params: modifiedParams,
//         })
//       );
//     }

//     // Wait for all API calls to complete
//     const responses: any = await Promise.all(apiCallPromises);

//     // Combine the data from all responses
//     const combinedData = responses.reduce((acc: any, response: any) => {
//       if (response && response.data && response.data.rows) {
//         acc.push(...response.data.rows);
//       }
//       return acc;
//     }, []);

//     return combinedData;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     throw error;
//   }
// };

export function* listTroubleAsset(payload: any): any {
  try {
    const data = yield call(() =>
      getAllTroubleAssets(payload.payload).then((res) => {
        return res;
      })
    );

    yield put({
      type: LOAD_TROUBLESHOOT_ASSET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: LOAD_TROUBLESHOOT_ASSET_ERROR,
      payload: null,
    });
  }
}

export function* listTroubleAssetSaga() {
  yield takeEvery(LOAD_TROUBLESHOOT_ASSET, listTroubleAsset);
}
