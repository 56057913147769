import React, { useState, useEffect, useRef } from 'react'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	Grid,
	IconButton
} from '@mui/material'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import CloseIcon from '@mui/icons-material/Close'
import { Editor } from 'react-draft-wysiwyg'
import { makeStyles } from '@mui/styles'

interface Props {
	open: boolean
	onClose: () => void
	handleSaveEdit: (newText: string) => void
	handleEditClose: () => void
	editedAnnotationText: string
	setEditedAnnotationText: (text: string) => void
	title: string
	button1: string
	button2: string
}

const useStyles = makeStyles((theme: any) => ({
	demoWrapper: {
		'& .rdw-suggestion-dropdown': {
			background: 'none !important'
		}
	},
	demoToolbarCustom: {
		background: theme.palette.mode === 'dark' ? '#293368 !important' : '',
		color: theme.palette.mode === 'dark' ? '#000 !important' : '',
		border: '0px solid #1245a8 !important',
		backgroundColor: theme.palette.mode === 'dark' ? '#232a58 !important' : '',
		borderRadius: '12px 12px 0px 0px !important'
	},
	commentboxOptions: {
		background: theme.palette.mode === 'dark' ? '#232a58 !important' : '',
		border: theme.palette.mode === 'dark' ? '#232a58 !important' : '',
		'&rdw-dropdownoption-active': {
			background: theme.palette.mode === 'dark' ? '#8476EF !important' : ''
		},
		'&rdw-dropdownoption-highlighted': {
			background: theme.palette.mode === 'dark' ? '#8476EF !important' : ''
		}
	},
	commentboxDropdownOptions: {
		background: theme.palette.mode === 'dark' ? '#232a58 !important' : '',
		border: theme.palette.mode === 'dark' ? '#232a58 !important' : '',
		backgroundColor: '#232a58',
		scrollBarWidth: 'thin',
		scrollbarColor: '#232A5830',
		'&::-webkit-scrollbar, & *::-webkit-scrollbar': {
			backgroundColor: '#232A5830'
		},
		'&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
			borderRadius: 10,
			backgroundColor: '#29336880',
			minHeight: 24
			// border: "3px solid #2b2b2b",
		}
	}
}))

const EditAnnotations: React.FC<Props> = ({
	open,
	onClose,
	handleSaveEdit,
	handleEditClose,
	editedAnnotationText,
	setEditedAnnotationText,
	title,
	button1,
	button2
}) => {
	const [editorState, setEditorState] = useState<EditorState>(() =>
		EditorState.createEmpty()
	)
	const classes = useStyles()
	const editorRef = useRef<any>(null)
	const [lines, setLines] = useState<number>(0)

	useEffect(() => {
		setEditorState(
			EditorState.createWithContent(
				ContentState.createFromText(editedAnnotationText)
			)
		)
	}, [editedAnnotationText])

	const handleEditorChange = (newEditorState: EditorState) => {
		setEditorState(newEditorState)
	}

	const onEditorChange = (e: any) => {
		const prevLines = lines
		if (prevLines !== e?.blocks?.length) {
			setLines(e?.blocks?.length)
		}
	}

	useEffect(() => {
		if (editorRef?.current) {
			const scrollDiv = editorRef?.current?.querySelector('.rdw-editor-main')
			scrollDiv.scrollTop = scrollDiv?.scrollHeight
		}
	}, [lines])

	const handleSave = () => {
		const contentState = editorState.getCurrentContent()
		const rawContentState = convertToRaw(contentState)
		let newText: string = ''
		rawContentState?.blocks?.forEach((block: any) => {
			newText = newText + ` ${block?.text}`
		})

		handleSaveEdit(newText?.trim())
		handleEditClose()
	}

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle style={{ fontSize: '16px' }}>
				{title}
				<IconButton
					onClick={onClose}
					style={{ position: 'absolute', right: 8, top: 8 }}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12} ref={editorRef} className='row'>
						<Editor
							editorState={editorState}
							onEditorStateChange={handleEditorChange}
							onChange={(e: any) => onEditorChange(e)}
							wrapperClassName={classes.demoWrapper}
							editorClassName='demo-editor'
							toolbarClassName={classes.demoToolbarCustom}
							toolbar={{
								options: [
									'inline',
									'blockType',
									'fontSize',
									'fontFamily',
									'list',
									'textAlign',
									'colorPicker',
									'history'
								],
								inline: {
									inDropdown: false,
									className: classes.commentboxOptions,
									component: undefined,
									dropdownClassName: classes.commentboxDropdownOptions,
									options: ['bold', 'italic', 'underline', 'strikethrough'],
									bold: { className: classes.commentboxOptions },
									italic: { className: classes.commentboxOptions },
									underline: { className: classes.commentboxOptions },
									strikethrough: { className: classes.commentboxOptions },
									monospace: { className: classes.commentboxOptions }
								},
								blockType: {
									inDropdown: true,
									options: [
										'Normal',
										'H1',
										'H2',
										'H3',
										'H4',
										'H5',
										'H6',
										'Blockquote',
										'Code'
									],
									className: classes.commentboxOptions,
									component: undefined,
									dropdownClassName: classes.commentboxDropdownOptions
								},
								fontSize: {
									options: [
										8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96
									],
									className: classes.commentboxOptions,
									component: undefined,
									dropdownClassName: classes.commentboxDropdownOptions
								},
								fontFamily: {
									options: [
										'Arial',
										'Georgia',
										'Impact',
										'Tahoma',
										'Times New Roman',
										'Verdana'
									],
									className: classes.commentboxOptions,
									component: undefined,
									dropdownClassName: classes.commentboxDropdownOptions
								},
								list: {
									inDropdown: false,
									className: classes.commentboxOptions,
									component: undefined,
									dropdownClassName: classes.commentboxDropdownOptions,
									options: ['unordered', 'ordered'],
									unordered: { className: classes.commentboxOptions },
									ordered: { className: classes.commentboxOptions },
									indent: { className: classes.commentboxOptions },
									outdent: { className: classes.commentboxOptions }
								},
								textAlign: {
									inDropdown: false,
									className: classes.commentboxOptions,
									component: undefined,
									dropdownClassName: classes.commentboxDropdownOptions,
									options: ['left', 'center', 'right', 'justify'],
									left: { className: classes.commentboxOptions },
									center: { className: classes.commentboxOptions },
									right: { className: classes.commentboxOptions },
									justify: { className: classes.commentboxOptions }
								},
								colorPicker: {
									className: classes.commentboxOptions,
									component: undefined,
									popupClassName: undefined
								},
								emoji: {
									className: classes.commentboxOptions,
									component: undefined,
									popupClassName: undefined
								},
								embedded: {
									className: classes.commentboxOptions,
									component: undefined,
									popupClassName: classes.commentboxOptions,
									embedCallback: undefined,
									defaultSize: {
										height: 'auto',
										width: 'auto'
									}
								},
								history: {
									inDropdown: false,
									className: classes.commentboxOptions,
									component: undefined,
									dropdownClassName: classes.commentboxDropdownOptions,
									options: ['undo', 'redo'],
									undo: { className: classes.commentboxOptions },
									redo: { className: classes.commentboxOptions }
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} container justifyContent='flex-end'>
						<Button
							variant='outlined'
							onClick={handleEditClose}
							style={{ marginRight: '8px' }}
						>
							{button1}
						</Button>
						<Button
							variant='contained'
							onClick={() => handleSave()}
							style={{ marginLeft: '8px' }}
						>
							{button2}
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default EditAnnotations
