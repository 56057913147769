import BackupTableIcon from "@mui/icons-material/BackupTable";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../../App";
import Axios from "../../../../../Utils/AxiosConfig/axiosConfig";
import { defaultErrorAction } from "../../../../../Utils/util";
import AddCommentButton from "../Component/AddComment";
import { LGVHealthContext } from "../Component/Context";
import NavBar from "../Component/NavBar";
import NavQualLegend from "../Component/NavQualLegend";
import TimeFilter from "../Component/TimeFilter";
import { SingleLgv, HeatmapRespData, Annotation } from "../Helpers/interfaces";
import { timeFilterRanges } from "../Helpers/utils";
import AssetSelect from "./Components/AssetSelect";
import { useCubeFetch } from "../Helpers/hooks";
import ViewAnnotations from "../Component/ViewAnnotations";
import DynamicPlantLayoutWrapper from "./DynamicPlantLayoutWrapper";
import { DetailPage as useStyles } from "./helpers/styles";
import LgvNavQualityOverTime from "./QualityOverTime";

interface NumberedObject<Type> {
  [key: number]: Type;
}
// interface Annotation {
//   _id: string;
//   message: string;
//   reportName: string;
//   x: string;
//   y: string;
//   userId: string;
//   tagType: string;
//   timestamp: string;
//   type: string;
//   plantId?: string;
//   title?: string;
//   scatter_plot_type?: string;
//   use_case_name?: string;
// }

const defaultStartDate = moment().subtract(24, "hours").toDate();

const defaultEndDate = new Date();

const visualizationType = "Nav Quality Over Time";

const DetailsPage: FC = () => {
  const classes = useStyles();
  const { assetId } = useParams();

  const lgvStoreData = useSelector(
    (state: any) => state.lgvHealth.historicalData
  );

  const plantsList = useSelector((state: any) => state.plantsList);
  const seedsList = useSelector((state: any) => state.seedsList);
  const { cubeFetch } = useCubeFetch();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [timeMachineEnabled, setTimeMachineEnabled] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [annotationLoader, setAnnotationLoader] = useState<boolean>(true);

  const [startTimeDate, setStartTimeDate] = useState<Date>(defaultStartDate);
  const [endTimeDate, setEndTimeDate] = useState<Date>(defaultEndDate);

  const [clickedLink, setClickedLink] = useState<number>(0);
  const [selectedAssets, setSelectedAssets] = useState<string[]>([assetId]);

  const [allLgvData, setAllLgvData] =
    useState<NumberedObject<SingleLgv>[]>(lgvStoreData);

  const [HeatMapData, setHeatMapData] = useState<
    NumberedObject<HeatmapRespData>[]
  >([]);

  const [plantLayoutLgvRecords, setPlantLayoutLgvRecords] = useState<{
    [lgvId: string]: HeatmapRespData[];
  }>({});

  const formattedSelectedAssetsData = useMemo(() => {
    const formattedLgvId: any = [];

    selectedAssets.forEach((asset: string) => {
      formattedLgvId.push(asset?.replace(/\s/g, ""));
    });

    return formattedLgvId;
  }, [selectedAssets]);

  const [filteredAnnotations, setFilteredAnnotations] = useState<Annotation[]>(
    []
  );

  const [allAnn, setAllAnn] = useState<Annotation[]>([]);

  const { selectedPlant } = useContext(LGVHealthContext);
  const { setSelectedPlantForCube }: any = useContext(AppContext);

  const plantCode: string = selectedPlant.plant_code;
  const reportName = `${assetId} Report`;

  // Find LGVHealth configuration
  const lgvHealthConfig = useMemo(() => {
    return seedsList.seeds.find((seed: any) => {
      return seed.seed_key_name === "LGVHealth";
    })?.seed_data_value;
  }, [seedsList.seeds]);

  const lgvNavQualityConfigValues: any = useMemo(() => {
    // If LGVHealth configuration exists, filter LGV_NAV_QUALITY config
    if (!lgvHealthConfig) return;

    const lgvNavQualityConfig = lgvHealthConfig.filter(
      (item: any) => item?.key === "LGV_NAV_QUALITY"
    );

    // Parse LGV_NAV_QUALITY config values if found
    if (lgvNavQualityConfig.length > 0) {
      return JSON.parse(lgvNavQualityConfig[0]?.value);
    }
  }, [lgvHealthConfig]);

  const navQualitySegmentConfig =
    lgvNavQualityConfigValues["Navigation_Quality_Over_Time"];

  const navHeatMapConfig = lgvNavQualityConfigValues["Navigation_Heat_Map"];

  const handleTimeMachineChange = () => {
    setTimeMachineEnabled(!timeMachineEnabled);
  };

  // Function to open the dialog
  const handleOpenDialog = () => setOpenDialog(true); // Fetch or set annotations data here

  // Function to close the dialog
  const handleCloseDialog = () => setOpenDialog(false);

  const handleSelectedAssetChange = (newSelectedAssets: string[]) =>
    setSelectedAssets(newSelectedAssets);

  const assetsAvailable = useMemo<string[]>(() => {
    const assetsAvailable = [];

    for (const key in allLgvData[0]) {
      const lgvNum = parseInt(key);

      if (isNaN(lgvNum)) continue;

      assetsAvailable.push("LGV " + key);
    }

    return assetsAvailable;
  }, [allLgvData]);

  const fetchAnnotations = useCallback(async () => {
    try {
      const assetIdSplit = assetId.split(" ");
      const assetIdNum = assetIdSplit[1];
      setIsLoading(true);
      const response = await Axios.get(
        `/annotations/allByLgv?plantId=${selectedPlant._id}&lgvNum=${assetIdNum}&type=${visualizationType}`
      );
      const allAnnotations = response.data.data;

      setAllAnn(allAnnotations);
    } catch (error) {
      dispatch(defaultErrorAction("Error Fetching Annotations"));
    } finally {
      setIsLoading(false);
    }
  }, [assetId, selectedPlant, dispatch]);

  useEffect(() => {
    setSelectedPlantForCube(selectedPlant.plant_code);
  }, [selectedPlant]);

  useEffect(() => {
    const filteredAnnotations = allAnn.filter((annotation: Annotation) => {
      const annotationTimestamp = moment(annotation.timestamp);
      return annotationTimestamp.isBetween(startTimeDate, endTimeDate);
    });

    setFilteredAnnotations(filteredAnnotations);
    setAnnotationLoader(false);
  }, [allAnn, startTimeDate, endTimeDate]);

  const fetchData = useCallback(() => {
    setHeatMapData([]);

    cubeFetch({
      startTimeDate,
      endTimeDate,
      setAllLgvData,
      setHeatMapData,
      setPlantLayoutLgvRecords,
    });
  }, [startTimeDate, endTimeDate, cubeFetch]);

  useEffect(() => {
    dispatch({
      type: "LOAD_HISTORICAL_DATA",
      payload: { historicalData: allLgvData },
    });
  }, [allLgvData, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchAnnotations();
  }, [fetchAnnotations]);

  useEffect(() => {
    setIsLoading(Boolean(plantsList.plants.length));
  }, [plantsList]);

  return (
    <>
      {isLoading ? (
        <CircularProgress style={{ margin: "20% 45%" }} />
      ) : (
        <Box className={[classes.criticalControlSec].join(" ")}>
          <Grid
            container
            direction="row"
            justifyContent="between"
            alignItems="center"
            spacing={2}
            rowSpacing={4}
            style={{
              padding: "40px 20px 20px",
            }}
          >
            <NavBar
              page={reportName}
              arrow={true}
              navigationUrl="/usecase/lgv-nav"
            />
            <Grid item xs={12} sm={12} md={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              marginLeft="3em"
              maxHeight="5px"
              marginTop="1.4em"
              padding="none"
              marginBottom="2em"
            >
              <TimeFilter
                selectedPlant={selectedPlant}
                timeFilterRanges={timeFilterRanges}
                startDateTime={startTimeDate}
                setStartDateTime={setStartTimeDate}
                endDateTime={endTimeDate}
                setEndDateTime={setEndTimeDate}
              />
            </Grid>
            <Grid>
              <AssetSelect
                assetsAvailable={assetsAvailable}
                onChange={handleSelectedAssetChange}
                selectedAssets={selectedAssets}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              padding: "20px",
            }}
            className={[classes.width100, classes.posRelative].join(" ")}
          >
            <DynamicPlantLayoutWrapper
              plantLayoutLgvRecords={plantLayoutLgvRecords}
              timeSliderStart={startTimeDate}
              timeSliderEnd={endTimeDate}
              timeMachineEnabled={timeMachineEnabled}
              handleTimeMachineChange={handleTimeMachineChange}
              plantCode={plantCode}
              HeatMapData={HeatMapData}
              navHeatMapConfig={navHeatMapConfig}
              formattedSelectedAssetsData={formattedSelectedAssetsData}
            />
            <Box
              id="parent-modal"
              sx={{
                background: "#293368",
                padding: "10px",
                borderRadius: "8px",
                marginBottom: "20px",
                position: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Typography
                    p={1}
                    sx={{
                      color: "#ffffff",
                      fontSize: "18px",
                      fontStyle: "Roboto",
                      textTransform: "capitalize",
                      fontWeight: "bold",
                    }}
                  >
                    {navQualitySegmentConfig.chartTitle ||
                      "Nav Quality Over Time"}
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <AddCommentButton parentContainerId="parent-modal" />
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleOpenDialog}
                        sx={{ width: "100%" }}
                      >
                        <BackupTableIcon />
                        <Typography sx={{ mx: 1 }}>View Comments</Typography>
                      </Button>
                    </Stack>
                  </div>
                </Box>
                <LgvNavQualityOverTime
                  legendItems={navQualitySegmentConfig.ranges}
                  secondaryLegend={
                    navQualitySegmentConfig.secondaryLegend || false
                  }
                  plant={selectedPlant}
                  assetId={assetId}
                  reportName={reportName}
                  clickedLink={clickedLink}
                  setFilteredAnnotations={setFilteredAnnotations}
                  filteredAnnotations={filteredAnnotations}
                />

                <NavQualLegend
                  legendTitle={
                    navQualitySegmentConfig.legendTitle || "Threshold"
                  }
                  legendItems={navQualitySegmentConfig.ranges || []}
                />
              </Grid>
            </Box>
          </Grid>
        </Box>
      )}

      <ViewAnnotations
        open={openDialog}
        onClose={handleCloseDialog}
        annotations={filteredAnnotations}
        title="Comments"
        closeButton="Close"
        pageName="LGV Nav Detail Page"
        setFilteredAnnotations={setFilteredAnnotations}
        setClickedLink={setClickedLink}
        setAnnotations={() => undefined}
        loader={annotationLoader}
      />
    </>
  );
};

export default DetailsPage;
