import {
  LOAD_DEVICES,
  LOAD_DEVICES_SUCCESS,
  LOAD_DEVICES_ERROR,
} from "../Types/devices.constants";

// import axios from "axios";
import axios from "../../Utils/AxiosConfig/axiosConfig";
import { put, call, takeEvery } from "redux-saga/effects";

//function to get all devices from the api
const getDevices = async () => {
  let devices: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`devices?pageSize=100&page=${page++}`);
    devices = devices.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);
  return devices;
};

export function* listDevices(): any {
  try {
    const data = yield call(() =>
      getDevices().then((res) => {
        return res;
      })
    );
    yield put({
      type: LOAD_DEVICES_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    console.error(error.response);
    yield put({
      type: LOAD_DEVICES_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export function* listDevicesSaga() {
  yield takeEvery(LOAD_DEVICES, listDevices);
}
