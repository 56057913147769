import { makeStyles } from "@mui/styles";

export const LandingPage = makeStyles(() => ({
  criticalControlSec: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      border: "1px solid #8476ef",
      background: "#293368",
      "& .MuiAutocomplete-input": {
        padding: "0 !important",
      },
    },

    "& .MuiInputBase-input": {
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "14px",
      "&::placeholder": {
        color: "#FFF",
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "12px",
    },
    "& .css-19kzrtu": {
      padding: 0,
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
  width100: {
    width: "100%",
  },
  width80: {
    width: "80%",
  },
  heatmapWrapper: {
    position: "relative",
  },
  heatmapContainer: {
    width: "100%",
    height: "70vh",
    maxWidth: "100%",
    margin: " 0 auto",
    display: "block",
    overflow: "hidden",
  },
  posRelative: {
    position: "relative",
  },
  btmNavQty: {
    background: "#232A58",
    padding: "20px 20px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  indicator: {
    height: "18px",
    width: "500px",
    border: "none",
    borderRadius: "10px",
    background:
      "linear-gradient(to right, red 20%, orange 40%, yellow 60%, green 80%)",
  },
  flexSpaceBwt: {
    display: "flex",
    justifyContent: "space-between",
  },
  flexDirCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  flexDirRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  direcCol: {
    flexDirection: "column",
  },
  direcRow: {
    flexDirection: "row",
  },
  gap20px: {
    gap: "20px",
  },
  hgt60vh: {
    height: "60vh",
  },
  textVertical: {
    writingMode: "vertical-lr",
    textOrientation: "mixed",
    transform: "rotate(180deg)",
  },
  marTop10: {
    marginTop: "1em",
  },
  zoomControlCenter: {
    position: "absolute",
    top: "65%",
    right: "5%",
    zIndex: 100,
  },
  zoomControls: {
    width: "50px",
    height: "30px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #DE6113",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  zoomInControl: {
    borderRadius: "10px 10px 0px 0px",
  },
  zoomOutControl: {
    borderRadius: "0px 0px 10px 10px",
  },
}));

export const DetailPage = makeStyles(() => ({
  criticalControlSec: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      background: "#293368",
      "& .MuiAutocomplete-input": {
        padding: "0 !important",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #8476ef !important",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "14px",
      "&::placeholder": {
        color: "#FFF",
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "12px",
    },
    "& .css-19kzrtu": {
      padding: 0,
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
  width100: {
    width: "100%",
  },
  posRelative: {
    position: "relative",
  },
}));

export const DynamicPlantLayoutWrapper = makeStyles(() => ({
  root: {
    width: "68px !important",
    height: "40px !important",
    padding: "0px !important",
  },
  switchBase: {
    color: "#818181",
    "&$checked": {
      "& + $track": {
        backgroundColor: "white",
      },
    },
  },
  thumb: {
    backgroundColor: "#232A58 !important",
    width: "30px !important",
    height: "30px !important",
  },
  track: {
    borderRadius: "30px !important",
    backgroundColor: "white !important",
    opacity: "1 !important",
    "&:after, &:before": {
      color: "black",
      fontSize: "14px",
      position: "absolute",
      top: "8px",
    },
    "&:after": {
      content: "'On'",
      left: "8px !important",
    },
    "&:before": {
      content: "'Off'",
      right: "8px !important",
    },
  },
  checked: {
    color: "#23bf58 !important",
    transform: "translateX(25px) !important",
  },
  dynamicPlantLayoutContainer: {
    width: "100%",
    height: "70vh",
    maxWidth: "100%",
    margin: " 0 auto",
    display: "block",
    overflow: "hidden",
  },
  btmNavQty: {
    background: "#232A58",
    padding: "18px 20px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  indicator: {
    height: "18px",
    width: "500px",
    border: "none",
    borderRadius: "10px",
    background:
      "linear-gradient(to right, red 20%, orange 40%, yellow 60%, green 80%)",
  },
  flexSpaceBwt: {
    display: "flex",
    justifyContent: "space-between",
  },
  marTop10: {
    marginTop: "1em",
  },
  zoomControlCenter: {
    position: "absolute",
    top: "43%",
    right: "6%",
    zIndex: 100,
  },
  zoomControls: {
    width: "50px",
    height: "30px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #DE6113",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  zoomInControl: {
    borderRadius: "10px 10px 0px 0px",
  },
  zoomOutControl: {
    borderRadius: "0px 0px 10px 10px",
  },
  liveIndicator: {
    border: "1px solid red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
    color: "white",
    padding: "5px 10px",
    marginLeft: "10px",
  },
  liveDot: {
    height: "14px",
    width: "14px",
    backgroundColor: "red",
    borderRadius: "50%",
    display: "inline-block",
    marginLeft: "2px",
    marginRight: "6px",
  },
}));

export const TimeSlider = makeStyles(() => ({
  slider: {
    "& .MuiSlider-valueLabel": {
      backgroundColor: "#000",
    },
    "& .MuiSlider-rail": {
      backgroundColor: "#232a58",
      opacity: 1,
    },
  },
  root: {
    position: "relative",
    width: "100%",
    margin: "auto",
    marginTop: "16px",
    padding: "30px", // Add 20px padding
    border: "1px solid white", // Add thin white border
    borderRadius: "20px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "2rem",
  },
  inputFieldLabelPosition: {
    backgroundColor: "#293368",
    borderRadius: "14px",
    padding: "0px 10px !important",
    marginTop: "3px",
  },
}));

export const SegmentChartWrapper = makeStyles(() => ({
  rootGridContainer: {
    margin: "10px 0px",
    padding: "25px 25px 0px 25px",
    borderTop: "1px solid #626262",
  },
  gridText: {
    "&.MuiTypography-root": {
      fontSize: "12px",
      texAlign: "center",
    },
  },
  healthIndicatior: {
    width: "13px",
    height: "13px",
    borderRadius: "50%",
    margin: "0 8px 0px 0px",
    display: "inline-block",
  },
  rootContainer: {
    position: "relative",
    margin: "20px 0",
    display: "flex",
    justifyContent: "center",
  },
  chartContainer: {
    width: "98%",
    padding: "0px 12px",
  },
  titleLeft: {
    position: "absolute",
    left: "0",
    top: "50%",
    transform: "translateY(100%) rotate(-90deg)",
    transformOrigin: "bottom center",
    whiteSpace: "nowrap",
    textAlign: "center",
    zIndex: 1,
    width: "20px",
  },
  titleRight: {
    position: "absolute",
    right: "0",
    top: "50%",
    transform: "translateY(100%) rotate(-90deg)",
    transformOrigin: "bottom center",
    whiteSpace: "nowrap",
    textAlign: "center",
    zIndex: 1,
    width: "20px",
  },
  chartTitle: {
    "&.MuiTypography-root": {
      marginBottom: "10px",
      color: "#FFF",
      textTransform: "capitalize",
      fontWeight: "bold",
      fontSize: "18px",
    },
  },
  shortIcon: {
    width: "28px",
    height: "28px",
    fontWeight: 600,
    marginRight: "20px",
  },
}));

export const LgvNavQuality = makeStyles(() => ({
  criticalControlSec: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      border: "1px solid #8476ef",
      background: "#293368",
      padding: "12px !important",
      "& .MuiAutocomplete-input": {
        padding: "0 !important",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "14px",
      "&::placeholder": {
        color: "#FFF",
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "12px",
    },
    "& .css-19kzrtu": {
      padding: 0,
    },
  },
  boldFont: {
    fontWeight: "bold",
  },
  liveIndicator: {
    border: "1px solid red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",

    color: "white",
    padding: "5px 10px",
    marginLeft: "10px",
    marginBottom: "26px",
  },
  liveDot: {
    height: "14px",
    width: "14px",
    backgroundColor: "red",
    borderRadius: "50%",
    display: "inline-block",
    marginLeft: "2px",
    marginRight: "6px",
  },
  tableRow1: {
    "&.MuiTableRow-root": {
      backgroundColor: "#242C59",

      "&:hover": {
        backgroundColor: "#242C5990",
      },
    },
  },
  tableRow2: {
    "&.MuiTableRow-root": {
      backgroundColor: "#2D376D",

      "&:hover": {
        backgroundColor: "#2D376D70",
      },
    },
  },
}));

export const AssetSelector = makeStyles(() => ({
  chipStyles: {
    "&.MuiChip-root": {
      margin: "0px 5px",
      backgroundColor: "#8476EF",
      borderRadius: "16px",
      padding: "4px",
      color: "#FFFFFFDE",
      letterSpacing: "0.25px",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      height: "32px",
    },
  },
}));
