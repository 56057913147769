import {
  LOAD_TROUBLESHOOT_ASSET,
  LOAD_TROUBLESHOOT_ASSET_ERROR,
  LOAD_TROUBLESHOOT_ASSET_SUCCESS,
} from "../Types/troubleshoot.constants";

const INITIAL_STATE = {
  loading: false,
  troubleShootAssetList: [],
  error: null,
};

export const troubleAssetReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_TROUBLESHOOT_ASSET:
      return { ...state, loading: true, troubleShootAssetList: [] };
    case LOAD_TROUBLESHOOT_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        troubleShootAssetList: action.payload,
      };
    case LOAD_TROUBLESHOOT_ASSET_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
