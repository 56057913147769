import { LOAD_SNACKBAR, SNACKBAR_OPEN } from "../Types/snackBar.constants";
import { put, takeEvery } from "redux-saga/effects";

export function* loadSnackBar(action: any): any {
  yield put({
    type: SNACKBAR_OPEN,
    payload: action.payload,
  });
}

export function* addSnackBarSaga() {
  yield takeEvery(LOAD_SNACKBAR, loadSnackBar);
}
