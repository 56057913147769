import React, { useState } from "react";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { loginRequest } from "./Services/authService";
import { EventType, InteractionType } from "@azure/msal-browser";
import { AuthenticatedTemplate } from "@azure/msal-react";
import CreateUser from "./Services/CreateUser";
import { useSelector } from "react-redux";
import UsersList from "./pages/master/User/UsersTable";
import Axios from "./Utils/AxiosConfig/axiosConfig";

function AuthAD(props: any) {
  const { instance } = useMsal();
  useMsalAuthentication(InteractionType.Redirect, loginRequest);

  instance.addEventCallback((message: any) => {
    if (message.eventType === EventType.LOGIN_SUCCESS) {
      const { payload } = message;
      CreateUser(payload.accessToken, payload.account);
    }
    // Handled login error for BrowserAuthError Status Code
    if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
      instance.loginRedirect(loginRequest);
    }
  });

  return <AuthenticatedTemplate>{props.children}</AuthenticatedTemplate>;
}

export default AuthAD;
