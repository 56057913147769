// QUERIES
export const NAV_QUALITY_HISTORICAL = "nav_quality_historical";
export const LOAD_SNACKBAR = "LOAD_SNACKBAR";
export const DEFAULT_SNACKBAR_STATE = {
  message: "Something went wrong, failed to fetch cube data!",
  type: "error",
  open: true,
};

export const NAV_QUALITY_HISTORICAL_CUBE = "TACLGVOutOfSpec";
export const DATA_NOT_FOUND = "No data found for selected timeframe.";
export const rectBottomLeftXNew = 0;
export const rectBottomLeftYNew = 500;
export const rectTopRightXNew = 1446;
export const rectTopRightYNew = 5.453125;

export const HYDRAULIC_SYSTEM_HEALTH = "Hydraulic System Health";
export const INVERTER_SYSTEM_HEALTH = "Inverter System Health";

export const defaultPlantCodes = {
	navQuality: 'TAC',
	plsSensor: 'TAC',
	hydraulic: 'TAC',
	inverter: 'TAC',
	battery: 'TAC'
}

export const chipDisplay = {
  allAssets: "All Assets",
  multiAssets: "Multiple Assets",
  noAssets: "No Assets",
  singleAsset: "Single Asset",
};
