import {
  LOAD_SENSORS,
  LOAD_SENSORS_SUCCESS,
  LOAD_SENSORS_ERROR,
} from "../Types/sensors.constants";

import axios from "../../Utils/AxiosConfig/axiosConfig";
import { put, call, takeEvery } from "redux-saga/effects";

//function to get all sensors from the api
const getSensors = async () => {
  let sensors: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`sensors?pageSize=100&page=${page++}`);
    sensors = sensors.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);
  return sensors;
};

export function* listSensors(): any {
  try {
    const data = yield call(() =>
    getSensors().then((res) => {
        return res;
      })
    );

    yield put({
      type: LOAD_SENSORS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: LOAD_SENSORS_ERROR,
      payload: null,
    });
  }
}

export function* listSensorsSaga() {
  yield takeEvery(LOAD_SENSORS, listSensors);
}
