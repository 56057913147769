/* eslint-disable no-console,func-names,react/no-multi-comp */
import React from 'react';
import { cloneDeep, find, isArray, isEmpty } from 'lodash';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const WQMSPlantBasedLength = (props: any) => {
  const { items, onSeqChange, selectedPlant } = props;

  const handleChange = (value: string) => {
    let clonedUpdatedItems = cloneDeep(items);
    clonedUpdatedItems.map((item: any) =>{
      if (item.key === selectedPlant?.plant_code) {
        item.value = value;
      }
      return item; 
    })

    onSeqChange(clonedUpdatedItems);
  }

  if (isEmpty(items) || !isArray(items)) return null;

  const currentPlantValue = find(items, function (item) {
    if (item.key === selectedPlant?.plant_code) {
        return true;
    }
  });

  return (
    <div className="selfConfigComponent">
      <FormControl fullWidth sx={{ margin: '16px 0' }}>
        <InputLabel id="demo-simple-select-label">Recipe {currentPlantValue?.key}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Category"
          inputProps={{ "data-testid": "page-dropdown" }}
          value={currentPlantValue?.value}
          onChange={(event) => handleChange(event.target.value)}
        >
          <MenuItem value={"1"}>1</MenuItem>
          <MenuItem value={"2"}>2</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}


export default WQMSPlantBasedLength;
