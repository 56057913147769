import {
  LOAD_USECASE,
  LOAD_USECASE_SUCCESS,
  LOAD_USECASE_ERROR,
  SELECT_USECASE,
  CLEAR_USECASE,
} from "../Types/usecase.contants";

const INITIAL_STATE = {
  loading: false,
  usecases: [],
  error: null,
  currentUsecase: {},
  isCalled: false,
};

export const usecaseListReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_USECASE:
      return { ...state, loading: true, usecases: [] };
    case LOAD_USECASE_SUCCESS:
      return {
        ...state,
        loading: false,
        usecases: action.payload,
        isCalled: true,
      };
    case LOAD_USECASE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCalled: false,
      };
    case SELECT_USECASE:
      return { ...state, currentUsecase: action.payload };
    case CLEAR_USECASE:
      return { ...state, currentUsecase: {} };
    default:
      return state;
  }
};
