import React, { useState, useEffect, useCallback, useRef } from "react";
import moment from "moment";
import { useMsal } from "@azure/msal-react";
import { acquireTokenSilent, userInfo } from "../../Services/authService";
import Alert from "../../componets/Modal/index";

const SessionTimeout = () => {
  const [events, setEvents] = useState(["load", "click", "scroll", "keypress"]);
  const [time, setTime] = useState(300 * 60);
  const [isOpen, setOpen] = useState(false);
  const { instance } = useMsal();

  let timeStamp: any;
  let warningInactiveInterval: any = useRef();
  let startTimerInterval: any = useRef();

  // check for inactivity
  // updated inactivity from 10 minutes to 6 hours

  const SIX_HOURS = 6 * 60 * 60 * 1000; // in ms

  let timeChecker = useCallback(() => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp: any = sessionStorage.getItem("lastTimeStamp");
      warningInactive(storedTimeStamp);
    }, SIX_HOURS);
  },[]);

  const logOut = () => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  // check for warning timer
  let warningInactive = (timeString: string) => {
    clearTimeout(startTimerInterval.current);
    const maxTime = 360;
    const popTime = 300;
    let time = popTime * 60;

    warningInactiveInterval.current = setInterval(() => {
      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes();

      if (minPast >= popTime) {
        setTime(time--);
      }

      if (minPast === popTime) {
        setOpen(true);
      }

      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        setOpen(false);
        sessionStorage.removeItem("lastTimeStamp");
        logOut();
      }
    }, 1000);
  };

  // check for reset interval timer
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);
    if (userInfo.userIsAuthenticated) {
      timeStamp = moment();
      sessionStorage.setItem("lastTimeStamp", timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem("lastTimeStamp");
      localStorage.removeItem("isForm");
    }
    timeChecker();
    setOpen(false);
  }, [userInfo.userIsAuthenticated]);

  const handleClose = () => {
    setOpen(false);
    resetTimer();
    logOut();
  };

  const handleContinue = () => {
    if (time >= 17970 && localStorage.getItem("isForm")) {
      setOpen(false);
      resetTimer();
    } else if (time < 17970 && localStorage.getItem("isForm")) {
      window.location.reload();
    } else {
      setOpen(false);
      acquireTokenSilent();
      resetTimer();
    }
  };

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, events, timeChecker]);

  if (!isOpen) {
    return null;
  }

  return (
    <Alert
      open={isOpen}
      handleClose={handleClose}
      handleContinue={handleContinue}
      setOpen={setOpen}
      time={time}
    />
  );
};

export default SessionTimeout;
