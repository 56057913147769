import React from 'react';
import { Grid, Typography, Box, Chip } from '@material-ui/core';

// Define the type for the range
interface Range {
  range: [number, number];
  color: string;
  name: string;
}

// Define the type for the data
interface Data {
  chartTitle: string;
  legendTitle: string;
  ranges: Range[];
}

// Define the component props
interface SummaryNavigationQualityAssetProps {
  data: Data;
}

const GradientLegend: React.FC<SummaryNavigationQualityAssetProps> = ({
  data: { legendTitle, ranges },
}) => {
  // Generate gradient and labels dynamically from ranges
  const gradient = ranges.map(range => range.color).join(', ');
  const labels = ranges.map(range => `${range.range[0]}-${range.range[1]}`);

  return (
    <Grid
      style={{
        backgroundColor: "#293368",
        padding: "30px",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
      }}
      item
      xs={12}
      sm={12}
      md={12}
    >
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#292E57",
          padding: "15px",
        }}
      >
        <Typography
          style={{
            color: "#FFF",
            fontWeight: "500",
            fontSize: "14px",
            padding: "10px",
            borderRadius: "8px",
          }}
        >
          {legendTitle}
        </Typography>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginLeft: "10px"
          }}
        >
          <Chip
            style={{
              width: "300px",
              height: "15px",
              marginTop: "12px",
              background: `linear-gradient(to right, ${gradient})`,
            }}
          />
          <Box
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "space-between",
            }}
          >
            {labels.map((label, index) => (
              <Typography key={index} style={{ color: "#FFF", marginLeft: index === 0 ? "10px" : "0px", marginRight: index === labels.length - 1 ? "10px" : "0px" }}>
                {label}
              </Typography>
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default GradientLegend;
