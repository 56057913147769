import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import DataTable from "../../../../../componets/DataTable";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { dashboardStyles } from "../DowntimeLandingPageStyles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getDowntimTicketsAlerts } from "../Services/SupplyChainTikcetService";
import { removeHtmlTags } from "../Helpers";
import SearchField from "./SearchField";
import ReplayIcon from "@mui/icons-material/Replay";

const DowntimeAlerts = (props: any): ReactElement => {
  const navigate = useNavigate();
  const usersList = useSelector((state: any) => state.usersList);
  const [downtimeAlerts, setDowntimeAlerts]: any = useState([]);
  const [totalCount, setToatlCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [ticketIdSearchText, setTicketIdSearchText] =
    React.useState<string>("");
  const [paginationOptions, setPaginationOptions] = useState({
    page: 1,
    limit: 5
  });
  const [sortingColumn, setSortingColumn]: any = useState({});
  const [debouncedSearchQuery, setDebouncedSearchQuery] = React.useState(0);
  const classes = dashboardStyles();
  const dispatch = useDispatch();
  const currentUser = usersList.currentUser;
  const downtimeTableAlertColumns = [
    {
      label: "Ticket ID",
      key: "dt_ticket_alias",
      dbColumnName: "dt_ticket_alias"
    },
    {
      label: "Type",
      key: "dt_ticket_type",
      dbColumnName: "dt_ticket_type"
    },
    {
      label: "Ticket Category",
      key: "dt_ticket_category",
      dbColumnName: "dt_ticket_category"
    },

    {
      label: "Alert",
      key: "alert_type",
      dbColumnName: "alert_type"
    },
    {
      label: "Description",
      key: "description",
      dbColumnName: "description",
      headerStyle: { maxWidth: "200px" },
      cellStyle: { maxWidth: "200px" }
    },
    {
      label: "Created on",
      key: "created_on",
      dbColumnName: "created_on"
    }
  ];

  React.useEffect(() => {
    // Use a timer to debounce the search query updates
    const debounceTimer = setTimeout(() => {
      setDebouncedSearchQuery(ticketIdSearchText.length);
    }, 500);

    // Cleanup the timer on every searchQuery change
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [ticketIdSearchText]);

  const getDowntimeAlerts = async (): Promise<any> => {
    try {
      setLoading(true);
      let payload: any = {
        user_id: currentUser?._id,
        page: paginationOptions?.page,
        limit: paginationOptions?.limit
      };

      if (ticketIdSearchText.length > 0) {
        payload.search = ticketIdSearchText;
      }

      if (Object.keys(sortingColumn).length > 0) {
        const sortColumnName = Object.keys(sortingColumn);
        const sortColunmOrder = Object.values(sortingColumn);
        payload.sort_by = sortColumnName[0];
        payload.sort_order = sortColunmOrder[0];
      }
      const response = await getDowntimTicketsAlerts(payload);
      if (response && response?.data?.rows) {
        const alerts: any = response?.data?.rows || [];
        setDowntimeAlerts(
          alerts?.map((item: any) => {
            return {
              ...item,
              created_on: `${moment(item?.created_on).format(
                "MM/DD/YYYY"
              )}, ${moment(item?.created_on).format("hh:mm A")} PST`,
              description: removeHtmlTags(item?.description)
            };
          })
        );
        setToatlCount(response?.data?.total);
      } else {
        setDowntimeAlerts([]);
      }
    } catch (error) {
      console.error("[getDowntimeAlerts]", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDowntimeAlerts();
  }, [
    paginationOptions?.page,
    paginationOptions?.limit,
    debouncedSearchQuery,
    sortingColumn
  ]);

  return (
    <Box className={classes.criticalControlSec}>
      <Grid style={{ margin: "25px", marginTop: "30px" }}>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            sx={{
              display: "flex",
              width: "50%",
              justifyContent: "space-between",
              marginBottom: "30px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "50%",
                justifyContent: "space-between"
              }}
            >
              <Button
                style={{ width: "65px", backgroundColor: "#8467ef" }}
                variant="outlined"
                onClick={() =>
                  navigate(`/usecase/downtime-request`, {
                    replace: true
                  })
                }
                startIcon={<ArrowBackIcon style={{ margin: "0px" }} />}
              />
            </Box>

            <Box style={{}}>
              <Typography
                variant="h2"
                textAlign="center"
                sx={{ fontSize: "18px" }}
              >
                Downtime Requests Notifications
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: "30px"
            }}
          >
            {/* Search by ticket id */}
            <SearchField
              className={`${classes.multiDropdown} ${classes.searchBox}`}
              defaultValue={""}
              handleChange={(event: any) => {
                setTicketIdSearchText(event.target.value);
              }}
              placeholder={"Search by Ticket ID"}
            />
            {/*  Reload action */}
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                getDowntimeAlerts();
              }}
              endIcon={<ReplayIcon />}
            >
              Reload
            </Button>
          </Box>
        </Grid>
        <DataTable
          columns={downtimeTableAlertColumns}
          data={downtimeAlerts}
          loading={loading}
          handleSort={(filter: any) => {
            const sortColumn = filter.sort;
            setSortingColumn({
              ...sortColumn
            });
          }}
          pagination={downtimeAlerts.length > 0 ? true : false}
          defaultEmptyMessage={"No data found"}
          paginationOptions={{
            count: totalCount,
            onRowsPerPageChange: (
              event: ChangeEvent<HTMLInputElement>
            ): void => {
              setLoading(true);
              setPaginationOptions({
                ...paginationOptions,
                limit: parseInt(event.target.value)
              });
            },
            onPageChange: (event: any, newPage: number): void => {
              setLoading(true);
              setPaginationOptions({
                ...paginationOptions,
                page: newPage + 1
              });
            },
            page: paginationOptions?.page - 1,
            rowsPerPage: paginationOptions?.limit,
            rowsPerPageOptions: [5, 10, 25, 50]
          }}
          sorting={true}
          handleRowClick={(row: any) => {
            // Setting up ticket details to redux state and navigation
            dispatch({
              type: "DOWN_TIME_TICKET",
              payload: row
            });

            if (row?.alert_type === "Delete") {
              dispatch({
                type: "LOAD_SNACKBAR",
                payload: {
                  message: "Ticket Deleted!",
                  type: "error",
                  open: true
                }
              });
            } else {
              navigate(
                `/usecase/downtime-request/list-details/${row?.dt_ticket_id}`,
                {
                  replace: true,
                  state: {
                    from: "alerts"
                  }
                }
              );
            }
          }}
        />
      </Grid>
    </Box>
  );
};

export default React.memo(DowntimeAlerts);
