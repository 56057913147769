import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import {
  Button,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Box,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, find, get, isEmpty } from "lodash";
import Lists from "./List";
import { useEffect, useState } from "react";
import { SelectChangeEvent, Stack, IconButton } from "@material-ui/core";
import Axios from "../../Utils/AxiosConfig/axiosConfigMonarch";
import {
  ASEPTIC_ICON_COMPONENT_OPTIONS,
  CCPS_MONITORED_COMPONENT_OPTIONS,
  LIST_COMPONENT_OPTIONS,
  DYNAMIC_QUERY_COMPONENT_OPTIONS,
  WQMS_TAG_PARAMETER_COMPONENT_OPTIONS,
  getAsepticIconItems,
  getCurrentUseCaseData,
  // getItemsFromLevelsList,
  getCCPsMonitoredItems,
  getListItems,
  isJson,
  getDynamicQueryItems,
  getWQMStagParameterItems,
  WQMS_RECIPE_MAPPING_COMPONENT_OPTIONS,
  getWQMSRecipeItems,
  WQMS_PLANT_BASED_LENGTH_COMPONENT_OPTIONS,
  getWQMSPlantBasedItems,
  STRING_COMPONENT_OPTIONS,
  getWQMSTagsEndingItems,
  TAG_GENERATOR_COMPONENT_OPTIONS,
  getTagGenerator,
  returnUpdatedTagGeneratorData,
  getDropDownOptionsForAsepticIcons,
  LIST_COMPONENT_WITH_NESTED_DATA,
  getListItemsWithNestedData,
  RH_GRADIENT_CONFIG_COMPONENT_OPTIONS,
  getGradientConfig,
  getVPPDConfig,
  RH_VP_PD_CONFIG_COMPONENT_OPTIONS,
  RH_GANTT_CHART_COMPONENT_OPTIONS,
  getRHGanttChartItems,
  getRHFinalConfigItems,
  RH_FINAL_CONFIG_COMPONENT_OPTIONS,
} from "../../Utils/SelfServiceHelper";
import AsepticIcon from "./AsepticIcon";
import CCPsMonitored from "./CCPsMonitored";
import DynamicQuery from "./DynamicQuery";
import WQMSRecipeMapping from "./WQMSRecipeMapping";
import WQMSPlantBasedLength from "./WQMSPlantBasedLength";
import WQMSTagsEndingString from "./WQMSTagsEndingString";
import TagGenerator from "./TagGenerator";
import RHGradientValues from "./RHGradientValues";
import RHGradientConfig from "./RHGradientConfig";
import RHVPPDConfig from "./RHVPPDConfig";
import RHGanttChart from "./RHGanttChart";
import RHFinalConfig from "./RHFinalConfig";

function ConfigureDashboardAssets(props: any) {
  const {
    open,
    handleClose,
    currentUseCase,
    selectedPlant,
    selectedParentAsset,
    selectedAssetType,
    tagsMasterList,
    assetTags,
  } = props;

  const [selectedOption, setOption] = useState("");
  const [isModified, setIsModified] = useState(false);
  const [isError, setIsError] = useState(false);
  const [updatedConfiguration, setConfiguration] = useState<string[]>([]);

  const seedsList = useSelector((state: any) => state.seedsList);
  const usersList = useSelector((state: any) => state.usersList);
  const usecaseList = useSelector((state: any) => state.usecaseList);
  const levelsList = useSelector((state: any) => state.levelsList);
  const plantsList = useSelector((state: any) => state.plantsList);
  const dispatch = useDispatch();

  const currentUser = usersList?.currentUser;

  const currentUseCaseData = getCurrentUseCaseData(
    currentUseCase,
    usecaseList?.usecases
  );

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "8px 24px 91px -11px rgba(12, 18, 52, 0.3)",
    p: "16px 40px",
    color: "white",
    borderRadius: "12px",
    bgcolor: "#293368",
    border: "#293368",
    ":focus": { outline: "none" },
    width: "auto",
    minWidth: 470,
  };

  const selfServiceConfig = find(seedsList.seeds, function (obj) {
    if (obj.seed_key_name === "SelfServiceConfig") {
      return true;
    }
  });

  const getSelectedSeedData = () => {
    if (!selectedOption) return {};

    // handled special case of 'Chlorine' which is saved in a separate seed.
    if (
      selectedOption === "WQMStagParameter" &&
      selectedPlant.plant_code === "JAX"
    ) {
      return find(seedsList.seeds, function (obj) {
        if (obj.seed_key_name === "WQMStagParameter_JAX") {
          return true;
        }
      });
    }

    return find(seedsList.seeds, function (obj) {
      if (obj.seed_key_name === selectedOption) {
        return true;
      }
    });
  };

  const selectedSeedData = getSelectedSeedData();

  const getConfigurationValue = () => {
    let returnData: any | { VP: any[]; PD: any[] } = [];

    const dataForHelperFunction = {
      selectedOption,
      selectedSeedData: getSelectedSeedData(),
      currentUseCase,
      selectedPlant,
      levelsList,
      seedsList,
      currentUseCaseData,
      selectedParentAsset,
      selectedAssetType,
      usecaseList,
      plantsList
    };

    if (LIST_COMPONENT_OPTIONS.includes(selectedOption))
      returnData = getListItems({
        ...dataForHelperFunction,
        tagsMasterList,
        assetTags,
      });

    if (LIST_COMPONENT_WITH_NESTED_DATA.includes(selectedOption))
      returnData = getListItemsWithNestedData(dataForHelperFunction);

    if (ASEPTIC_ICON_COMPONENT_OPTIONS.includes(selectedOption))
      returnData = getAsepticIconItems(dataForHelperFunction);

    if (CCPS_MONITORED_COMPONENT_OPTIONS.includes(selectedOption))
      returnData = getCCPsMonitoredItems(dataForHelperFunction);

    if (DYNAMIC_QUERY_COMPONENT_OPTIONS.includes(selectedOption))
      returnData = getDynamicQueryItems(dataForHelperFunction);

    if (WQMS_TAG_PARAMETER_COMPONENT_OPTIONS.includes(selectedOption))
      returnData = getWQMStagParameterItems(dataForHelperFunction);

    if (WQMS_RECIPE_MAPPING_COMPONENT_OPTIONS.includes(selectedOption))
      returnData = getWQMSRecipeItems(dataForHelperFunction);

    if (WQMS_PLANT_BASED_LENGTH_COMPONENT_OPTIONS.includes(selectedOption))
      returnData = getWQMSPlantBasedItems(dataForHelperFunction);

    if (STRING_COMPONENT_OPTIONS.includes(selectedOption))
      returnData = getWQMSTagsEndingItems(dataForHelperFunction);

    if (TAG_GENERATOR_COMPONENT_OPTIONS.includes(selectedOption))
      returnData = getTagGenerator(dataForHelperFunction);

    if (RH_GRADIENT_CONFIG_COMPONENT_OPTIONS.includes(selectedOption))
      returnData = getGradientConfig(dataForHelperFunction);

    if (RH_VP_PD_CONFIG_COMPONENT_OPTIONS.includes(selectedOption))
      returnData = getVPPDConfig(dataForHelperFunction);

    if (RH_GANTT_CHART_COMPONENT_OPTIONS.includes(selectedOption))
      returnData = getRHGanttChartItems(dataForHelperFunction);

    if (RH_FINAL_CONFIG_COMPONENT_OPTIONS.includes(selectedOption))
      returnData = getRHFinalConfigItems(dataForHelperFunction);

    return returnData;
  };

  useEffect(() => {
    setConfiguration(getConfigurationValue());
  }, [selectedOption]);

	if (currentUseCase !== 'AGR Inspection System' && (!selectedPlant?.plant_code || !selfServiceConfig?.seed_data_value))
		return null

  const menuData = find(selfServiceConfig.seed_data_value, function (obj) {
    if (obj.key === currentUseCase) {
      return true;
    }
  });

  const getMenuOptions = () => {
    const data = get(menuData, "value", null);
    if (isJson(data)) return JSON.parse(data);
    else return [data];
  };

  const menuOptions = getMenuOptions();

  if (!menuOptions) return null;

  const handleChange = (event: SelectChangeEvent) => {
    setOption(event.target.value);
  };

  const onClose = () => {
    setOption("");
    handleClose();
    setIsModified(false);
  };

  const onError = (errorValue: boolean) => {
    setIsError(errorValue);
  };

  const onSeqChange = (updatedData: any, overRideError: boolean = true) => {
    setConfiguration(updatedData);
    setIsModified(true);
    overRideError && setIsError(false);
  };
  const resetListItems = () => {
    const items = getConfigurationValue();
    setConfiguration(items);
    setIsModified(false);
  };

  const saveSeedData = async () => {
    let payload = {};

    if (isEmpty(selectedSeedData)) return;

    const selectedOptionSeedData = cloneDeep(selectedSeedData);
    const seedId = selectedOptionSeedData._id;

    if (LIST_COMPONENT_OPTIONS.includes(selectedOption)) {
      selectedOptionSeedData.seed_data_value.forEach((obj: any) => {
        if (obj.key === currentUseCase) {
          const parsedValueData = JSON.parse(obj.value);
          parsedValueData[selectedPlant.plant_code] = updatedConfiguration;
          obj.value = JSON.stringify(parsedValueData);
        }
      });
    }
    if (LIST_COMPONENT_WITH_NESTED_DATA.includes(selectedOption)) {
      let formattedData = updatedConfiguration.map((data: any) => {
        return { key: data.key, value: JSON.stringify(data.value) };
      });
      selectedOptionSeedData.seed_data_value = formattedData;
    }
    if (CCPS_MONITORED_COMPONENT_OPTIONS.includes(selectedOption)) {
      const allPlantKeys = selectedOptionSeedData.seed_data_value.map(
        (obj: any) => obj.key
      );
      if (allPlantKeys.includes(selectedPlant.plant_code)) {
        selectedOptionSeedData.seed_data_value.forEach((obj: any) => {
          if (obj.key === selectedPlant.plant_code) {
            obj.value = JSON.stringify(updatedConfiguration);
          }
        });
      } else {
        selectedOptionSeedData.seed_data_value.push({
          key: selectedPlant.plant_code,
          value: JSON.stringify(updatedConfiguration),
        });
      }
    }
    if (DYNAMIC_QUERY_COMPONENT_OPTIONS.includes(selectedOption)) {
      selectedOptionSeedData.seed_data_value.forEach((obj: any) => {
        if (obj.key === currentUseCase) {
          const parsedValueData = JSON.parse(obj.value);
          parsedValueData[selectedPlant.plant_code] = updatedConfiguration;
          obj.value = JSON.stringify(parsedValueData);
        }
      });
    }
    if (WQMS_TAG_PARAMETER_COMPONENT_OPTIONS.includes(selectedOption)) {
      selectedOptionSeedData.seed_data_value = updatedConfiguration;
    }
    if (WQMS_RECIPE_MAPPING_COMPONENT_OPTIONS.includes(selectedOption)) {
      selectedOptionSeedData.seed_data_value = updatedConfiguration;
    }
    if (WQMS_PLANT_BASED_LENGTH_COMPONENT_OPTIONS.includes(selectedOption)) {
      selectedOptionSeedData.seed_data_value = updatedConfiguration;
    }
    if (STRING_COMPONENT_OPTIONS.includes(selectedOption)) {
      selectedOptionSeedData.seed_data_value = updatedConfiguration;
    }
    if (ASEPTIC_ICON_COMPONENT_OPTIONS.includes(selectedOption)) {
      selectedOptionSeedData.seed_data_value = updatedConfiguration;
    }
    if (TAG_GENERATOR_COMPONENT_OPTIONS.includes(selectedOption)) {
      selectedOptionSeedData.seed_data_value.forEach((obj: any) => {
        if (obj.key === currentUseCase) {
          const parsedValueData = returnUpdatedTagGeneratorData({
            updatedConfiguration,
          });
          obj.value = JSON.stringify(parsedValueData);
        }
      });
    }
    if (RH_GRADIENT_CONFIG_COMPONENT_OPTIONS.includes(selectedOption)) {
      const allPlantKeys = selectedOptionSeedData.seed_data_value.map(
        (obj: any) => obj.key
      );
      if (allPlantKeys.includes(selectedPlant.plant_code)) {
        selectedOptionSeedData.seed_data_value.forEach((obj: any) => {
          if (obj.key === selectedPlant.plant_code) {
            obj.value = get(updatedConfiguration, "value", []);
          }
        });
      } else {
        selectedOptionSeedData.seed_data_value.push({
          key: selectedPlant.plant_code,
          value: get(updatedConfiguration, "value", []),
        });
      }
    }
    if (RH_VP_PD_CONFIG_COMPONENT_OPTIONS.includes(selectedOption)) {
      const allPlantKeys = selectedOptionSeedData.seed_data_value.map(
        (obj: any) => obj.key
      );
      if (allPlantKeys.includes(selectedPlant.plant_code)) {
        selectedOptionSeedData.seed_data_value.forEach((obj: any) => {
          if (obj.key === selectedPlant.plant_code) {
            obj.value = JSON.stringify(updatedConfiguration);
          }
        });
      } else {
        selectedOptionSeedData.seed_data_value.push({
          key: selectedPlant.plant_code,
          value: JSON.stringify(updatedConfiguration),
        });
      }
    }
    if (RH_GANTT_CHART_COMPONENT_OPTIONS.includes(selectedOption)) {
      selectedOptionSeedData.seed_data_value = updatedConfiguration;
    }
    if (RH_FINAL_CONFIG_COMPONENT_OPTIONS.includes(selectedOption)) {
      const allPlantKeys = selectedOptionSeedData.seed_data_value.map(
        (obj: any) => obj.key
      );
      if (allPlantKeys.includes(selectedPlant.plant_code)) {
        selectedOptionSeedData.seed_data_value.forEach((obj: any) => {
          if (obj.key === selectedPlant.plant_code) {
            obj.value = JSON.stringify(updatedConfiguration);
          }
        });
      } else {
        selectedOptionSeedData.seed_data_value.push({
          key: selectedPlant.plant_code,
          value: JSON.stringify(updatedConfiguration),
        });
      }
    }

    payload = {
      ...selectedOptionSeedData,
      seed_last_modified_by: currentUser.user_ad_name,
    };

    console.log("==== payload: ", payload);

    Axios.put(`seedconfig/${seedId}`, payload).then((data) => {
      dispatch({ type: "LOAD_SEEDS" });
      dispatch({
        type: "LOAD_SNACKBAR",
        payload: {
          message: "Seed Data updated successfully",
          type: "success",
          open: true,
        },
      });
      onClose();
    });
  };

  return (
    <Modal
      open={open}
      // onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        width: "auto",
        minWidth: 470,
      }}
    >
      <Box sx={style}>
        <Stack
          direction="row"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <IconButton aria-label="close" onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </Stack>
        <Typography
          style={{ marginBottom: "24px" }}
          id="modal-modal-title"
          variant="h3"
          component="h2"
        >
          Configure {currentUseCase} Dashboard
        </Typography>

        <FormControl fullWidth>
          <InputLabel id="simple-select-label">Select Category</InputLabel>
          <Select
            label="Select Category"
            value={selectedOption}
            onChange={handleChange}
          >
            {menuOptions.map((option: any) => (
              <MenuItem value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {LIST_COMPONENT_OPTIONS.includes(selectedOption) ? (
          <Lists
            items={updatedConfiguration}
            onSeqChange={onSeqChange}
            onError={onError}
          />
        ) : null}

        {LIST_COMPONENT_WITH_NESTED_DATA.includes(selectedOption) ? (
          <RHGradientValues
            items={updatedConfiguration}
            onSeqChange={onSeqChange}
            onError={onError}
            selectedPlant={selectedPlant}
          />
        ) : null}

        {ASEPTIC_ICON_COMPONENT_OPTIONS.includes(selectedOption) ? (
          <AsepticIcon
            items={updatedConfiguration}
            dropdownOptions={getDropDownOptionsForAsepticIcons({
              selectedOption,
              selectedSeedData,
              selectedPlant,
              levelsList,
              seedsList,
              currentUseCaseData,
              plantsList
            })}
            onSeqChange={onSeqChange}
            onError={onError}
          />
        ) : null}
        {CCPS_MONITORED_COMPONENT_OPTIONS.includes(selectedOption) ? (
          <CCPsMonitored
            items={updatedConfiguration}
            onSeqChange={onSeqChange}
            onError={onError}
            resetListItems={resetListItems}
          />
        ) : null}
        {DYNAMIC_QUERY_COMPONENT_OPTIONS.includes(selectedOption) ? (
          <DynamicQuery
            items={updatedConfiguration}
            onSeqChange={onSeqChange}
            onError={onError}
          />
        ) : null}
        {WQMS_TAG_PARAMETER_COMPONENT_OPTIONS.includes(selectedOption) ? (
          <Lists
            items={updatedConfiguration}
            onSeqChange={onSeqChange}
            onError={onError}
          />
        ) : null}
        {WQMS_RECIPE_MAPPING_COMPONENT_OPTIONS.includes(selectedOption) ? (
          <WQMSRecipeMapping
            items={updatedConfiguration}
            onSeqChange={onSeqChange}
            onError={onError}
          />
        ) : null}
        {WQMS_PLANT_BASED_LENGTH_COMPONENT_OPTIONS.includes(selectedOption) ? (
          <WQMSPlantBasedLength
            items={updatedConfiguration}
            onSeqChange={onSeqChange}
            onError={onError}
            selectedPlant={selectedPlant}
          />
        ) : null}
        {STRING_COMPONENT_OPTIONS.includes(selectedOption) ? (
          <WQMSTagsEndingString
            items={updatedConfiguration}
            onSeqChange={onSeqChange}
            onError={onError}
            selectedPlant={selectedPlant}
          />
        ) : null}
        {TAG_GENERATOR_COMPONENT_OPTIONS.includes(selectedOption) ? (
          <TagGenerator
            items={updatedConfiguration}
            onSeqChange={onSeqChange}
            onError={onError}
          />
        ) : null}
        {RH_GRADIENT_CONFIG_COMPONENT_OPTIONS.includes(selectedOption) ? (
          <RHGradientConfig
            items={updatedConfiguration}
            onSeqChange={onSeqChange}
            onError={onError}
          />
        ) : null}
        {RH_VP_PD_CONFIG_COMPONENT_OPTIONS.includes(selectedOption) ? (
          <RHVPPDConfig
            items={updatedConfiguration}
            dropdownOptions={usersList.users}
            onSeqChange={onSeqChange}
            onError={onError}
          />
        ) : null}
        {RH_GANTT_CHART_COMPONENT_OPTIONS.includes(selectedOption) ? (
          <RHGanttChart
            items={updatedConfiguration}
            dropdownOptions={usersList.users}
            onSeqChange={onSeqChange}
            onError={onError}
          />
        ) : null}
        {RH_FINAL_CONFIG_COMPONENT_OPTIONS.includes(selectedOption) ? (
          <RHFinalConfig
            items={updatedConfiguration}
            onSeqChange={onSeqChange}
            onError={onError}
            resetListItems={resetListItems}
          />
        ) : null}

        {selectedOption ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="outlined"
              disabled={!isModified || isError}
              onClick={resetListItems}
              sx={{
                marginTop: "16px",
              }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              disabled={!isModified || isError}
              onClick={saveSeedData}
              sx={{
                marginTop: "16px",
              }}
            >
              Save
            </Button>
          </div>
        ) : null}
      </Box>
    </Modal>
  );
}

export default ConfigureDashboardAssets;
