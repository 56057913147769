import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { Plant } from "../../../models/Types_Interfaces";
import { useNavigate } from "react-router-dom";
import {
  getFormatedDate,
  getTimeZoneTimeStamp,
} from "../../../Utils/UtilFunctions/timeZoneFormatter";
import { GREEN, RED, YELLOW } from "../../../Utils/UtilFunctions/constant";

import { ReactComponent as DangerIcon } from "../../../Icons/AlertCancelIcon.svg";
import { ReactComponent as MonitorIcon } from "../../../Icons/MonitorIcon.svg";
import { ReactComponent as TickIcon } from "../../../Icons/AlertTickIcon.svg";

function BlockedCavityPopUp(props: any) {
  const ticketsList = useSelector((state: any) => state.ticketsList);
  const plantsList = useSelector((state: any) => state.plantsList);
  const messagesList = useSelector((state: any) => state.messagesList);
  const navigate = useNavigate();
  return (
    <Modal open={props.open} onClose={props.handleClose} closeAfterTransition>
      <Fade in={props.open}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: window?.innerWidth < 400 ? "300px" : "550px",
            boxShadow: "24",
            padding: "16px 40px",
            color: "white",
            borderRadius: "12px",
            backgroundColor:
              props?.alert?.message_data?.Block >= 3
                ? RED
                : props?.alert?.message_data?.Block >= 1 &&
                  props?.alert?.message_data?.Block < 3
                ? YELLOW
                : props?.alert?.message_data?.Block < 1
                ? GREEN
                : "",

            border:
              props?.alert?.message_data?.Block >= 3
                ? `3px solid ${RED}`
                : props?.alert?.message_data?.Block >= 1 &&
                  props?.alert?.message_data?.Block < 3
                ? `3px solid ${YELLOW}`
                : props?.alert?.message_data?.Block < 1
                ? `3px solid ${GREEN}`
                : "",
          }}
        >
          <div
            style={{
              marginTop: "20px",
            }}
          >
            {props?.alert?.message_data?.Block >= 3 ? (
              <DangerIcon />
            ) : props?.alert?.message_data?.Block >= 1 &&
              props?.alert?.message_data?.Block < 3 ? (
              <MonitorIcon />
            ) : props?.alert?.message_data?.Block < 1 ? (
              <TickIcon />
            ) : null}
          </div>

          <div
            style={{
              marginBottom: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h5 style={{ fontSize: "1.2rem" }}>
              {plantsList.plants.find((plant: Plant) => {
                return plant._id === props?.alert?.plant_id;
              }) !== undefined
                ? plantsList.plants.find((plant: Plant) => {
                    return plant._id === props?.alert?.plant_id;
                  }).plant_name
                : "Plant not found"}
            </h5>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                color: "black",
                padding: "12px",
                borderRadius: "3px",
                boxShadow: "1px 1px 0px 0px #000000",
                width: "100%",
                margin: "5px",
              }}
            >
              <h5
                style={{
                  fontWeight: "800",
                  color: "#000",
                  textAlign: "left",
                  fontSize: "12px",
                }}
              >
                Machine Name
              </h5>

              <p style={{ textAlign: "left", fontSize: "12px" }}>
                {props?.alert?.asset_alias ?? "NA"}
              </p>
            </div>
          </div>

          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              color: "black",
              padding: "12px",
              borderRadius: "3px",
              boxShadow: "1px 1px 0px 0px #000000",
              width: "98%",
              margin: "5px",
            }}
          >
            <h5
              style={{
                fontWeight: "800",
                color: "#000",
                fontSize: "12px",
              }}
            >
              Description
            </h5>

            <p>
              {props.alert.message_data !== undefined &&
                `On  ${
                  props.alert?.timestamp !== undefined &&
                  props.alert?.timestamp !== null &&
                  getFormatedDate(
                    plantsList.plants.find((plant: any) => {
                      return plant._id === props.alert.plant_id;
                    })?.plant_timezone,
                    getTimeZoneTimeStamp(
                      plantsList.plants.find((plant: any) => {
                        return plant._id === props.alert.plant_id;
                      })?.plant_timezone,
                      props.alert?.timestamp
                    ),
                    "MM/DD/yyyy"
                  )
                } at ${
                  props.alert?.timestamp !== undefined &&
                  props.alert?.timestamp !== null &&
                  getFormatedDate(
                    plantsList.plants.find((plant: any) => {
                      return plant._id === props?.alert?.plant_id;
                    })?.plant_timezone,
                    getTimeZoneTimeStamp(
                      plantsList.plants.find((plant: any) => {
                        return plant._id === props?.alert?.plant_id;
                      })?.plant_timezone,
                      props.alert?.timestamp
                    ),
                    "hh:mm A z"
                  )
                }, the blocked cavities are ${Number(
                  props.alert.message_data?.Block
                )}`}
            </p>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <button
              style={{
                color: "black",
                backgroundColor: "white",
                marginLeft: "2em",
                margin: "20px 5px",
                width: "45%",
                padding: "8px",
                borderRadius: "8px",
                fontWeight: "bold",
              }}
              onClick={() => {
                props.handleClose();
              }}
            >
              Close
            </button>

            <button
              style={{
                color: "black",
                backgroundColor: "white",
                marginLeft: "2em",
                margin: "20px 5px",
                width: "45%",
                padding: "8px",
                borderRadius: "8px",
                fontWeight: "bold",
              }}
              onClick={() => {
                props.setOpen(false);
                navigate(
                  `/maintenance/ticket-details/${props?.alert?.ticket_id}/${props?.alert?.message_id}`,
                  {
                    replace: true,
                  }
                );
              }}
              disabled={ticketsList.loading || messagesList.loading}
            >
              {(ticketsList.loading || messagesList.loading) && (
                <CircularProgress
                  sx={{
                    color: "black",
                    height: "1px",
                    margin: "0px 20px 0px 10px",
                  }}
                  size={18}
                />
              )}
              Go to Ticket
            </button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default BlockedCavityPopUp;
