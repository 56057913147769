import {
    SCHEDULER_CURRENT_DATE,
    SCHEDULER_CURRENT_DATE_ERROR,
    SCHEDULER_CURRENT_DATE_SUCCESS,
    SCHEDULER_CURRENT_VIEW,
    SCHEDULER_CURRENT_VIEW_ERROR,
    SCHEDULER_CURRENT_VIEW_SUCCESS,
    SCHEDULER_FILTER_DATA,
    SCHEDULER_FILTER_DATA_ERROR,
    SCHEDULER_FILTER_DATA_SUCCESS,
    VIEW_ASSET,
    VIEW_ASSET_ERROR,
    VIEW_ASSET_SUCCESS,
  } from "../Types/schdeuler.constant";
  
  const INITIAL_STATE = {
    loading: false,
    details: {},
    error: null,
    currDate: null,
    currView: "",
    schedulerFilters: {},
  };
  
  export const schedulerReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
      case VIEW_ASSET:
        return {
          ...state,
          loading: true,
          details: state.details,
        };
      case VIEW_ASSET_SUCCESS:
        return {
          ...state,
          loading: false,
          details: action.payload,
        };
      case VIEW_ASSET_ERROR:
        return { ...state, loading: false, error: action.payload };
      case SCHEDULER_CURRENT_DATE:
        return {
          ...state,
          loading: true,
          currDate: state.currDate,
        };
      case SCHEDULER_CURRENT_DATE_SUCCESS:
        return {
          ...state,
          loading: false,
          currDate: action.payload,
        };
      case SCHEDULER_CURRENT_DATE_ERROR:
        return { ...state, loading: false, error: action.payload };
      case SCHEDULER_CURRENT_VIEW:
        return {
          ...state,
          loading: true,
          currView: state.currView,
        };
      case SCHEDULER_CURRENT_VIEW_SUCCESS:
        return {
          ...state,
          loading: false,
          currView: action.payload,
        };
      case SCHEDULER_CURRENT_VIEW_ERROR:
        return { ...state, loading: false, error: action.payload };
      case SCHEDULER_FILTER_DATA:
        return {
          ...state,
          loading: true,
          schedulerFilters: state.schedulerFilters,
        };
      case SCHEDULER_FILTER_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          schedulerFilters: action.payload,
        };
      case SCHEDULER_FILTER_DATA_ERROR:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  