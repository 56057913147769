import {
  LOAD_ALL_USERS_IMAGE,
  LOAD_ALL_USERS_IMAGE_SUCCESS,
  LOAD_ALL_USERS_IMAGE_ERROR,
} from "../Types/userImages.constants";

const INITIAL_STATE = {
  loading: false,
  users: [],
  error: null,
};

export const usersImagesReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_ALL_USERS_IMAGE:
      return { ...state, loading: true, users: [], error: null };
    case LOAD_ALL_USERS_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case LOAD_ALL_USERS_IMAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
