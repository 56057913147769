const getSeedValues = (
  seedsList: any,
  seedName: string,
  useCaseName: string,
  plantCode: string
) => {
  const seedData =
    seedsList.seeds.length > 0 &&
    seedsList.seeds
      .find((seed: any) => {
        return seed.seed_key_name === seedName;
      })
      ?.seed_data_value?.find((useCases: any) => {
        return useCases.key === useCaseName;
      });

  return seedData !== undefined &&
    seedData.value !== undefined &&
    Object.keys(JSON.parse(seedData?.value)).includes(plantCode)
    ? JSON.parse(seedData?.value)[plantCode]
    : [];
};

const sortAssetOrder = (a: any, b: any, orderArrayForAssets: any) => {
  return orderArrayForAssets !== undefined && orderArrayForAssets.length !== 0
    ? orderArrayForAssets.findIndex((temp: any, index: any) => {
        return a.asset_name.toLowerCase().includes(temp.toLowerCase());
      }) >
      orderArrayForAssets.findIndex((temp: any, index: any) => {
        return b.asset_name.toLowerCase().includes(temp.toLowerCase());
      })
      ? 1
      : orderArrayForAssets.findIndex((temp: any, index: any) => {
          return b.asset_name.toLowerCase().includes(temp.toLowerCase());
        }) >
        orderArrayForAssets.findIndex((temp: any, index: any) => {
          return a.asset_name.toLowerCase().includes(temp.toLowerCase());
        })
      ? -1
      : 0
    : a.asset_name.toLowerCase() > b.asset_name.toLowerCase()
    ? 1
    : b.asset_name.toLowerCase() > a.asset_name.toLowerCase()
    ? -1
    : 0;
};

const sortParentOrder = (a: any, b: any, orderArray: any, customKey: string = 'asset_name') => {
  return orderArray !== undefined && orderArray.length !== 0
    ? orderArray.findIndex((temp: any, index: any) => {
        return a[customKey].toLowerCase().includes(temp.toLowerCase());
      }) >
      orderArray.findIndex((temp: any, index: any) => {
        return b[customKey].toLowerCase().includes(temp.toLowerCase());
      })
      ? 1
      : orderArray.findIndex((temp: any, index: any) => {
          return b[customKey].toLowerCase().includes(temp.toLowerCase());
        }) >
        orderArray.findIndex((temp: any, index: any) => {
          return a[customKey].toLowerCase().includes(temp.toLowerCase());
        })
      ? -1
      : 0
    : a[customKey].toLowerCase() > b[customKey].toLowerCase()
    ? 1
    : b[customKey].toLowerCase() > a[customKey].toLowerCase()
    ? -1
    : 0;
};

export { getSeedValues, sortAssetOrder, sortParentOrder };
