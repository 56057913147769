import {
  LOAD_OEM,
  LOAD_OEM_SUCCESS,
  LOAD_OEM_ERROR,
} from "../Types/OEM.constants";

const INITIAL_STATE = {
  loading: false,
  OEMs: [],
  error: null,
};

export const OEMListReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_OEM:
      return { ...state, loading: true, OEMs: [] };
    case LOAD_OEM_SUCCESS:
      return {
        ...state,
        loading: false,
        OEMs: action.payload,
      };
    case LOAD_OEM_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
