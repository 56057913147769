import { useState } from "react";

interface useLoaderParams {
  initialValue?: boolean;
}

type useLoaderType = (params?: useLoaderParams) => {
  startLoading: () => void;
  endLoading: () => void;
  isLoading: boolean;
};

const useLoader: useLoaderType = (params = {}) => {
  const { initialValue = false } = params;

  const [isLoading, setIsLoading] = useState<boolean>(initialValue);

  const startLoading = () => setIsLoading(true);

  const endLoading = () => setIsLoading(false);

  return { startLoading, endLoading, isLoading };
};

export { useLoader };
