import {
  LOAD_MODELS,
  LOAD_MODELS_SUCCESS,
  LOAD_MODELS_ERROR,
} from "../Types/models.constants";

const INITIAL_STATE = {
  loading: false,
  models: [],
  error: null,
};

export const modelsListReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_MODELS:
      return { ...state, loading: true, models: [] };
    case LOAD_MODELS_SUCCESS:
      return {
        ...state,
        loading: false,
        models: action.payload,
      };
    case LOAD_MODELS_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
