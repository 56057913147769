import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { groupBy } from "lodash";
import AtlPlantImg from "../Component/plantImage/ATL.png";
import AlaPlantImg from "../Component/plantImage/ALA.png";
import BalPlantImg from "../Component/plantImage/BAL.png";
import BayPlantImg from "../Component/plantImage/BAY.png";
import BlmPlantImg from "../Component/plantImage/BLM.png";
import CarPlantImg from "../Component/plantImage/CAR.jpeg";
import ColPlantImg from "../Component/plantImage/COL.png";
import DetPlantImg from "../Component/plantImage/DET.png";
import HazPlantImg from "../Component/plantImage/HAZ.png";
import HouPlantImg from "../Component/plantImage/HOU.png";
import JaxPlantImg from "../Component/plantImage/JAX.png";
import Kc2PlantImg from "../Component/plantImage/KC2.png";
import KenPlantImg from "../Component/plantImage/KEN.png";
import KncPlantImg from "../Component/plantImage/KNC.png";
import LanPlantImg from "../Component/plantImage/LAN.png";
import LasPlantImg from "../Component/plantImage/LAS.png";
import LouPlantImg from "../Component/plantImage/LOU.png";
import MesPlantImg from "../Component/plantImage/MES.png";
import MiaPlantImg from "../Component/plantImage/MIA.png";
import MisPlantImg from "../Component/plantImage/MIS.png";
import MorPlantImg from "../Component/plantImage/MOR.png";
import RchPlantImg from "../Component/plantImage/RCH.png";
import SanPlantImg from "../Component/plantImage/SAN.png";
import Sl2PlantImg from "../Component/plantImage/SL2.png";
import St3PlantImg from "../Component/plantImage/ST3.png";
import TacPlantImg from "../Component/plantImage/TAC.png";
import TemPlantImg from "../Component/plantImage/TEM.png";
import {
  SingleCubeDataPoint,
  SingleLgv,
  HeatmapRespData,
  GroupedData,
  RangeConfig,
  NavQualityWdhHgt,
  SummaryConfig,
  TimeFilterRange,
} from "./interfaces";
import { curry } from "lodash";
import {
  getFormatedDate,
  getTimeZoneTimeStamp,
} from "../../../../../Utils/UtilFunctions/timeZoneFormatter";
import moment from "moment";

export const getPlantCoordinates = (
  plant: string,
  renderObject?: string
): object | undefined => {
  switch (plant) {
    case "CAR": {
      if (renderObject === "lgv" || renderObject === "heatmap") {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 14500,
          rectTopRightX: 453500,
          rectTopRightY: 168000,
        };
      } else if (renderObject === "block" || renderObject === "rack") {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 14500,
          rectTopRightX: 455000,
          rectTopRightY: 168000,
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 450000,
          rectTopRightY: 171000,
        };
      }
    }
    case "ATL": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 82000,
          rectBottomLeftY: 30500,
          rectTopRightX: 463000,
          rectTopRightY: 148000,
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 85000, //R->L
          rectBottomLeftY: 32000, //T->B
          rectTopRightX: 472000, //R->L shrink
          rectTopRightY: 155000, // T->B shrink
        };
      } else {
        return {
          //map
          rectBottomLeftX: 47000, //15000
          rectBottomLeftY: 13000, //333000
          rectTopRightX: 523000, //0
          rectTopRightY: 171000, //143000
        };
      }
    }
    case "DET": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 127000, //R->L
          rectBottomLeftY: -55000, //T->B
          rectTopRightX: 427000, //R->L shrink
          rectTopRightY: 182000, // T->B shrink
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 127000, //R->L
          rectBottomLeftY: 36000, //T->B
          rectTopRightX: 447000, //R->L shrink
          rectTopRightY: 170000, // T->B shrink
        };
      } else {
        return {
          //map
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "ALA": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 105000, //R->L
          rectBottomLeftY: 25000, //T->B
          rectTopRightX: 410000, //R->L shrink
          rectTopRightY: 172000, // T->B shrink
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 105000, //R->L
          rectBottomLeftY: 25000, //T->B
          rectTopRightX: 410000, //R->L shrink
          rectTopRightY: 190000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 20000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "HAZ": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 237000, //R->L
          rectBottomLeftY: 230000, //T->B
          rectTopRightX: 832000, //R->L shrink
          rectTopRightY: 450000, // T->B shrink
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 237000, //R->L
          rectBottomLeftY: 270000, //T->B
          rectTopRightX: 832000, //R->L shrink
          rectTopRightY: 470000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "HOU": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 108000, //R->L
          rectBottomLeftY: 13000, //T->B
          rectTopRightX: 472000, //R->L shrink
          rectTopRightY: 121000, // T->B shrink
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 109000, //R->L
          rectBottomLeftY: 33000, //T->B
          rectTopRightX: 462000, //R->L shrink
          rectTopRightY: 151000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "LAS": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 81000, //R->L
          rectBottomLeftY: -16000, //T->B
          rectTopRightX: 370000, //R->L shrink
          rectTopRightY: 142000, // T->B shrink
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 81000, //R->L
          rectBottomLeftY: 33000, //T->B
          rectTopRightX: 370000, //R->L shrink
          rectTopRightY: 169000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "MIA": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 81000, //R->L
          rectBottomLeftY: 0, //T->B
          rectTopRightX: 355000, //R->L shrink
          rectTopRightY: 155000, // T->B shrink
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 81000, //R->L
          rectBottomLeftY: 33000, //T->B
          rectTopRightX: 355000, //R->L shrink
          rectTopRightY: 190000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "TAC": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 90000, //R->L
          rectBottomLeftY: -30000, //T->B
          rectTopRightX: 315000, //R->L shrink
          rectTopRightY: 140000, // T->B shrink
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 47000, //R->L
          rectBottomLeftY: 17500, //T->B
          rectTopRightX: 385000, //R->L shrink
          rectTopRightY: 167000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "TACLGV": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 90000, //R->L
          rectBottomLeftY: -30000, //T->B
          rectTopRightX: 315000, //R->L shrink
          rectTopRightY: 140000, // T->B shrink
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 47000, //R->L
          rectBottomLeftY: 17500, //T->B
          rectTopRightX: 385000, //R->L shrink
          rectTopRightY: 167000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "JAX": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 80000, //R->L
          rectBottomLeftY: -20000, //T->B
          rectTopRightX: 525000, //R->L shrink
          rectTopRightY: 170000, // T->B shrink
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 80000, //R->L
          rectBottomLeftY: 20500, //T->B
          rectTopRightX: 525000, //R->L shrink
          rectTopRightY: 200000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "LAN": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 10000, //R->L
          rectBottomLeftY: -50000, //T->B
          rectTopRightX: 524000, //R->L shrink
          rectTopRightY: 190000, // T->B shrink
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: -2000, //R->L
          rectBottomLeftY: 20500, //T->B
          rectTopRightX: 524000, //R->L shrink
          rectTopRightY: 245000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "ST3": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 80000, //R->L
          rectBottomLeftY: -100000, //T->B
          rectTopRightX: 462500, //R->L shrink
          rectTopRightY: 140000, // T->B shrink
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 80000, //R->L
          rectBottomLeftY: 20500, //T->B
          rectTopRightX: 462500, //R->L shrink
          rectTopRightY: 165000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "BAL": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 93000, //R->L
          rectBottomLeftY: -100000, //T->B
          rectTopRightX: 463500, //R->L shrink BAL
          rectTopRightY: 140000, // T->B shrink
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 93000, //R->L
          rectBottomLeftY: 1500, //T->B
          rectTopRightX: 463500, //R->L shrink BAL
          rectTopRightY: 169000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "KC2": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 93000, //R->L
          rectBottomLeftY: -100000, //T->B
          rectTopRightX: 463500, //R->L shrink BAL
          rectTopRightY: 140000, // T->B shrink
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 91500, //R->L
          rectBottomLeftY: 1500, //T->B
          rectTopRightX: 459900, //R->L shrink BAL
          rectTopRightY: 169000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "MOR": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 93000, //R->L
          rectBottomLeftY: -100000, //T->B
          rectTopRightX: 463500, //R->L shrink BAL
          rectTopRightY: 140000, // T->B shrink
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 78500, //R->L
          rectBottomLeftY: 1800, //T->B
          rectTopRightX: 499500, //R->L shrink BAL
          rectTopRightY: 168500, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 0, //R->L
          rectBottomLeftY: 0, //T->B
          rectTopRightX: 0, //R->L shrink
          rectTopRightY: 0, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 529700,
          rectTopRightY: 161000,
        };
      }
    }
    case "TEM": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 93000, //R->L
          rectBottomLeftY: -100000, //T->B
          rectTopRightX: 463500, //R->L shrink BAL
          rectTopRightY: 140000, // T->B shrink
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: -46800, //R->L
          rectBottomLeftY: -55500, //T->B
          rectTopRightX: 350500, //R->L shrink TEM
          rectTopRightY: 121000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 0, //R->L
          rectBottomLeftY: 0, //T->B
          rectTopRightX: 0, //R->L shrink
          rectTopRightY: 0, // T->B shrink
        };
      } else if (renderObject === "dockdoors") {
        return {
          rectBottomLeftX: -36800, //R->L
          rectBottomLeftY: -71500, //T->B
          rectTopRightX: 326500, //R->L shrink TEM
          rectTopRightY: 139000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 161000,
        };
      }
    }
    case "LOU": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 82000,
          rectBottomLeftY: 30500,
          rectTopRightX: 463000,
          rectTopRightY: 148000,
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 92000, //R->L
          rectBottomLeftY: 13000, //T->B
          rectTopRightX: 445000, //R->L shrink LOU
          rectTopRightY: 148500, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "BAY": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 82000,
          rectBottomLeftY: 30500,
          rectTopRightX: 463000,
          rectTopRightY: 148000,
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 93000, //R->L
          rectBottomLeftY: 1500, //T->B
          rectTopRightX: 425000, //R->L shrink BAL
          rectTopRightY: 170000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "KNC": {
      //KNC PLANT
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 110000,
          rectBottomLeftY: 25500,
          rectTopRightX: 403000,
          rectTopRightY: 208000,
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 14000, //R->L
          rectBottomLeftY: 55500, //T->B
          rectTopRightX: 300000, //R->L shrink
          rectTopRightY: 212000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 50000,
          rectBottomLeftY: 16000,
          rectTopRightX: 583000,
          rectTopRightY: 171000,
        };
      }
    }
    case "COL": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 82000,
          rectBottomLeftY: -40500,
          rectTopRightX: 333000,
          rectTopRightY: 120000,
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 212500, //R->L
          rectBottomLeftY: 14000, //T->B
          rectTopRightX: 450200, //R->L shrink COL
          rectTopRightY: 148500, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "RCH": {
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 91600,
          rectBottomLeftY: 30500,
          rectTopRightX: 488000,
          rectTopRightY: 148000,
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 61000, //R->L
          rectBottomLeftY: 24000, //T->B
          rectTopRightX: 462000, //R->L shrink RCH
          rectTopRightY: 159000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 47000,
          rectBottomLeftY: 13000,
          rectTopRightX: 523000,
          rectTopRightY: 171000,
        };
      }
    }
    case "BLM": {
      //KNC PLANT
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 110000,
          rectBottomLeftY: 22500,
          rectTopRightX: 390000,
          rectTopRightY: 178000,
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 14000, //R->L
          rectBottomLeftY: 56500, //T->B
          rectTopRightX: 317000, //R->L shrink BLM
          rectTopRightY: 190000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 50000,
          rectBottomLeftY: 16000,
          rectTopRightX: 583000,
          rectTopRightY: 171000,
        };
      }
    }
    case "KEN": {
      //KEN PLANT
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 74000,
          rectBottomLeftY: -9500,
          rectTopRightX: 420000,
          rectTopRightY: 148000,
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 1000, //R->L
          rectBottomLeftY: 67000, //T->B
          rectTopRightX: 342000, //R->L shrink KEN
          rectTopRightY: 170000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 50000,
          rectBottomLeftY: 16000,
          rectTopRightX: 583000,
          rectTopRightY: 171000,
        };
      }
    }
    case "SAN": {
      //SAN PLANT
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 55000,
          rectBottomLeftY: 22500,
          rectTopRightX: 465000,
          rectTopRightY: 208000,
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 11000, //R->L
          rectBottomLeftY: 36500, //T->B
          rectTopRightX: 460000, //R->L shrink BLM
          rectTopRightY: 158000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 50000,
          rectBottomLeftY: 14000,
          rectTopRightX: 583000,
          rectTopRightY: 171000,
        };
      }
    }
    case "SL2": {
      //SL2 PLANT
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 110000,
          rectBottomLeftY: 23500,
          rectTopRightX: 300000,
          rectTopRightY: 188000,
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: -6500, //R->L
          rectBottomLeftY: 69500, //T->B
          rectTopRightX: 189500, //R->L shrink SL2
          rectTopRightY: 190000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 50000,
          rectBottomLeftY: 16000,
          rectTopRightX: 583000,
          rectTopRightY: 171000,
        };
      }
    }
    case "MES": {
      //MES PLANT
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 110000,
          rectBottomLeftY: 13500,
          rectTopRightX: 490000,
          rectTopRightY: 228000,
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 14000, //R->L
          rectBottomLeftY: 58500, //T->B
          rectTopRightX: 387000, //R->L shrink BLM
          rectTopRightY: 197000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 50000,
          rectBottomLeftY: 15000,
          rectTopRightX: 583000,
          rectTopRightY: 191000,
        };
      }
    }
    case "MIS": {
      //MIS PLANT
      if (renderObject === "heatmap") {
        return {
          rectBottomLeftX: 110000,
          rectBottomLeftY: 13500,
          rectTopRightX: 490000,
          rectTopRightY: 228000,
        };
      } else if (renderObject === "lgv") {
        return {
          rectBottomLeftX: 20000, //R->L
          rectBottomLeftY: 66000, //T->B
          rectTopRightX: 368000, //R->L shrink MIS
          rectTopRightY: 220000, // T->B shrink
        };
      } else if (renderObject === "destinations") {
        return {
          rectBottomLeftX: 88000, //R->L
          rectBottomLeftY: -42500, //T->B
          rectTopRightX: -292000, //R->L shrink
          rectTopRightY: -450000, // T->B shrink
        };
      } else {
        return {
          rectBottomLeftX: 50000,
          rectBottomLeftY: 15000,
          rectTopRightX: 583000,
          rectTopRightY: 191000,
        };
      }
    }
  }
};

export const mapCoordinate = (
  coordinateX: number,
  coordinateY: number,
  renderObject: string,
  locationCode: string
): { x: number; y: number } | undefined => {
  const rectBottomLeftXNew = 195;
  const rectBottomLeftYNew = 500;
  const rectTopRightXNew = 1440;
  const rectTopRightYNew = 5.453125;

  if (locationCode) {
    const {
      rectBottomLeftX,
      rectBottomLeftY,
      rectTopRightX,
      rectTopRightY,
    }: any = getPlantCoordinates(locationCode, renderObject);

    const ratioX =
      (coordinateX - rectBottomLeftX) / (rectTopRightX - rectBottomLeftX);
    const ratioY =
      (coordinateY - rectBottomLeftY) / (rectTopRightY - rectBottomLeftY);

    const translatedX =
      rectBottomLeftXNew + ratioX * (rectTopRightXNew - rectBottomLeftXNew);
    const translatedY =
      rectBottomLeftYNew + ratioY * (rectTopRightYNew - rectBottomLeftYNew);

    return {
      x: translatedX,
      y: translatedY,
    };
  }
};

export const getHeatmapWeightage = (
  navQuality: number,
  segmentId: number,
  heatMapConfig: any
): { fillOpacity: number; fillColor?: string } => {
  if (
    !heatMapConfig ||
    !Array.isArray(heatMapConfig) ||
    heatMapConfig.length === 0
  ) {
    return { fillOpacity: 0 };
  }

  if (segmentId > 0) {
    const heatMapEntry = heatMapConfig.find(
      (config: any) =>
        navQuality >= config.range[0] && navQuality <= config.range[1]
    );
    if (heatMapEntry) {
      return { fillOpacity: 1, fillColor: heatMapEntry.color };
    }
  }
  return { fillOpacity: 0 }; // Default return if no valid segmentId or navQuality range
};

export const setDynamicZoomLevelToMap = (scaleLevel: string, mapId: string) => {
  const instance: any = document.getElementById(mapId);
  if (instance) {
    switch (scaleLevel) {
      case "0.8": {
        instance.style.transform = "scale(0.8) translate(20px, 50px)";
        break;
      }
      case "0.9": {
        instance.style.transform = "scale(0.9) translate(20px, 50px)";
        break;
      }
      case "1.0": {
        instance.style.transform = "scale(1.0) translate(20px, 50px)";
        break;
      }
      case "1.1": {
        instance.style.transform = "scale(1.1) translate(20px, 50px)";
        break;
      }
      case "1.2": {
        instance.style.transform = "scale(1.2) translate(120px, 50px)";
        break;
      }
      case "1.3": {
        instance.style.transform = "scale(1.3) translate(120px, 50px)";
        break;
      }
      case "1.4": {
        instance.style.transform = "scale(1.4) translate(210px, 90px)";
        break;
      }
      case "1.6": {
        instance.style.transform = "scale(1.6) translate(265px, 120px)";
        break;
      }
      case "1.8": {
        instance.style.transform = "scale(1.8) translate(325px, 135px)";
        break;
      }
      case "2.0": {
        instance.style.transform = "scale(2.0) translate(360px, 155px)";
        break;
      }
      case "2.2": {
        instance.style.transform = "scale(2.2) translate(380px, 175px)";
        break;
      }
      case "2.4": {
        instance.style.transform = "scale(2.4) translate(400px, 195px)";
        break;
      }
      case "2.6": {
        instance.style.transform = "scale(2.6) translate(420px, 215px)";
        break;
      }
      case "2.8": {
        instance.style.transform = "scale(2.8) translate(440px, 235px)";
        break;
      }
      case "3.0": {
        instance.style.transform = "scale(3.0) translate(460px, 255px)";
        break;
      }
    }
  }
};

export const getIdealAxisPosition = (
  windowWidth: number
): { xAxisX: number; xAxisY: number; yAxisX: number; yAxisY: number } => {
  let idealAxisPosition: any = {};
  if (windowWidth >= 5000 && windowWidth < 6000) {
    idealAxisPosition = {
      xAxisX: -2000,
      xAxisY: -50,
      yAxisX: -2110,
      yAxisY: -30,
    };
  } else if (windowWidth >= 3000 && windowWidth < 4000) {
    idealAxisPosition = {
      xAxisX: -1350,
      xAxisY: -220,
      yAxisX: -1470,
      yAxisY: -200,
    };
  } else if (windowWidth >= 2051 && windowWidth <= 2732) {
    idealAxisPosition = {
      xAxisX: -750,
      xAxisY: -400,
      yAxisX: -820,
      yAxisY: -380,
    };
  } else if (windowWidth >= 1800 && windowWidth < 2500) {
    idealAxisPosition = {
      xAxisX: -400,
      xAxisY: -490,
      yAxisX: -520,
      yAxisY: -470,
    };
  } else {
    // windowWidth >= 1440 && windowWidth < 1800
    idealAxisPosition = {
      xAxisX: -300,
      xAxisY: -520,
      yAxisX: -400,
      yAxisY: -500,
    };
  }
  return idealAxisPosition;
};

//chat popup on hover
export const handleMouseOver = (event: any, data: any, heatMapConfig: any) => {
  // data - receiving two different set of information form heatmap and dynamic plant layout.
  if (data?.segmentId > 0) {
    const rectangle = event.target;
    const popupContainer = document.querySelector(".custom-popup");
    if (popupContainer) {
      const parentContainer = popupContainer.parentElement?.parentElement;
      if (parentContainer) {
        parentContainer.style.setProperty("background-color", "#232A58");
      }
    }

    const customPopupContent = `<div style="background-color: #232A58; padding: 10px; border-radius: 12px; color: white; margin: 0 !important;">
    <p style="color: white; margin-bottom: -1em; margin-top: 0em;">
      Segment ID:
      <span>
        <b>${data?.segmentId}</b>
      </span>
    </p>
    <p style="color: white;margin-bottom: -1em;">
      X Position:
      <span>
        <b>${data?.xPos}</b>
      </span>
    </p>
    <p style="color: white; margin-bottom: -1em;">
      Y Position:
      <span>
        <b>${data?.yPos}</b>
      </span>
    </p>
    <p style="color: white; margin-bottom: -0.5em;">
      Navigation Quality:
      <span>
        <b style="color:${getGradientColor(
          data?.navQuality,
          heatMapConfig
        )};">${data?.navQuality}</b>
      </span>
    </p>
  </div>`;

    rectangle
      .bindPopup(customPopupContent, {
        closeButton: false,
        className: "custom-popup",
        offset: [-32, -28],
      })
      .openPopup();
  }
};

//chat popup close
export const handleMouseOut = (event?: any) => {
  const rectangle = event.target;
  rectangle.closePopup();
};

export const checkZoomInLimit = (mapId: string): boolean | undefined => {
  const idInstance: any = document.getElementById(mapId);
  const styleInstance: any = document.getElementById("map-zoom-in-btn");
  if (idInstance && styleInstance) {
    if (idInstance.style.transform.includes("scale(1.6)")) {
      styleInstance.style.cursor = "no-drop";
      return true;
    } else {
      styleInstance.style.cursor = "pointer";
      return false;
    }
  }
};

export const checkZoomOutLimit = (mapId: string): boolean | undefined => {
  const idInstance: any = document.getElementById(mapId);
  const styleInstance: any = document.getElementById("map-zoom-out-btn");
  if (idInstance && styleInstance) {
    if (idInstance.style.transform === "scale(1.0)") {
      styleInstance.style.cursor = "no-drop";
      return true;
    } else {
      styleInstance.style.cursor = "pointer";
      return false;
    }
  }
};

export const zoomIn = (mapId: string, setMapContainerZoomLevel?: any): void => {
  const idInstance: any = document.getElementById(mapId);
  if (idInstance) {
    const mapContainerZoom: any = idInstance.style.transform;

    if (setMapContainerZoomLevel) {
      setMapContainerZoomLevel(mapContainerZoom);
    }
    if (mapContainerZoom.includes("scale(0.9)")) {
      idInstance.style.transform = "scale(1) translate(100px, 55px)";
    }
    if (mapContainerZoom.includes("scale(1)")) {
      idInstance.style.transform = "scale(1.1) translate(100px, 55px)";
    }
    if (mapContainerZoom.includes("scale(1.1)")) {
      idInstance.style.transform = "scale(1.2) translate(130px, 80px)";
    }
    if (mapContainerZoom.includes("scale(1.2)")) {
      idInstance.style.transform = "scale(1.3) translate(160px, 100px)";
    }
    if (mapContainerZoom.includes("scale(1.3)")) {
      idInstance.style.transform = "scale(1.4) translate(190px, 100px)";
    }
    if (mapContainerZoom.includes("scale(1.4)")) {
      idInstance.style.transform = "scale(1.5) translate(210px, 110px)";
    }
    if (mapContainerZoom.includes("scale(1.5)")) {
      idInstance.style.transform = "scale(1.6) translate(230px, 120px)";
    }
  }
};

export const zoomOut = (
  mapId: string,
  setMapContainerZoomLevel?: any
): void => {
  const idInstance: any = document.getElementById(mapId);
  if (idInstance) {
    const mapContainerZoom = idInstance.style.transform;

    if (setMapContainerZoomLevel) {
      setMapContainerZoomLevel(mapContainerZoom);
    }
    if (mapContainerZoom.includes("scale(1.6)")) {
      idInstance.style.transform = "scale(1.5) translate(210px, 110px)";
    }
    if (mapContainerZoom.includes("scale(1.5)")) {
      idInstance.style.transform = "scale(1.4) translate(190px, 100px)";
    }
    if (mapContainerZoom.includes("scale(1.4)")) {
      idInstance.style.transform = "scale(1.3) translate(160px, 100px)";
    }
    if (mapContainerZoom.includes("scale(1.3)")) {
      idInstance.style.transform = "scale(1.2) translate(130px, 80px)";
    }
    if (mapContainerZoom.includes("scale(1.2)")) {
      idInstance.style.transform = "scale(1.1) translate(100px, 55px)";
    }
    if (mapContainerZoom.includes("scale(1.1)")) {
      idInstance.style.transform = "scale(1) translate(20px, 50px)";
    }
    if (mapContainerZoom.includes("scale(1)")) {
      idInstance.style.transform = "scale(0.9) translate(20px, 50px)";
    }
  }
};

export const getPlantLayoutImg = (plantCode: string) => {
  switch (plantCode) {
    case "HOU":
      return HouPlantImg;
    case "ALA":
      return AlaPlantImg;
    case "ATL":
      return AtlPlantImg;
    case "BAL":
      return BalPlantImg;
    case "BAY":
      return BayPlantImg;
    case "BLM":
      return BlmPlantImg;
    case "CAR":
      return CarPlantImg;
    case "COL":
      return ColPlantImg;
    case "DET":
      return DetPlantImg;
    case "HAZ":
      return HazPlantImg;
    case "JAX":
      return JaxPlantImg;
    case "KC2":
      return Kc2PlantImg;
    case "KEN":
      return KenPlantImg;
    case "KNC":
      return KncPlantImg;
    case "LAN":
      return LanPlantImg;
    case "LAS":
      return LasPlantImg;
    case "LOU":
      return LouPlantImg;
    case "MES":
      return MesPlantImg;
    case "MIA":
      return MiaPlantImg;
    case "MIS":
      return MisPlantImg;
    case "MOR":
      return MorPlantImg;
    case "RCH":
      return RchPlantImg;
    case "SAN":
      return SanPlantImg;
    case "SL2":
      return Sl2PlantImg;
    case "ST3":
      return St3PlantImg;
    case "TAC":
      return TacPlantImg;
    case "TACLGV":
      return TacPlantImg;
    case "TEM":
      return TemPlantImg;
    default:
      return "";
  }
};

export const timeFilterRanges: TimeFilterRange[] = [
  {
    label: "Last 5 mins",
    placement: "left",
    difference: moment.duration(5, "minutes"),
    default: false,
  },
  {
    label: "Last 15 mins",
    placement: "left",
    difference: moment.duration(15, "minutes"),
    default: false,
  },
  {
    label: "Last 30 mins",
    placement: "left",
    difference: moment.duration(30, "minutes"),
    default: false,
  },
  {
    label: "Last 1 hour",
    placement: "left",
    difference: moment.duration(1, "hours"),
    default: false,
  },
  {
    label: "Last 1.5 hours",
    placement: "left",
    difference: moment.duration(90, "minutes"),
    default: false,
  },
  {
    label: "Last 2 hours",
    placement: "left",
    difference: moment.duration(2, "hours"),
    default: false,
  },
  {
    label: "Last 3 hours",
    placement: "left",
    difference: moment.duration(3, "hours"),
    default: false,
  },
  {
    label: "Last 4 hours",
    placement: "left",
    difference: moment.duration(4, "hours"),
    default: false,
  },
  {
    label: "Last 5 hours",
    placement: "left",
    difference: moment.duration(5, "hours"),
    default: false,
  },
  {
    label: "Last 6 hours",
    placement: "left",
    difference: moment.duration(6, "hours"),
    default: false,
  },
  {
    label: "Last 7 hours",
    placement: "left",
    difference: moment.duration(7, "hours"),
    default: false,
  },
  {
    label: "Last 8 hours",
    placement: "left",
    difference: moment.duration(8, "hours"),
    default: false,
  },
  {
    label: "Last 9 hours",
    placement: "left",
    difference: moment.duration(9, "hours"),
    default: false,
  },
  {
    label: "Last 10 hours",
    placement: "left",
    difference: moment.duration(10, "hours"),
    default: false,
  },
  {
    label: "Last 11 hours",
    placement: "left",
    difference: moment.duration(11, "hours"),
    default: false,
  },
  {
    label: "Last 12 hours",
    placement: "left",
    difference: moment.duration(12, "hours"),
    default: false,
  },
  {
    label: "Last 24 hours",
    placement: "left",
    difference: moment.duration(24, "hours"),
    default: true,
  },
  {
    label: "Last 2 days",
    placement: "left",
    difference: moment.duration(2, "days"),
    default: false,
  },
  {
    label: "Last 5 days",
    placement: "left",
    difference: moment.duration(5, "days"),
    default: false,
  },
  {
    label: "Last 7 days",
    placement: "left",
    difference: moment.duration(7, "days"),
    default: false,
  },
  {
    label: "Last 15 days",
    placement: "left",
    difference: moment.duration(15, "days"),
    default: false,
  },
  {
    label: "Last 30 days",
    placement: "left",
    difference: moment.duration(30, "days"),
    default: false,
  },
  {
    label: "Last Shift 6AM-6PM",
    placement: "left",
    default: false,
    difference: moment.duration(12, "hours"),
  },
  {
    label: "Last Shift 6PM-6AM",
    placement: "left",
    default: false,
    difference: moment.duration(12, "hours"),
  },
];

export const lgvNameRenderer = (vehicleNumber: string): string => {
  const lgvNumber: string = vehicleNumber?.includes("LGV")
    ? vehicleNumber.split("V")[1]
    : vehicleNumber;
  return lgvNumber;
};

export const convertReactComponentToHtmlIcon = (
  reactComponent: any,
  className: any,
  iconSize: any,
  iconAnchor: any
): any => {
  const reactToHtml = ReactDOMServer.renderToString(reactComponent);
  return L.divIcon({
    className,
    iconSize,
    iconAnchor: iconAnchor ? iconAnchor : null,
    html: reactToHtml,
  });
};

export const coordinateCorrection = (
  locationCode: string,
  lgvCorrectionValues: any
): { x: number; y: number } => {
  return lgvCorrectionValues[locationCode];
};

export const getSeedConfigValue = (
  seedsList: any,
  seedName: string,
  subUseCaseName: string
) => {
  // Find the seed in the seed list
  const config = seedsList?.seeds?.find(
    (seed: any) => seed?.seed_key_name === seedName
  )?.seed_data_value;

  if (config) {
    // Find the sub use case in the list
    const filteredConfig = config.filter(
      (item: any) => item?.key === subUseCaseName
    );
    if (filteredConfig.length > 0) {
      return JSON.parse(filteredConfig[0]?.value);
    } else {
      return null;
    }
  }
  return null;
};

export const formatDate = (
  date: Date,
  timeZone = "America/Chicago"
): string => {
  // const month = date.toLocaleString('default', { month: 'short' })
  // const day = date.getDate()
  // const hours = date.getHours().toString().padStart(2, '0')
  // const minutes = date.getMinutes().toString().padStart(2, '0')
  // return `${month} ${day}, ${date.getFullYear()} ${hours}:${minutes}`
  return getFormatedDate(
    timeZone,
    getTimeZoneTimeStamp(
      timeZone,
      moment.utc(date).valueOf(),
      "YYYY-MM-DD hh:mma"
    ),
    "MM/DD hh:mma"
  );
};

type SelectorType = "number" | "string";
type ConditionsType = {
  upperLimit?: number;
  lowerLimit?: number;
  value: any;
};

export class Selector {
  type: SelectorType;
  conditions: ConditionsType[];
  default: any;

  constructor(type: SelectorType = "number") {
    this.type = type;
    this.conditions = [];
    this.default = undefined;
  }

  addLessThan(upperLimit: number, value: any): Selector {
    this.conditions.push({
      upperLimit,
      value,
    });

    return this;
  }

  addGreaterThan(lowerLimit: number, value: any): Selector {
    this.conditions.push({
      lowerLimit,
      value,
    });

    return this;
  }

  addRange(lowerLimit: number, upperLimit: number, value: any): Selector {
    this.conditions.push({
      lowerLimit,
      upperLimit,
      value,
    });

    return this;
  }

  addDefault(value: any): Selector {
    this.default = value;

    return this;
  }

  get(n: number): any {
    const value = this.conditions.find(
      ({ upperLimit: less, lowerLimit: greater }) =>
        (less ? n <= less : true) && (greater ? n >= greater : true)
    )?.value;

    return typeof value === undefined ? this.default : value;
  }
}

export const transformData = (data: [SingleCubeDataPoint]) => {
  const startTime = data[0]["TACLGVOutOfSpec.timestamp"];
  const endTime = data[data.length - 1]["TACLGVOutOfSpec.timestamp"];

  const completeLGVObj: SingleLgv = {
    xPos: undefined,
    yPos: undefined,
    headingAngle: undefined,
    segmentId: undefined,
    reflectorsSeen: undefined,
    reflectorUsed: undefined,
    navQuality: undefined,
    timeStamp: new Date(),
  };
  const lgvNames: { [key: string]: number } = {};
  const timestampedData: {
    [key: string]: { [key: string]: SingleLgv };
  } = {};
  const finalData: { [key: number]: SingleLgv }[] = [];

  const addData = (
    data: SingleCubeDataPoint,
    timestamp: Date,
    timestampString: string,
    name: string
  ) => {
    let lgvObj = { ...completeLGVObj };
    lgvObj.timeStamp = timestamp;

    if (
      timestampedData[timestampString] &&
      Object.keys(timestampedData[timestampString]).includes(name)
    ) {
      lgvObj = timestampedData[timestampString][name];
    }
    switch (data["TACLGVOutOfSpec.description"]) {
      case "NavigationalQuality":
        lgvObj.navQuality = parseInt(data["TACLGVOutOfSpec.Value"]);
        break;
      case "NavigationSeenReflectors":
        lgvObj.reflectorsSeen = parseInt(data["TACLGVOutOfSpec.Value"]);
        break;
      case "LGVX":
        lgvObj.xPos = parseFloat(data["TACLGVOutOfSpec.Value"]);
        break;
      case "LGVY":
        lgvObj.yPos = parseFloat(data["TACLGVOutOfSpec.Value"]);
        break;
      case "NavigationUsedReflectors":
        lgvObj.reflectorUsed = parseInt(data["TACLGVOutOfSpec.Value"]);
        break;
      case "LGVHeadingAngle":
        lgvObj.headingAngle = parseFloat(data["TACLGVOutOfSpec.Value"]);
        break;
      case "CurrentSegmentID":
        lgvObj.segmentId = parseInt(data["TACLGVOutOfSpec.Value"]);
        break;
    }
    timestampedData[timestampString][name] = lgvObj;
  };

  const genAllTimestamps = (start: string, end: string) => {
    const startDate = new Date(start + "Z");
    const endDate = new Date(end + "Z");

    const startTime = startDate.getTime();
    const endTime = endDate.getTime();
    const secondsDifference = (endTime - startTime) / 1000;

    for (let i = 0; i <= secondsDifference; i++) {
      const newDate = new Date(startTime + i * 1000);
      const newDateISOString = newDate.toISOString();
      timestampedData[
        newDateISOString.substring(0, newDateISOString.length - 1)
      ] = {};
    }
  };

  const genMissingData = () => {
    for (const timestamp of Object.keys(timestampedData)) {
      for (const lgv of Object.keys(lgvNames)) {
        if (!timestampedData[timestamp][lgv]) {
          const lgvObj = { ...completeLGVObj };
          lgvObj.timeStamp = new Date(timestamp + "Z");
          timestampedData[timestamp][lgv] = lgvObj;
        }
      }
      finalData.push(timestampedData[timestamp]);
    }
  };

  genAllTimestamps(startTime, endTime);

  for (const dataPt of data) {
    const name = dataPt["TACLGVOutOfSpec.deviceName"].substring(3);
    const timestamp = dataPt["TACLGVOutOfSpec.timestamp"];
    lgvNames[name] = 1;
    addData(dataPt, new Date(timestamp + "Z"), timestamp, name);
  }

  genMissingData();
  return finalData;
};

export const transformToHeatMapData = (data: [SingleCubeDataPoint]) => {
  const ignoreDescriptions = new Set([
    "LGVHeadingAngle",
    "NavigationSeenReflectors",
    "NavigationUsedReflectors",
  ]);

  const filteredData = data.filter(
    (record) => !ignoreDescriptions.has(record["TACLGVOutOfSpec.description"])
  );
  const grouped = groupBy(
    filteredData,
    (record) =>
      `${record["TACLGVOutOfSpec.deviceName"]}_${record["TACLGVOutOfSpec.timestamp"]}`
  );

  const result = Object.entries(grouped).map(([key, records]) => {
    const [deviceName, timestamp] = key.split("_");
    const baseObj: HeatmapRespData = {
      xPos: undefined,
      yPos: undefined,
      lgvId: deviceName,
      locationCode: "lgv",
      segmentId: undefined,
      navQuality: undefined,
      timeStamp: `${timestamp}Z`,
    };

    records.forEach((record) => {
      switch (record["TACLGVOutOfSpec.description"]) {
        case "LGVX":
          baseObj.xPos = parseFloat(record["TACLGVOutOfSpec.Value"]);
          break;
        case "LGVY":
          baseObj.yPos = parseFloat(record["TACLGVOutOfSpec.Value"]);
          break;
        case "CurrentSegmentID":
          baseObj.segmentId = parseInt(record["TACLGVOutOfSpec.Value"], 10);
          break;
        case "NavigationalQuality":
          baseObj.navQuality = parseFloat(record["TACLGVOutOfSpec.Value"]);
          break;
        default:
          break;
      }
    });

    return baseObj;
  });
  return result;
};

//filter latest lgv records based on time stamp
export const getLatestLgvRecords = (filteredLayoutData: any) => {
  const latestRecords: any = {};
  let latestRecordsArray: any = [];
  filteredLayoutData.forEach((item: any) => {
    const { lgvId, timeStamp } = item;
    // If the vehicle number is not in latestRecords or the current timestamp is later than the stored timestamp
    if (
      !(lgvId in latestRecords) ||
      timeStamp > latestRecords[lgvId].timeStamp
    ) {
      latestRecords[lgvId] = item;
    }
  });
  latestRecordsArray = Object.values(latestRecords);
  return latestRecordsArray;
};

export const populateFormattedRows = (groupedData: GroupedData): number[][] => {
  const formattedRows: number[][] = [[], [], []];

  Object.keys(groupedData).forEach((segmentIdStr) => {
    const segmentId = Number(segmentIdStr);
    const avgNavQuality =
      groupedData[segmentId].navQualitySum / groupedData[segmentId].count;

    if (avgNavQuality >= 0 && avgNavQuality <= 20) {
      formattedRows[0].push(segmentId);
    } else if (avgNavQuality > 20 && avgNavQuality <= 60) {
      formattedRows[1].push(segmentId);
    } else if (avgNavQuality > 60 && avgNavQuality <= 100) {
      formattedRows[2].push(segmentId);
    }
  });

  return formattedRows;
};

export const call = (fn?: (...args: any[]) => any, ...params: any[]): any => {
  if (fn) {
    return fn(...params);
  }
};

export const limit = curry((limit: number, records: any[]) => {
  const totalRecords = records.length;
  const maxIndex = totalRecords - 1;
  const intervalSize = maxIndex / (limit - 1);
  const filteredRecords = [];

  if (maxIndex <= limit) return records;

  for (let i = 0; i < limit; i += 1) {
    const index = Math.round(i * intervalSize);
    filteredRecords.push(records[index]);
  }

  return filteredRecords;
});

export const getGradientColor = (
  value: number,
  config: { ranges: RangeConfig[] }
): any => {
  if (value >= config?.ranges[2]?.range[0]) return config?.ranges[2]?.color;

  const hexToRgb = (
    hex: string
  ): { r: number; g: number; b: number } | null => {
    // Remove the hash symbol if it's there
    hex = hex.replace(/^#/, "");

    // Parse the hex values
    if (hex.length === 3) {
      hex = hex
        .split("")
        .map((h) => h + h)
        .join("");
    }

    if (hex.length !== 6) {
      throw new Error(`Invalid hex color: ${hex}`);
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return { r, g, b };
  };

  const componentToHex = (c: number): string => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const rgbToHex = (r: number, g: number, b: number): string => {
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
  };

  const startColor = config?.ranges[0]?.color
    ? hexToRgb(config?.ranges[0]?.color)
    : null;
  const endColor = config?.ranges[1]?.color
    ? hexToRgb(config?.ranges[1]?.color)
    : null;

  const ratio =
    (value - config?.ranges[0]?.range[0]) /
    (config?.ranges[1]?.range[1] - config?.ranges[0]?.range[0]);

  if (startColor && endColor) {
    const r = Math.round(startColor.r + ratio * (endColor.r - startColor.r));
    const g = Math.round(startColor.g + ratio * (endColor.g - startColor.g));
    const b = Math.round(startColor.b + ratio * (endColor.b - startColor.b));

    return rgbToHex(r, g, b);
  }
};

export const getNavQualityWdhHgt = (
  mapContainerZoom: any
): NavQualityWdhHgt => {
  if (mapContainerZoom.includes("scale(0.9)")) {
    return {
      path: { width: "12px", height: "12px" },
      icon: { width: "18px", height: "18px", fontSize: "12px" },
    };
  } else if (mapContainerZoom.includes("scale(1)")) {
    return {
      path: { width: "10px", height: "10px" },
      icon: { width: "16.5px", height: "16.5px", fontSize: "11px" },
    };
  } else if (mapContainerZoom.includes("scale(1.1)")) {
    return {
      path: { width: "9px", height: "9px" },
      icon: { width: "15px", height: "15px", fontSize: "10px" },
    };
  } else if (mapContainerZoom.includes("scale(1.2)")) {
    return {
      path: { width: "8px", height: "8px" },
      icon: { width: "13.5px", height: "13.5px", fontSize: "9px" },
    };
  } else if (mapContainerZoom.includes("scale(1.3)")) {
    return {
      path: { width: "7px", height: "7px" },
      icon: { width: "12px", height: "12px", fontSize: "8px" },
    };
  } else if (mapContainerZoom.includes("scale(1.4)")) {
    return {
      path: { width: "6px", height: "6px" },
      icon: { width: "10.5px", height: "10.5px", fontSize: "7px" },
    };
  } else if (mapContainerZoom.includes("scale(1.5)")) {
    return {
      path: { width: "5px", height: "5px" },
      icon: { width: "9px", height: "9px", fontSize: "6px" },
    };
  } else if (mapContainerZoom.includes("scale(1.6)")) {
    return {
      path: { width: "4px", height: "4px" },
      icon: { width: "7.5px", height: "7.5px", fontSize: "5px" },
    };
  } else {
    return {
      path: { width: "14px", height: "14px" },
      icon: { width: "18px", height: "18px", fontSize: "12px" },
    };
  }
};

export const getNavQualityColor = (
  navQuality: number,
  ranges: SummaryConfig["ranges"]
): string => {
  const rangeEntry = ranges.find(
    (range: any) => navQuality >= range.range[0] && navQuality <= range.range[1]
  );
  return rangeEntry ? rangeEntry.color : "#000000"; // Default color if no match found
};

// interface SensorStatus {
// 	dirty: boolean
// 	alarm: boolean
// }

// interface LGVSensors {
// 	forwardSensor: SensorStatus
// 	verticalRightSensor: SensorStatus
// 	verticalLeftSensor: SensorStatus
// 	backwardRightSensor: SensorStatus
// 	backwardLeftSensor: SensorStatus
// 	backwardSensor:SensorStatus
// }

// interface SensorsData {
// 	[lgvId: string]: LGVSensors[]
// }

export const transformToPlsData = (data: [SingleCubeDataPoint]) => {
  const grouped = groupBy(
    data,
    (record) =>
      `${record["TACLGVOutOfSpec.deviceName"]}_${record["TACLGVOutOfSpec.timestamp"]}`
  );

  const result = Object.entries(grouped).map(([key, records]) => {
    const [deviceName, timestamp] = key.split("_");
    const baseObj: any = {
      lgvId: deviceName,
      backwardLeftSensorDirty: undefined,
      backwardRightSensorDirty: undefined,
      backwardSensorDirty: undefined,
      forwardSensorDirty: undefined,
      verticalLeftSensorDirty: undefined,
      verticalRightSensorDirty: undefined,
      timeStamp: `${timestamp}Z`,
    };

    records.forEach((record) => {
      switch (record["TACLGVOutOfSpec.description"]) {
        case "PLS Backward Left Sensor Dirty COV Values":
          baseObj.backwardLeftSensorDirty = record["TACLGVOutOfSpec.Value"];
          break;
        case "PLS Backward Right Sensor Dirty COV Values":
          baseObj.backwardRightSensorDirty = record["TACLGVOutOfSpec.Value"];
          break;
        case "PLS Backward Sensor Dirty COV Values":
          baseObj.backwardSensorDirty = record["TACLGVOutOfSpec.Value"];
          break;
        case "PLS Forward Sensor Dirty COV Values":
          baseObj.forwardSensorDirty = record["TACLGVOutOfSpec.Value"];
          break;
        case "PLS Vertical Left Sensor Dirty COV Values":
          baseObj.verticalLeftSensorDirty = record["TACLGVOutOfSpec.Value"];
          break;
        case "PLS Vertical Right Sensor Dirty COV Values":
          baseObj.verticalRightSensorDirty = record["TACLGVOutOfSpec.Value"];
          break;
        default:
          break;
      }
    });

    return baseObj;
  });
  return result;
};

export const capitalizeString = (status: string): string => {
  return status.charAt(0).toUpperCase() + status.slice(1);
};
