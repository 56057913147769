import {
  LOAD_VISUALS,
  LOAD_VISUALS_SUCCESS,
  LOAD_VISUALS_ERROR,
} from "../Types/visuals.constants";

const INITIAL_STATE = {
  loading: false,
  visuals: [],
  error: null,
};

export const visualsListReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_VISUALS:
      return { ...state, loading: true, visuals: [] };
    case LOAD_VISUALS_SUCCESS:
      return {
        ...state,
        loading: false,
        visuals: action.payload,
      };
    case LOAD_VISUALS_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
