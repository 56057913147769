import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { CustomProvider, DateRangePicker } from "rsuite";
import { Plant, TimeFilterRange } from "../Helpers/interfaces";
import { LGVHealthContext } from "./Context";

const useStyles = makeStyles(() => ({
  gridPadding: {
    padding: "0px 0 0 20px",
  },
  gridContainerPadding: {
    padding: "20px",
    background: "#293368",
  },
  chipStyles: {
    "&.MuiChip-root": {
      margin: "5px 20px",
      backgroundColor: "#8476EF",
      borderRadius: "16px",
      padding: "4px",
      color: "#FFFFFFDE",
      letterSpacing: "0.25px",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      height: "32px",
    },
  },
  inputFieldLabelPosition: {
    backgroundColor: "#293368",
    borderRadius: "14px",
    padding: "0px 10px !important",
    marginTop: "3px",
  },
}));

interface TimeFilterProps {
  startDateTime: Date;
  endDateTime: Date;
  setStartDateTime: Dispatch<SetStateAction<Date>>;
  setEndDateTime: Dispatch<SetStateAction<Date>>;
  selectedPlant: Plant;
  timeFilterRanges: TimeFilterRange[];
  margin?: string;
}

const TimeFilter: FC<TimeFilterProps> = ({
  startDateTime,
  endDateTime,
  setStartDateTime,
  setEndDateTime,
  timeFilterRanges,
  margin,
}) => {
  const classes = useStyles();

  const { selectedPlant } = useContext(LGVHealthContext);

  const [dateRangeFilterLabel, setDateRangeFilterLabel] = useState(() => {
    const diff = moment(endDateTime).diff(startDateTime, "seconds");

		const range = timeFilterRanges.find(
			range => diff - range.difference.asSeconds() <= 1
		)

    return range?.label || "custom";
  });

  const handleDateRangeFilter = (range: TimeFilterRange) => {
    const now = moment().tz(selectedPlant.plant_timezone);

    let start: Date, end: Date;

    // Checking last shift conditions
    if (range.label === "Last Shift 6AM-6PM") {
      // if today's shift is in progress
      const diff = moment.duration(now.hour() < 18 ? 1 : 0, "d");
      const date = now.subtract(diff).set({ hour: 6, minutes: 0, seconds: 0 });

      start = date.toDate();

      end = date.add(range.difference).toDate();
    } else if (range.label === "Last Shift 6PM-6AM") {
      // if today's shift is in progress
      const diff = moment.duration(now.hour() < 6 ? 2 : 1, "d");
      const date = now
        .subtract(diff, "days")
        .set({ hour: 18, minutes: 0, seconds: 0 });

      start = date.toDate();

      end = date.add(range.difference).toDate();
    } else {
      // remaining selected date ranges
      start = now.clone().subtract(range.difference).toDate();
      end = now.toDate();
    }

    setStartDateTime(start);
    setEndDateTime(end);
    setDateRangeFilterLabel(range.label);
  };

  const customChangeHandler = (newSelectedDateRange: [Date, Date] | null) => {
    if (newSelectedDateRange === null) return;

    const [start, end] = newSelectedDateRange;

    setStartDateTime(convertDateToTimeZone(start));
    setEndDateTime(convertDateToTimeZone(end));
  };

  const convertDateToTimeZone = (date: Date): Date => {
    const local = moment(date).format("YYYY-MM-DD HH:mm:ss");
    return moment.tz(local, selectedPlant.plant_timezone).toDate();
  };

  const convertDateToLocal = (date: Date): Date => {
    const local = moment(date)
      .tz(selectedPlant.plant_timezone)
      .format("YYYY-MM-DD HH:mm:ss");
    return moment(local).toDate();
  };

  return (
    <Grid item xs={12}>
      <FormControl
        margin="dense"
        variant="outlined"
        style={{
          width: 300,
        }}
        size="medium"
      >
        <InputLabel className={classes.inputFieldLabelPosition}>
          Select Date Range
        </InputLabel>
        <Select label="Type" value={dateRangeFilterLabel}>
          {timeFilterRanges.map((ele, index) => {
            return (
              <MenuItem
                key={index}
                value={ele.label}
                onClick={() => {
                  handleDateRangeFilter(ele);
                }}
              >
                {ele.label}
              </MenuItem>
            );
          })}
          <MenuItem
            value="custom"
            onClick={() => setDateRangeFilterLabel("custom")}
          >
            Custom
          </MenuItem>
        </Select>
      </FormControl>
      {dateRangeFilterLabel === "custom" && (
        <CustomProvider theme="dark">
          <DateRangePicker
            format="yyyy-MM-dd hh:mm:ss aa"
            showMeridian
            disabledDate={(date) => moment(date).isAfter(moment())}
            style={{
              marginLeft: "1em",
              width: 300,
              maxHeight: 5,
              marginTop: margin || "1.3em",
              padding: "none",
              marginBottom: "2em",
            }}
            value={[
              convertDateToLocal(startDateTime),
              convertDateToLocal(endDateTime),
            ]}
            placeholder="Set Date Range"
            onChange={customChangeHandler}
            size="lg"
          />
        </CustomProvider>
      )}
    </Grid>
  );
};

export default memo(TimeFilter);
