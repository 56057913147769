import {
  LOAD_ASSET_TYPES,
  LOAD_ASSET_TYPES_SUCCESS,
  LOAD_ASSET_TYPES_ERROR,
} from "../Types/assetTypes.constants";

const INITIAL_STATE = {
  loading: false,
  assetTypes: [],
  error: null,
};

export const assetTypesListReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_ASSET_TYPES:
      return { ...state, loading: true, assetTypes: [] };
    case LOAD_ASSET_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        assetTypes: action.payload,
      };
    case LOAD_ASSET_TYPES_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
