import React from 'react';
import { clone, isArray, isEmpty, isString } from 'lodash';
import ReactDragListView from 'react-drag-listview';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const Lists = (props: any) => {
  const { items, onSeqChange } = props;

  const dragProps = {
    onDragEnd(fromIndex: any, toIndex: any) {
      const updatedData = clone(items);
      const item = updatedData.splice(fromIndex, 1)[0];
      updatedData.splice(toIndex, 0, item);
      onSeqChange(updatedData);
    },
    nodeSelector: 'li',
    handleSelector: 'a'
  };

  if (isEmpty(items) || !isArray(items)) return null;

  if (!isString(items[0])) return null;

  return (
    <div className="selfConfigComponent">
      <div className="simple-inner">
        <ReactDragListView {...dragProps}>
          <ol style={{
            listStyleType: 'none',
            padding: '0',
            marginTop: '8px',            
            maxHeight: '450px',
            overflow: 'auto'
          }}>
            {items.map((item: string, index: number) => (
              <li key={index} style={{
                padding: '5px 5px',
                borderRadius: '2px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
                {item}
                <a style={{ cursor: 'move' }}><DragIndicatorIcon sx={{ cursor: 'move' }}/></a>
              </li>
          ))}
          </ol>
        </ReactDragListView>
      </div>
    </div>
  );
}


export default Lists;
