import {
  LOAD_PLANTS,
  LOAD_PLANTS_SUCCESS,
  LOAD_PLANTS_ERROR,
  UPDATE_PLANT,
  UPDATE_PLANT_SUCCESS,
  DELETE_PLANT,
  DELETE_PLANT_SUCCESS,
} from "../Types/plants.constants";

// import axios from "axios";
import axios from "../../Utils/AxiosConfig/axiosConfig";
import { put, call, takeEvery } from "redux-saga/effects";

//function to get all plants from the api
const getPlants = async () => {
  let plants: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`plants?pageSize=100&page=${page++}`);
    plants = plants.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);
  let sortedPlants = plants.sort((a: any, b: any) =>
    a.plant_name.toLowerCase() > b.plant_name.toLowerCase()
      ? 1
      : b.plant_name.toLowerCase() > a.plant_name.toLowerCase()
      ? -1
      : 0
  );
  return sortedPlants;
};

export function* listPlants(): any {
  try {
    const data = yield call(() =>
      getPlants().then((res) => {
        return res;
      })
    );
    yield put({
      type: LOAD_PLANTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // console.error(error.response);
    yield put({
      type: LOAD_PLANTS_ERROR,
      payload: null,
    });
  }
}

export function* updatePlants(action: any): any {
  try {
    yield put({
      type: UPDATE_PLANT_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    // console.error(error.response);
    yield put({
      type: LOAD_PLANTS_ERROR,
      payload: null,
    });
  }
}

export function* deletePlant(action: any): any {
  try {
    yield put({
      type: DELETE_PLANT_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    // console.error(error.response);
    yield put({
      type: LOAD_PLANTS_ERROR,
      payload: null,
    });
  }
}

export function* listPlantsSaga() {
  yield takeEvery(LOAD_PLANTS, listPlants);
  yield takeEvery(UPDATE_PLANT, updatePlants);
  yield takeEvery(DELETE_PLANT, deletePlant);
}
