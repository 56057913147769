import { Box, Hidden, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { experimentalStyled } from "@material-ui/core/styles";
import BrightnessAutoTwoToneIcon from "@mui/icons-material/BrightnessAutoTwoTone";
import LogoNiagara from "./LogoNiagara.svg";

const LogoWrapper = experimentalStyled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = experimentalStyled(Box)(
  () => `
        margin-top: 4px;
        transform: scale(.8);
`
);

function Logo() {
  return (
    <LogoSignWrapper>
      {/* <BrightnessAutoTwoToneIcon style={{ fontSize: 35 }} /> */}

      <div style={{ display: "flex" }}>
        <img
          src={`${window.location.origin + "/LogoNiagara.svg"}`}
          style={{ width: 100, marginTop: 16 }}
          alt="Niagara | MonARCH"
        />
      </div>
    </LogoSignWrapper>
  );
}

export default Logo;
