import React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT,
  GREEN,
  RED,
  YELLOW
} from "../../Utils/UtilFunctions/constant";
import { removeHtmlTags } from "../../pages/master/UsecaseDashboard/SupplyChainTicket/Helpers";

const DowmtimeAlertPopup = (props: any): React.ReactElement => {
  const navigate = useNavigate();
  return (
    <Modal open={props?.open} onClose={props?.handleClose} closeAfterTransition>
      <Fade in={props?.open}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: window?.innerWidth < 400 ? "300px" : "550px",
            boxShadow: "24",
            padding: "16px 40px",
            color: "white",
            borderRadius: "12px",
            backgroundColor:
              props?.alert?.ticket_priority === "Low"
                ? GREEN
                : props?.alert?.ticket_priority === "Medium"
                ? YELLOW
                : props?.alert?.ticket_priority === "High"
                ? RED
                : DEFAULT,
            border:
              props?.alert?.ticket_priority === "Low"
                ? GREEN
                : props?.alert?.ticket_priority === "Medium"
                ? YELLOW
                : props?.alert?.ticket_priority === "High"
                ? RED
                : DEFAULT
          }}
        >
          <div
            style={{
              marginTop: "20px"
            }}
          >
            <CheckCircleIcon sx={{ fontSize: "60px" }} />
          </div>

          <div
            style={{
              marginBottom: "5px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <h5 style={{ fontSize: "1.2rem" }}>Downtime Request</h5>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                color: "black",
                padding: "12px",
                borderRadius: "3px",
                boxShadow: "1px 1px 0px 0px #000000",
                width: "98%",
                margin: "5px"
              }}
            >
              <p style={{ textAlign: "left", fontSize: "12px" }}>
                Ticket Id: {props?.alert?.ticket_alias}
              </p>
              <p style={{ textAlign: "left", fontSize: "12px" }}>
                Description:{" "}
                {removeHtmlTags(props?.alert?.message_data?.description)}
              </p>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <button
              style={{
                color: "black",
                backgroundColor: "white",
                marginLeft: "2em",
                margin: "20px 5px",
                width: props.alert.type === "Delete" ? "100%" : "45%",
                padding: "8px",
                borderRadius: "8px",
                fontWeight: "bold"
              }}
              onClick={() => {
                props?.handleClose();
              }}
            >
              Close
            </button>

            {props?.alert?.type !== "Delete" && (
              <button
                style={{
                  color: "black",
                  backgroundColor: "white",
                  marginLeft: "2em",
                  margin: "20px 5px",
                  width: "45%",
                  padding: "8px",
                  borderRadius: "8px",
                  fontWeight: "bold"
                }}
                onClick={() => {
                  props?.setOpen(false);
                  window.location.href?.includes(props?.alert?.ticket_id)
                    ? window.location.reload()
                    : navigate(
                        `/usecase/downtime-request/list-details/${props?.alert?.ticket_id}`,
                        {
                          replace: true
                        }
                      );
                }}
              >
                Go to Ticket
              </button>
            )}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default DowmtimeAlertPopup;
