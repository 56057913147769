import { makeStyles } from "@mui/styles";

export const LandingPage = makeStyles(() => ({
  lgvPlsWrapper: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      border: "1px solid #8476ef",
      background: "#293368",
      "& .MuiAutocomplete-input": {
        padding: "0 !important",
      },
    },

    "& .MuiInputBase-input": {
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "14px",
      "&::placeholder": {
        color: "#FFF",
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "12px",
    },
    "& .css-19kzrtu": {
      padding: 0,
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
}));

export const AssetIndicators = makeStyles(() => ({
  liveIndicator: {
    border: "1px solid red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
    color: "white",
    padding: "5px 10px",
    marginLeft: "20px",
  },
  liveDot: {
    height: "14px",
    width: "14px",
    backgroundColor: "red",
    borderRadius: "50%",
    display: "inline-block",
    marginLeft: "2px",
    marginRight: "6px",
  },
}));

export const Filter = makeStyles(() => ({
  inputFieldLabelPosition: {
    backgroundColor: "#293368",
    borderRadius: "14px",
    padding: "0px 10px !important",
    marginTop: "3px",
  },
}));

export const SensorAnnotation = makeStyles(() => ({
  annotation: {
    padding: "6px",
    borderRadius: "2px",
    position: "absolute",
  },
}));

export const TopCountChart = makeStyles(() => ({
  bar: {
    borderRadius: 4,
    background: "#8476ef",
  },
}));

export const DetailsPage = makeStyles(() => ({
  criticalControlSec: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      border: "1px solid #8476ef",
      background: "#293368",
      "& .MuiAutocomplete-input": {
        padding: "0 !important",
      },
    },

    "& .MuiInputBase-input": {
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "14px",
      "&::placeholder": {
        color: "#FFF",
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "12px",
    },
    "& .css-19kzrtu": {
      padding: 0,
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
}));