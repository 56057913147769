import {
  LOAD_USECASE,
  LOAD_USECASE_SUCCESS,
  LOAD_USECASE_ERROR,
} from "../Types/usecase.contants";

import axios from "../../Utils/AxiosConfig/axiosConfig";
import { put, call, takeEvery } from "redux-saga/effects";
import store from "../store";

//function to get all usecases from the api
const getUsecases = async () => {
  let usecases: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`usecases?pageSize=100&page=${page++}`);
    usecases = usecases.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);
  const usersList = store.getState().usersList;
  if (!(res.data.page < res.data.totalPages)) {
    let usecaseRoles = usersList.currentUserPermissions.filter((ele: any) => {
      return ele.includes("ARCH_USECASE_") || ele.includes("ARCH_SCM_") || ele.includes("ARCH_USECASE_EAM_");
    });
    if (
      usecaseRoles.length > 0 &&
      !usersList.currentUserPermissions.includes("ARCH_ADMIN") &&
      !usersList.currentUserPermissions.includes("ARCH_STAKEHOLDER")
    ) {
      if (!usersList.currentUserPermissions.includes("ARCH_USECASE_ALL")) {
        let usecaseCodeName = usecaseRoles.map((usecaseName: any) => {
          if (usecaseName.includes("ARCH_SCM_")) {
            return "ARCH_SCM_";
          }
          return usecaseName.includes("EAM") ? "EAM" : usecaseName.substring(13);
        });
        usecases = usecases.filter((usecaseData: any) => {
          if (
            (usecaseCodeName.includes("ARCH_SCM_") &&
              usecaseData.level_use_code === "DowntimeRequests") || (
              usecaseCodeName.includes("LGV_HEALTH") && ["LGV Navigation Quality", "LGV Inverter System Health", "LGV PLS Sensors", "LGV Hydraulic System Health"].includes(usecaseData.level_use_case)
            )
          ) {
            return true;
          } else {
            return usecaseCodeName.includes(usecaseData.level_use_code);
          }
        });
      }
    }
  }
  let sortedUsecase = usecases.sort((a: any, b: any) =>
    a.level_use_case.toLowerCase() > b.level_use_case.toLowerCase()
      ? 1
      : b.level_use_case.toLowerCase() > a.level_use_case.toLowerCase()
        ? -1
        : 0
  );
  return sortedUsecase;
};

export function* listUseCases(): any {
  try {
    const data = yield call(() =>
      getUsecases().then((res) => {
        return res;
      })
    );

    yield put({
      type: LOAD_USECASE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // console.error(error.response);
    yield put({
      type: LOAD_USECASE_ERROR,
      payload: null,
    });
  }
}

export function* listUseCasesSaga() {
  yield takeEvery(LOAD_USECASE, listUseCases);
}
